import FolderRouter from "../modules/Folder/routes/FolderRouter";
import DiagramRouter from "../modules/Diagram/routes/DiagramRouter";
import DiagramPublicRouter from "../modules/Diagram/routes/DiagramPublicRouter";
import { reactflowActions } from "../store/reactflow-slice";
import { useEffect, useLayoutEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { authActions } from "../store/auth-slice";
import { useCookies } from "react-cookie";
var { BrowserRouter, Routes, Route, Navigate } = require("react-router-dom");
const Router = () => {
  const dispatch = useDispatch();
  const [cookies, setCookie, removeCookie] = useCookies(["access_token"]);
  const isAuthanticated = cookies.access_token ? true : false;
  const userInfo = useSelector((state) => state.auth.userInfo);
  const urlParams = new URLSearchParams(window.location.search);
  const selectedWorkspaceId = urlParams.get("selectedWorkspaceId");
  const idToken = urlParams.get("idToken");
  const selectedLicensedWorkspace = urlParams.get("selectedLicensedWorkspace");

  useEffect(() => {
    if (selectedWorkspaceId) {
      localStorage.setItem("selectedWorkspaceId", selectedWorkspaceId);
      localStorage.setItem("idToken", idToken);
      localStorage.setItem(
        "selectedLicensedWorkspace",
        selectedLicensedWorkspace
      );
      window.location.href =
        window.location.protocol +
        "//" +
        window.location.host +
        window.location.pathname;
    }
  }, [selectedWorkspaceId]);
  useEffect(() => {
    if (localStorage.getItem("idToken")) {
      dispatch(authActions.setUserInfo(localStorage.getItem("idToken")));
      dispatch(authActions.setIsAuthenticated(true));
    }
  }, [dispatch]);
  useLayoutEffect(() => {
    dispatch(reactflowActions.changeDataBase([]));
  }, []);
  useEffect(() => {
    if (
      window.location.pathname.includes("share") ||
      window.location.pathname.includes("embed")
    ) {
    } else if (!cookies.access_token) {
      window.location.href =
        process.env.REACT_APP_ADMIN_BASE_DOMAIN + "/auth/login";
    }
  }, [cookies.access_token]);
  useLayoutEffect(() => {
    dispatch(authActions.setIsAuthenticated(isAuthanticated));
    dispatch(authActions.setSelectedWorkSpaceId());
  }, [isAuthanticated, dispatch, userInfo]);
  const publicRoutes = () => {
    return (
      <Routes>
        <Route exact path="/diagrams/*" element={<DiagramPublicRouter />} />
        <Route path="*" element={<Navigate replace to="/diagrams" />} />
      </Routes>
    );
  };
  const isAuthanticatedRoutes = () => {
    return (
      <Routes>
        <Route exact path="/*" element={<Navigate replace to="/diagrams" />} />
        <Route exact path="/folders/*" element={<FolderRouter />} />
        <Route exact path="/diagrams/*" element={<DiagramRouter />} />
        <Route path="*" element={<Navigate replace to="/diagrams" />} />
      </Routes>
    );
  };
  return (
    <BrowserRouter>
      {!isAuthanticated && publicRoutes()}
      {isAuthanticated && isAuthanticatedRoutes()}
    </BrowserRouter>
  );
};
export default Router;
