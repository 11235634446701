import { useSelector } from "react-redux";
import classes from "./style/TableAction.module.scss";
const TableAction = (props) => {
  const selectedDiagram = useSelector((state) => state.diagram.selectedDiagram);

  return (
    <div className={classes.tabcontainer}>
      {props.type === "table" && (
        <div className={classes.actionItem} onClick={props.columnHandler}>
          <span className={classes.actionText}>
            {selectedDiagram.dataVariationId === "16022509476708353"
              ? "Add Entity"
              : "Add Column"}
          </span>
        </div>
      )}
      <div className={classes.actionItem} onClick={props.descriptionHandler}>
        <span className={classes.actionText}>Add description</span>
      </div>
      <div className={classes.deleteAction} onClick={props.deleteHandler}>
        <span>Delete Table</span>
      </div>
    </div>
  );
};
export default TableAction;
