import classes from "./style/ErrorHandling.module.scss";
import { useSelector, useDispatch } from "react-redux";
import { Warning2 } from "iconsax-react";
import { errorActions } from "../../store/error-slice";
import { useEffect, useState, useCallback } from "react";
import { authActions } from "../../store/auth-slice";
const ErrorHandling = () => {
  const dispatch = useDispatch();
  const error = useSelector((state) => state.error.error);
  const [showNotif, setShowNotif] = useState(false);

  useCallback(() => {
    if (!showNotif) {
      closeNotify();
    }
  }, [showNotif]);
  const closeNotify = () => {
    setShowNotif(false);
    dispatch(errorActions.setHasError(false));
    dispatch(
      errorActions.setError({
        message: "",
        statusCode: 0,
      })
    );
  };
  useEffect(() => {
    let timer = setTimeout(() => {
      setShowNotif(false);
      dispatch(errorActions.setHasError(false));
      dispatch(
        errorActions.setError({
          message: "",
          statusCode: 0,
        })
      );
    }, 3000);
    return () => {
      clearTimeout(timer);
    };
  }, [showNotif]);
  useEffect(() => {
    if (error?.message) {
      setShowNotif(true);
    }
    if (error?.statusCode === 401) {
      dispatch(authActions.logOut());
    }
  }, [error]);
  return (
    <div className={classes.errorHandling}>
      {error && showNotif && (
        <div
          className={
            error?.statusCode === 200 ? classes.successNotif : classes.errNotif
          }
        >
          <div className={classes.content}>
            <Warning2
              className={classes.icon}
              style={{
                color: error?.statusCode === 200 ? "#729134" : "#E20000",
              }}
            />
            <span
              className={classes.message}
              style={{
                color: error?.statusCode === 200 ? "#729134" : "#E20000",
              }}
            >
              {error.message}
            </span>
          </div>
        </div>
      )}
    </div>
  );
};
export default ErrorHandling;
