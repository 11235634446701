import classes from "./style/FolderPanel.module.scss";
import FolderTile from "./FolderTile";
import { useSelector } from "react-redux";
import PageHeader from "../../../components/base/PageHeader";
import { folderActions } from "../store/folder-slice";
import { useDispatch } from "react-redux";
import { useEffect, useState } from "react";
import { FolderSortOptions } from "../../../types/SharedTypes";
import { SortFolders } from "../../../types/SharedTypes";

const FolderPanel = () => {
  const dispatch = useDispatch();
  const folderList = useSelector((state) => state.folder.folderList);
  const sortType = useSelector((state) => state.folder.sortType);
  const sortHandler = (status) => {
    dispatch(folderActions.setSortType(status));
  };
  useEffect(() => {
    dispatch(folderActions.setFolderList(SortFolders(sortType, folderList)));
  }, [sortType]);
  return (
    <>
      <PageHeader
        type="All folders"
        defaultSort={sortType}
        sortOptions={FolderSortOptions}
        onSort={sortHandler}
      />
      <div className={classes.panelLayout}>
        <div className={classes.tileBox}>
          {folderList?.map((item, index) => {
            return (
              <div key={index} className={classes.tileItem}>
                <FolderTile folder={item} />
              </div>
            );
          })}
        </div>
      </div>
    </>
  );
};
export default FolderPanel;
