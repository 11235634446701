import classes from "./style/Sidebar.module.scss";
import CreateNode from "../../../modules/ReactFlow/node/createForm/CreateForm";
import TableAction from "./TableAction";
import { useSelector, useDispatch } from "react-redux";
import { reactflowActions } from "../../../store/reactflow-slice";
import { Edit, TickSquare, ArrowDown2, ArrowRight2, Add } from "iconsax-react";
import VidPlayIcon from "@atlaskit/icon/glyph/vid-play";
import MoreVerticalIcon from "@atlaskit/icon/glyph/more-vertical";
import { useEffect, useState, useRef } from "react";
import WarningModal from "../../../core/helpers/WarningModal";
import Form from "react-bootstrap/Form";
import BaseButton from "../../base/BaseButton";
import DropdownButton from "../../base/DropDownButton";
import { Colors } from "../../../types/SharedTypes";
import { diagramActions } from "../../../modules/Diagram/store/diagram-slice";
const Sidebar = () => {
  const tableOption = useRef(null);
  const dispatch = useDispatch();
  const [deleteModalFlag, setDeleteModalFlag] = useState(false);
  const database = useSelector((state) => state.reactflow.database);
  const selectedNode = useSelector((state) => state.reactflow.selectedNode);
  const editMode = useSelector((state) => state.reactflow.editFlag);
  const diagramVariations = useSelector(
    (state) => state.diagram.diagramVariations
  );
  const selectedDiagram = useSelector((state) => state.diagram.selectedDiagram);
  const [enteredName, setEnteredName] = useState("");
  const [tableAction, setTableAction] = useState(false);
  const [selecetedTableForOpenOption, setSelecetedTableForOpenOption] =
    useState(null);
  const [openTableCol, setOpenTableCol] = useState(false);
  const colorsItem = [
    "#F2EDFD",
    "#F2D8FB",
    "#FFCFF2",
    "#EBFAE4",
    "#FAF4E4",
    "#FFDFC5",
    "#FFD7D7",
    "#FFE7E7",
  ];
  const warningModalHandler = (state) => {
    setDeleteModalFlag(state);
  };
  const showDetail = (e, item) => {
    if (item) {
      dispatch(reactflowActions.setSelectedNode(item));
    }
  };
  const deleteTable = (selectedNode) => {
    setDeleteModalFlag(false);
    if (selectedNode.data.enum) {
      dispatch(
        reactflowActions.deleteColsByDataVariationRemove(
          selectedNode?.data.name
        )
      );
    }
    dispatch(reactflowActions.setSaveChangesFlag(true));
    dispatch(reactflowActions.deleteNode({ id: selectedNode.id }));
  };
  const addIcon = () => {
    return <Add />;
  };
  const addColumnHandler = (e, id) => {
    let colNewData = {
      id: Math.random(),
      name: "Col",
      type: "int",
      description: "",
    };
    let tempNode = {
      id: selectedNode.id,
      position: selectedNode.position,
      type: selectedNode.type,
      descriptionFlag: selectedNode.descriptionFlag,
      data: {
        name: selectedNode.data.name,
        columns: [...selectedNode.data.columns, colNewData],
        schemaColor: selectedNode.data.schemaColor,
      },
    };
    dispatch(reactflowActions.setSaveChangesFlag(true));
    dispatch(reactflowActions.updateNode(tempNode));
  };
  const descriptionHandler = () => {
    let tempNode;
    if (selectedNode.data.enum) {
      tempNode = {
        id: selectedNode.id,
        position: selectedNode.position,
        type: selectedNode.type,
        descriptionFlag: true,
        data: {
          enum: true,
          name: selectedNode.data.name,
          values: selectedNode.data.values,
          schemaColor: selectedNode.data.schemaColor,
        },
      };
    } else {
      tempNode = {
        id: selectedNode.id,
        position: selectedNode.position,
        type: selectedNode.type,
        descriptionFlag: true,
        data: {
          enum: false,
          name: selectedNode.data.name,
          columns: selectedNode.data.columns,
          schemaColor: selectedNode.data.schemaColor,
        },
      };
    }
    dispatch(reactflowActions.setSaveChangesFlag(true));
    dispatch(reactflowActions.setSelectedNode(tempNode));
    dispatch(reactflowActions.updateNode(tempNode));
    setTableAction(false);
  };
  const tableOptionHandler = (item) => {
    if (
      selecetedTableForOpenOption === null ||
      selecetedTableForOpenOption?.id !== item.id
    ) {
      setSelecetedTableForOpenOption(item);
      setTableAction(true);
      setOpenTableCol(true);
    } else if (selecetedTableForOpenOption?.id === item.id) {
      setSelecetedTableForOpenOption(null);
      setTableAction(false);
    }
  };
  const handleClickOutside = (event) => {
    if (tableOption.current && !tableOption.current.contains(event.target)) {
      setTableAction(false);
    }
  };
  useEffect(() => {
    document.addEventListener("click", handleClickOutside, true);
    return () => {
      document.removeEventListener("click", handleClickOutside, true);
    };
  }, [tableOption]);
  useEffect(() => {
    setEnteredName(selectedNode?.data?.name);
  }, [selectedNode]);
  const changeEnteredName = (event) => {
    let tempNode;
    if (selectedNode.data.enum) {
      tempNode = {
        id: selectedNode.id,
        position: selectedNode.position,
        type: selectedNode.type,
        data: {
          name: event.target.value,
          enum: true,
          values: selectedNode.data.values,
          schemaColor: selectedNode.data.schemaColor,
          description: selectedNode.data.description,
        },
      };
      updateDataVariation(event.target.value);
    } else {
      tempNode = {
        id: selectedNode.id,
        position: selectedNode.position,
        type: selectedNode.type,
        data: {
          name: event.target.value,
          enum: false,
          columns: selectedNode.data.columns,
          schemaColor: selectedNode.data.schemaColor,
          description: selectedNode.data.description,
        },
      };
    }
    dispatch(reactflowActions.setSaveChangesFlag(true));
    setEnteredName(event.target.value);
    dispatch(reactflowActions.updateNode(tempNode));
    dispatch(reactflowActions.setSelectedNode(tempNode));
  };
  const updateDataVariation = (newName) => {
    dispatch(
      diagramActions.updateDiagramVariations({
        oldName: selectedNode?.data.name,
        newName: newName,
      })
    );
    dispatch(
      reactflowActions.updateColumnsProperty({
        oldName: selectedNode?.data.name,
        newName: newName,
      })
    );
  };

  const getBorderColor = (table) => {
    switch (table.data.schemaColor) {
      case "#F2EDFD":
        return "#8D71D5";
      case "#F2D8FB":
        return "#9A4DE8";
      case "#FFCFF2":
        return "#E84DE2";
      case "#EBFAE4":
        return "#92BD7F";
      case "#FAF4E4":
        return "#FFE296";
      case "#FFDFC5":
        return "#FFB274";
      case "#FFD7D7":
        return "#FF9C9C";
      case "#FFE7E7":
        return "#FF99CA";
      default:
        return "#292d32";
    }
  };
  const tableHeightCalc = (index) => {
    var bodyRect = document.body.getBoundingClientRect();
    var rect = document.getElementById("table" + index).getBoundingClientRect();
    var offset = rect.top - bodyRect.top;
    return offset + 48;
  };
  const openTableHandler = (item) => {
    if (selectedNode?.id === item?.id) {
      setOpenTableCol(!openTableCol);
    } else {
      setOpenTableCol(true);
      dispatch(reactflowActions.setSelectedNode(item));
    }
  };
  const tableNameValidator = (tableType) => {
    let name = tableType + (database.length + 1);
    let counter = 0;
    while (database.find((table) => table.data.name === name)) {
      counter++;
      break;
    }
    counter = database.length + 1 + counter;
    return tableType + counter;
  };
  const ShowCreateTableFormSection = (type) => {
    dispatch(reactflowActions.setSaveChangesFlag(false));
    let position = { x: 0, y: 0 };
    if (database.length > 0) {
      position = {
        x: database[database.length - 1].position.x + 50,
        y: database[database.length - 1].position.y + 60,
      };
    }
    const random = Math.floor(Math.random() * colorsItem.length);
    let data;
    if (type === "table") {
      data = {
        id: String(Math.random()),
        position: position,
        type: "table",
        descriptionFlag: false,
        data: {
          enum: false,
          schemaColor: colorsItem[random],
          name: tableNameValidator(
            selectedDiagram.dataVariationId === "16022509476708353"
              ? "Entity"
              : "Table"
          ),
          description: "",
          columns: [
            {
              id: Math.random().toString(),
              name: "id",
              type: "int",
              description: "",
              required: true,
              nullable: true,
              key: 0,
            },
          ],
        },
      };
    } else {
      data = {
        id: String(Math.random()),
        position: position,
        type: "table",
        descriptionFlag: false,
        data: {
          enum: true,
          schemaColor: colorsItem[random],
          name: tableNameValidator("Enum"),
          description: "",
          values: [
            {
              name: "string",
              description: "",
              value: 0,
            },
          ],
        },
      };
      dispatch(
        diagramActions.setDiagramVariations([
          ...diagramVariations,
          { propertyType: data.data.name },
        ])
      );
    }
    dispatch(reactflowActions.setSaveChangesFlag(true));
    dispatch(reactflowActions.setDataBase(data));
    dispatch(reactflowActions.setSelectedNode(data));
  };
  return (
    <div className={classes.sidebar} onScroll={() => setTableAction(false)}>
      <div className={classes.createTable}>
        <span>
          {selectedDiagram?.dataVariationId === "16022509476708353"
            ? "Entities"
            : "Tables"}
        </span>

        {selectedDiagram?.dataVariationId === "16022509476708353" ? (
          <DropdownButton
            title={
              selectedDiagram?.dataVariationId === "16022509476708353"
                ? "Add Entity"
                : "Add Table"
            }
            color={Colors.Blue_700}
            onButtonClick={() => ShowCreateTableFormSection("table")}
            items={[
              <BaseButton
                title="Add Enum"
                color="secondary"
                hasRightIcon={true}
                size="sm"
                hasBlock={true}
                onClickHandler={(e) => ShowCreateTableFormSection("enum")}
              />,
            ]}
          />
        ) : (
          <BaseButton
            title="Add Table"
            color="primary"
            hasRightIcon={true}
            icon={addIcon()}
            size="sm"
            onClickHandler={(e) => ShowCreateTableFormSection("table")}
          />
        )}
      </div>
      <div className={classes.tablesList}>
        {database?.map((item, index) => {
          return (
            <div key={index} className={classes.tableSection}>
              <div
                className={classes.colorBorder}
                style={{ backgroundColor: item.data.schemaColor }}
              ></div>

              <div id={"table" + index} className={classes.tables}>
                <div
                  onClick={(e) => {
                    showDetail(e, item);
                  }}
                  className={classes.tableName}
                >
                  <div
                    className={classes.name}
                    onClick={(e) => {
                      openTableHandler(item);
                    }}
                  >
                    {openTableCol &&
                    selectedNode &&
                    selectedNode.id === item.id ? (
                      <ArrowDown2 className={classes.icon} />
                    ) : (
                      <ArrowRight2 className={classes.icon} />
                    )}
                    {!(
                      editMode === "sidebar" &&
                      selectedNode &&
                      selectedNode.id === item.id
                    ) && (
                      <div className={classes.tableName}>{item.data.name}</div>
                    )}
                    {selectedNode &&
                      selectedNode.id === item.id &&
                      editMode === "sidebar" && (
                        <Form.Group>
                          <Form.Control
                            type="text"
                            id="name"
                            value={enteredName}
                            onChange={(e) => changeEnteredName(e)}
                            onKeyDown={(e) =>
                              e.key === "Enter"
                                ? dispatch(reactflowActions.setEditFlag(""))
                                : ""
                            }
                          />
                        </Form.Group>
                      )}
                  </div>

                  <div className={classes.leftButton}>
                    {!(
                      editMode === "sidebar" &&
                      selectedNode &&
                      selectedNode.id === item.id
                    ) ? (
                      <Edit
                        className={classes.icon}
                        onClick={(e) => {
                          dispatch(reactflowActions.setEditFlag("sidebar"));
                        }}
                      />
                    ) : (
                      <TickSquare
                        className={classes.icon}
                        onClick={(e) => {
                          dispatch(reactflowActions.setEditFlag(""));
                        }}
                      />
                    )}
                    {item.data.enum && (
                      <div className={classes.enumLabel}>
                        <span className={classes.enumText}>ENUM</span>
                      </div>
                    )}
                    <div
                      className={classes.moreIcon}
                      onClick={(e) => {
                        tableOptionHandler(item);
                      }}
                    >
                      <MoreVerticalIcon />
                    </div>
                  </div>
                </div>
                {tableAction && selectedNode && selectedNode.id === item.id && (
                  <div
                    ref={tableOption}
                    className={classes.tableAction}
                    style={{ top: tableHeightCalc(index) }}
                  >
                    <TableAction
                      type={item.data.enum ? "enum" : "table"}
                      columnHandler={(e) => addColumnHandler()}
                      descriptionHandler={(e) =>
                        !selectedNode.descriptionFlag
                          ? descriptionHandler()
                          : null
                      }
                      deleteHandler={(e) => {
                        setDeleteModalFlag(true);
                        setTableAction(false);
                      }}
                    />
                  </div>
                )}
                {selectedNode && selectedNode.id === item.id && openTableCol ? (
                  <CreateNode table={item}></CreateNode>
                ) : null}
              </div>
            </div>
          );
        })}
      </div>
      {deleteModalFlag && (
        <WarningModal
          type="delete"
          header="Warning"
          alert="Are you sure you want to delete this Table?"
          onClose={warningModalHandler}
          onDelete={(e) => deleteTable(selectedNode)}
        />
      )}
    </div>
  );
};

export default Sidebar;
