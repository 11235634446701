import DashboardLayout from "../../../components/layouts/DashboardLayout";
import FolderDetails from "../components/FolderDetails";

const Folder = () => {
  return (
    <DashboardLayout>
      <FolderDetails />
    </DashboardLayout>
  );
};
export default Folder;
