import classes from "./style/DiagramTile.module.scss";
import DiagramOptions from "./DiagramOptions";
import DiagramCreationForm from "./DiagramCreationForm";
import MoveToFolder from "./MoveToFolder";
import DiagramShareLink from "./DiagramShareLink";
import EmbedDiagram from "./EmbedDiagram";
import Avatar from "../../../assets/image/avatar.png";
import ChildIssuesIcon from "@atlaskit/icon/glyph/child-issues";
import StarIcon from "@atlaskit/icon/glyph/star";
import StarFilledIcon from "@atlaskit/icon/glyph/star-filled";
import LockIcon from "@atlaskit/icon/glyph/lock";
import UnlockIcon from "@atlaskit/icon/glyph/unlock";
import MoreIcon from "@atlaskit/icon/glyph/more";
import Sql_icon from "../../../assets/image/icon/sql-icon.svg";
import Mongodb from "../../../assets/image/icon/mongodb.svg";
import PostgreSql from "../../../assets/image/icon/postgresql.svg";
import Dotnet from "../../../assets/image/icon/dotnet.svg";
import Pyhthon from "../../../assets/image/icon/Python.svg";
import Nodejs from "../../../assets/image/icon/Nodejs.svg";
import { useState, useEffect, useRef } from "react";
import { diagramActions } from "../store/diagram-slice";
import { errorActions } from "../../../store/error-slice";
import { loadingActions } from "../../../store/loading-slice";
import { useDispatch, useSelector } from "react-redux";
import Modal from "../../../components/base/Modal";
import WarningModal from "../../../core/helpers/WarningModal";
import { RepoFactory } from "../../../baseRepository/Factory";
import { useNavigate, useParams } from "react-router-dom";
import { folderActions } from "../../Folder/store/folder-slice";
import { IconColor, MonthName } from "../../../types/SharedTypes";
import { Colors } from "../../../types/SharedTypes";
import { TagColor } from "../../../types/SharedTypes";
import { TagTitleColor } from "../../../types/SharedTypes";
import Tag from "../../../components/base/Tag";
import { SortDiagrams } from "../../../types/SharedTypes";
const DiagramRepository = () => RepoFactory.get("diagram");
const TemplateRepository = () => RepoFactory.get("template");
const FolderRepository = () => RepoFactory.get("folder");

const DigramTile = (props) => {
  const { folderId } = useParams();
  const navigate = useNavigate();
  const diagramOption = useRef();
  const dispatch = useDispatch();
  const [optionFlag, setOptionFlag] = useState(false);
  const [mouseHoverFlag, setMouseHoverFlag] = useState(false);
  const [diagramModalFlag, setDiagramModalFlag] = useState(false);
  const [diagramModal, setDiagramModal] = useState(null);
  const [deleteModalFlag, setDeleteModalFlag] = useState(false);
  const [diagramImage, setDiagramImage] = useState("");
  const selectedDiagram = useSelector((state) => state.diagram.selectedDiagram);
  const sortType = useSelector((state) => state.diagram.sortType);
  const [diagramDatabaseIcon, setDiagramDatabaseIcon] = useState();
  const privateIcon = () => {
    if (props.diagram.isPrivate) {
      return (
        <LockIcon
          label=""
          size="small"
          primaryColor={TagTitleColor[TagColor.blue]}
        />
      );
    } else {
      return (
        <UnlockIcon
          label=""
          size="small"
          primaryColor={TagTitleColor[TagColor.blue]}
        />
      );
    }
  };
  const exitModal = (status) => {
    setDiagramModalFlag(status);
  };
  const warningModalHandler = (state) => {
    setDeleteModalFlag(state);
  };

  const selectedWorkSpaceId = useSelector(
    (state) => state.auth.selectedWorkSpaceId
  );
  const getDiagramsListWithPageParams = () => {
    if (window.location.pathname.includes("folders") && folderId) {
      getDiagramByFolderId();
    } else {
      getAllDigrams();
    }
  };

  const getAllDigrams = async () => {
    dispatch(loadingActions.setHasLoading(true));
    dispatch(errorActions.setHasError(false));
    dispatch(errorActions.setError(""));
    let array = [];
    await DiagramRepository()
      .getAllDigrams(selectedWorkSpaceId)
      .then((res) => {
        array = res.data.value.diagrams;
      })
      .catch((error) => {
        dispatch(errorActions.setHasError(true));
        dispatch(
          errorActions.setError({
            message: error.response?.data
              ? error.response.data.Reasons[0].Message
              : error.message,
            statusCode: error.response?.status,
          })
        );
      })
      .finally(() => {
        dispatch(loadingActions.setHasLoading(false));
        dispatch(diagramActions.setDiagramsList(SortDiagrams(sortType, array)));
      });
  };
  const deleteDiagram = async () => {
    dispatch(loadingActions.setHasLoading(true));
    dispatch(errorActions.setHasError(false));
    dispatch(errorActions.setError(""));
    setOptionFlag(false);
    await DiagramRepository()
      .deleteDiagram(props.diagram.id)
      .then((res) => {
        dispatch(
          errorActions.setError({
            message: "Diagram Deleted Successfuly",
            statusCode: 200,
          })
        );
        dispatch(diagramActions.setSelectedDiagram(null));
        dispatch(errorActions.setHasError(true));
        getDiagramsListWithPageParams();
      })
      .catch((error) => {
        dispatch(errorActions.setHasError(true));
        dispatch(errorActions.setError(error.response?.data));
        dispatch(
          errorActions.setError({
            message: error.response?.data
              ? error.response.data.Reasons[0].Message
              : error.message,
            statusCode: error.response?.status,
          })
        );
        dispatch(loadingActions.setHasLoading(false));
      })
      .finally(() => {
        setDeleteModalFlag(false);
      });
  };
  const getDiagramByFolderId = () => {
    dispatch(loadingActions.setHasLoading(true));
    dispatch(errorActions.setHasError(false));
    dispatch(errorActions.setError(""));
    FolderRepository()
      .getFolderById(folderId)
      .then((res) => {
        dispatch(folderActions.setSelectedFolder(res.data.value.folder));
      })
      .catch((error) => {
        dispatch(errorActions.setHasError(true));
        dispatch(errorActions.setError(error.response?.data));
      })
      .finally(() => {
        dispatch(loadingActions.setHasLoading(false));
      });
  };
  const getDiagram = async () => {
    setOptionFlag(false);
    dispatch(loadingActions.setHasLoading(true));
    dispatch(errorActions.setHasError(false));
    dispatch(errorActions.setError(""));
    await DiagramRepository()
      .getDiagram(props.diagram.id)
      .then((res) => {
        dispatch(diagramActions.setSelectedDiagram(res.data.value.diagram));
      })
      .catch((error) => {
        dispatch(errorActions.setHasError(true));
        dispatch(
          errorActions.setError({
            message: error.response?.data
              ? error.response.data.Reasons[0].Message
              : error.message,
            statusCode: error.response?.status,
          })
        );
      })
      .finally(() => {
        dispatch(loadingActions.setHasLoading(false));
        setDiagramModalFlag(true);
      });
  };
  const addToFavorite = async () => {
    dispatch(loadingActions.setHasLoading(true));
    dispatch(errorActions.setHasError(false));
    dispatch(errorActions.setError(""));
    let body = {
      diagramId: props.diagram.id,
      tenantId: selectedWorkSpaceId,
    };
    let tempDiagram = { ...props.diagram };
    await DiagramRepository()
      .addToFavorite(body)
      .then((res) => {
        tempDiagram.isFavoriate = true;
        dispatch(errorActions.setHasError(true));
        dispatch(
          errorActions.setError({
            message: "Diagram Added To Favorites Successfuly",
            statusCode: 200,
          })
        );
        getDiagramsListWithPageParams();
      })
      .catch((error) => {
        dispatch(errorActions.setHasError(true));
        dispatch(
          errorActions.setError({
            message: error.response?.data
              ? error.response.data.Reasons[0].Message
              : error.message,
            statusCode: error.response?.status,
          })
        );
      })
      .finally(() => {
        dispatch(loadingActions.setHasLoading(false));
        setOptionFlag(false);
      });
  };
  const unFavoriteDiagram = async () => {
    dispatch(loadingActions.setHasLoading(true));
    dispatch(errorActions.setHasError(false));
    dispatch(errorActions.setError(""));
    let body = {
      diagramId: props.diagram.id,
      tenantId: selectedWorkSpaceId,
    };
    let tempDiagram = { ...props.diagram };
    await DiagramRepository()
      .unFavoriteDiagram(body)
      .then((res) => {
        tempDiagram.isFavoriate = true;
        dispatch(errorActions.setHasError(true));
        dispatch(
          errorActions.setError({
            message: "Diagram  unFavorited Successfuly",
            statusCode: 200,
          })
        );
        getDiagramsListWithPageParams();
      })
      .catch((error) => {
        dispatch(errorActions.setHasError(true));
        dispatch(
          errorActions.setError({
            message: error.response?.data
              ? error.response.data.Reasons[0].Message
              : error.message,
            statusCode: error.response?.status,
          })
        );
      })
      .finally(() => {
        dispatch(loadingActions.setHasLoading(false));
        setOptionFlag(false);
      });
  };
  const diagramClone = async () => {
    dispatch(loadingActions.setHasLoading(true));
    dispatch(errorActions.setHasError(false));
    dispatch(errorActions.setError(""));
    let body = {
      templateId: props.diagram.id,
      tenantId: selectedWorkSpaceId,
      newTemplateName: "Copy of " + props.diagram.name,
    };
    await TemplateRepository()
      .cloneTemplate(body)
      .then((res) => {
        dispatch(errorActions.setHasError(true));
        dispatch(
          errorActions.setError({
            message: "Diagram Cloned Successfuly",
            statusCode: 200,
          })
        );
        getDiagramsListWithPageParams();
      })
      .catch((error) => {
        dispatch(errorActions.setHasError(true));
        dispatch(
          errorActions.setError({
            message: error.response?.data
              ? error.response.data.Reasons[0].Message
              : error.message,
            statusCode: error.response?.status,
          })
        );
      })
      .finally(() => {
        dispatch(loadingActions.setHasLoading(false));
        setOptionFlag(false);
      });
  };
  const modalHandler = () => {
    switch (diagramModal) {
      case "edit":
        return (
          <Modal
            size="lg"
            header="Edit Diagram"
            exitAction={exitModal}
            body={
              <DiagramCreationForm
                exitAction={exitModal}
                diagram={selectedDiagram}
              />
            }
          />
        );
      case "move":
        return (
          <Modal
            body={
              <div className={classes.moveToFolder}>
                <MoveToFolder
                  exitAction={exitModal}
                  diagram={selectedDiagram}
                />
              </div>
            }
          />
        );
      case "shareLink":
        return (
          <Modal
            body={
              <DiagramShareLink
                exitAction={exitModal}
                diagram={selectedDiagram}
              />
            }
          />
        );
      case "embedLink":
        return (
          <Modal
            body={
              <EmbedDiagram exitAction={exitModal} diagram={selectedDiagram} />
            }
          />
        );
      default:
        return null;
    }
  };
  const openDiagram = () => {
    navigate(
      `/diagrams/${props.diagram.id}/${
        props.diagram.diagramCurrentVersion
      }?page=${
        window.location.pathname.includes("Favorites")
          ? "fav"
          : window.location.pathname.includes("folders")
          ? "folder"
          : "diagrams"
      }`
    );
  };
  const handleClickOutside = (event) => {
    if (
      diagramOption.current &&
      !diagramOption.current.contains(event.target)
    ) {
      setOptionFlag(false);
    }
  };
  useEffect(() => {
    document.addEventListener("click", handleClickOutside, true);
    return () => {
      document.removeEventListener("click", handleClickOutside, true);
    };
  }, [diagramOption]);
  useEffect(() => {
    if (props.diagram.models.length > 0) {
      let currenVersion = props.diagram.models.find(
        (model) => model.version === props.diagram.diagramCurrentVersion
      );
      setDiagramImage(currenVersion?.snapshot);
    }
  }, [props.diagram]);
  const dateConvertor = () => {
    let date = props.diagram.addedDate.split("T")[0].split("-");
    let currentyear = new Date().getFullYear();
    if (date[0] === String(currentyear)) {
      return MonthName[date[1]] + " " + date[2];
    } else {
      return date[0] + " " + MonthName[date[1]] + " " + date[2];
    }
  };
  const diagramIconMap = () => {
    switch (props.diagram.dataVariationId) {
      case "16022509476708352":
        return (
          <img
            alt="icon"
            src={Sql_icon}
            className={classes.diagramDatabaseIcon}
          />
        );
      case "16022509476708353":
        return (
          <img
            alt="icon"
            src={Dotnet}
            className={classes.diagramDatabaseIcon}
          />
        );
      case "16022509476708354":
        return (
          <img
            alt="icon"
            src={PostgreSql}
            className={classes.diagramDatabaseIcon}
          />
        );
      case "16022509476708355":
        return (
          <img
            alt="icon"
            src={Mongodb}
            className={classes.diagramDatabaseIcon}
          />
        );
      default:
        return "";
    }
  };
  return (
    <div className={classes.tileLayout} ref={diagramOption}>
      <div
        className={classes.tileBody}
        onMouseLeave={() => setMouseHoverFlag(false)}
        onMouseEnter={() => {
          setMouseHoverFlag(true);
        }}
        style={{
          background: Colors.Natural_1100,
        }}
      >
        {mouseHoverFlag && (
          <div
            className={classes.optionIcon}
            onClick={() => {
              setOptionFlag(!optionFlag);
            }}
          >
            <MoreIcon label="" />
          </div>
        )}
        {optionFlag && (
          <div className={classes.diagramOption}>
            <DiagramOptions
              onEdit={(e) => {
                setDiagramModal("edit");
                getDiagram();
              }}
              onClone={(e) => diagramClone()}
              onMove={(e) => {
                setDiagramModal("move");
                getDiagram();
              }}
              onShareLink={(e) => {
                setDiagramModal("shareLink");
                getDiagram();
              }}
              onEmbedLink={(e) => {
                setDiagramModal("embedLink");
                getDiagram();
              }}
              onDelete={(e) => {
                setDeleteModalFlag(true);
                setOptionFlag(false);
              }}
              onFavorite={(e) => {
                props.diagram.isFavoriate
                  ? unFavoriteDiagram()
                  : addToFavorite();
              }}
            />
          </div>
        )}
        {diagramImage ? (
          <img
            alt="diagramImage"
            className={classes.backgroundImage}
            src={diagramImage}
            onClick={() => openDiagram()}
          />
        ) : (
          <div className={classes.diagramIcon} onClick={() => openDiagram()}>
            <ChildIssuesIcon
              label=""
              primaryColor={Colors.FolderIconColor}
              size="large"
            />
          </div>
        )}
      </div>
      <div className={classes.tileFooter}>
        <div className={classes.diagramName}>
          <div className={classes.diagramTitle}>
            {diagramIconMap()}
            <span className={classes.nameTitle}>{props.diagram.name}</span>
          </div>
          <div
            className={
              props.diagram.isFavoriate
                ? classes.isFavoriateIcon
                : classes.starIcon
            }
            onClick={(e) =>
              props.diagram.isFavoriate ? unFavoriteDiagram() : addToFavorite()
            }
          >
            {props.diagram.isFavoriate ? (
              <StarFilledIcon />
            ) : (
              <StarIcon label="" primaryColor={IconColor.grey} />
            )}
          </div>
        </div>
        <Tag
          title={props.diagram.isPrivate ? "private" : "public"}
          color={TagColor.blue}
          hasLeftIcon={true}
          icon={privateIcon()}
          size="sm"
        />
        <div className={classes.versionBox}>
          <span className={classes.versionText}>Version history</span>
          <span className={classes.versionText}>
            V {props.diagram.diagramCurrentVersion}
          </span>
        </div>
        <div className={classes.userInfo}>
          <div className={classes.avatar}>
            {/* <img alt="avatar" src={Avatar} className={classes.avatarImage} /> */}
          </div>
          <span className={classes.diagramDate}>
            Modified {dateConvertor()}{" "}
          </span>
        </div>
      </div>
      {diagramModalFlag && modalHandler()}
      {deleteModalFlag && (
        <WarningModal
          header="Delete Diagram"
          alert="Are You Sure Delete This Diagram?"
          onClose={warningModalHandler}
          onDelete={() => deleteDiagram()}
        />
      )}
    </div>
  );
};
export default DigramTile;
