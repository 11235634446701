import { createSlice } from "@reduxjs/toolkit";
const dashboardSLice = createSlice({
  name: "dashboard",
  initialState: {
    selectedDashboard: "Recents",
  },
  reducers: {
    setSelectedDashboard(state, action) {
      state.selectedDashboard = action.payload;
    },
  },
});
export const dashboardAction = dashboardSLice.actions;
export default dashboardSLice.reducer;
