import DashboardLayout from "../../../components/layouts/DashboardLayout";
import DiagramPanel from "../components/DiagramPanel";
import { useDispatch } from "react-redux";
import { dashboardAction } from "../../../store/dashboard-slice";
import { useEffect } from "react";
import { reactflowActions } from "../../../store/reactflow-slice";
import { menuAction } from "../../../store/menu-slice";
const Diagram = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(reactflowActions.changeDataBase([]));
    dispatch(dashboardAction.setSelectedDashboard("All Diagrams"));
    dispatch(menuAction.setSelectedMenu("My Diagrams"));
  }, [dispatch]);
  return (
    <DashboardLayout>
      <DiagramPanel />
    </DashboardLayout>
  );
};
export default Diagram;
