import { createSlice } from "@reduxjs/toolkit";
import { DiagramSortOptions } from "../../../types/SharedTypes";
const diagramSLice = createSlice({
  name: "diagram",
  initialState: {
    diagramsList: [],
    favoriteDiagramList: [],
    selectedDiagram: null,
    diagramVariations: [],
    selectedDatabase: null,
    sortType: DiagramSortOptions[2],
    diagramImageFlag: false,
  },
  reducers: {
    setSelectedDiagram(state, action) {
      state.selectedDiagram = action.payload;
    },
    setDiagramsList(state, action) {
      state.diagramsList = action.payload;
    },
    setFavoriteDiagramList(state, action) {
      state.favoriteDiagramList = action.payload;
    },
    setDiagramVariations(state, action) {
      state.diagramVariations = action.payload;
    },
    updateDiagramVariations(state, action) {
      let tempList = [];
      state.diagramVariations?.map((item) => {
        if (item.propertyType === action.payload?.oldName) {
          tempList.push({ propertyType: action.payload?.newName });
        } else {
          tempList.push(item);
        }
      });
      state.diagramVariations = tempList;
    },
    updateDiagramsList(state, action) {
      let tempList = [];
      state.diagramsList?.map((item) => {
        if (item.id === action.payload) {
          tempList.push(action.payload);
        } else {
          tempList.push(item);
        }
      });
      state.diagramsList = tempList;
    },
    setSelectedDatabse(state, action) {
      state.selectedDatabase = action.payload;
    },
    setSortType(state, action) {
      state.sortType = action.payload;
    },
    setDiagramImageFlag(state, action) {
      state.diagramImageFlag = action.payload;
    },
  },
});
export const diagramActions = diagramSLice.actions;
export default diagramSLice.reducer;
