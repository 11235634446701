import { useEffect, useRef, useState } from "react";
import { IconColor } from "../../../types/SharedTypes";
import classes from "./style/VersionHistoryPanel.module.scss";
import CrossIcon from "@atlaskit/icon/glyph/cross";
import { useDispatch, useSelector } from "react-redux";
import VersionTile from "./VersionTile";
import { loadingActions } from "../../../store/loading-slice";
import { errorActions } from "../../../store/error-slice";
import { RepoFactory } from "../../../baseRepository/Factory";
const DiagramRepository = RepoFactory.get("diagram");
const VersionHistoryPanel = (props) => {
  const dispatch = useDispatch();
  const selectedDiagram = useSelector((state) => state.diagram.selectedDiagram);
  const [currentDiagram, setCurrentDigaram] = useState(null);
  const getDigramsVersionHistory = async () => {
    if (selectedDiagram) {
      dispatch(loadingActions.setHasLoading(true));
      dispatch(errorActions.setHasError(false));
      dispatch(errorActions.setError(""));
      await DiagramRepository.getDigramsVersionHistory(selectedDiagram.id)
        .then((res) => {
          setCurrentDigaram(res.data.value.diagram);
          // dispatch(diagramActions.setSelectedDiagram(res.data.value.diagram));
        })
        .catch((error) => {
          dispatch(errorActions.setHasError(true));
          dispatch(
            errorActions.setError({
              message: error.response?.data
                ? error.response.data.Reasons[0].Message
                : error.message,
              statusCode: error.response?.status,
            })
          );
        })
        .finally(() => {
          dispatch(loadingActions.setHasLoading(false));
        });
    }
  };
  useEffect(() => {
    getDigramsVersionHistory();
  }, [selectedDiagram]);
  return (
    <div className={classes.panelLayout}>
      <div className={classes.header}>
        <div
          className={classes.headerIcon}
          onClick={() => {
            return props.onClose(false);
          }}
        >
          <CrossIcon label="" primaryColor={IconColor.grey} />
        </div>
        <span className={classes.headerTitle}>version history</span>
      </div>
      <div className={classes.historyCards}>
        {currentDiagram &&
          currentDiagram.models.map((history, index) => {
            return (
              <VersionTile
                title={history.data.name}
                versionNumber={history.version}
                description={history.description}
                diagramId={currentDiagram.id}
                current={
                  currentDiagram.diagramCurrentVersion === history.version
                }
                key={index}
                onCreateNewVersion={() => getDigramsVersionHistory()}
              />
            );
          })}
      </div>
    </div>
  );
};
export default VersionHistoryPanel;
