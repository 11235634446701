import { createSlice } from "@reduxjs/toolkit";

const layoutSlice = createSlice({
  name: "layout",
  initialState: {
    showSidebar: true,
  },
  reducers: {
    toggle(state) {
      state.showSidebar = !state.showSidebar;
    },
  },
});
export const layoutActions = layoutSlice.actions;
export default layoutSlice.reducer;
