import classes from "./style/ExportForm.module.scss";
import SelectInput from "../../../components/base/SelectInput";
import BaseButton from "../../../components/base/BaseButton";
import ExportIcon from "@atlaskit/icon/glyph/export";
import { useDispatch, useSelector } from "react-redux";
import { loadingActions } from "../../../store/loading-slice";
import { errorActions } from "../../../store/error-slice";
import { RepoFactory } from "../../../baseRepository/Factory";
import { useEffect, useState } from "react";
import DatabaseCard from "../../../components/base/DatabaseCard";
import { diagramActions } from "../../Diagram/store/diagram-slice";
const FormatterRepository = () => RepoFactory.get("formatter");
const DataVariationsRepository = () => RepoFactory.get("dataVariations");

const ExportForm = (props) => {
  const dispatch = useDispatch();
  const selectedDiagram = useSelector((state) => state.diagram.selectedDiagram);
  const selectedCard = useSelector((state) => state.diagram.selectedDatabase);
  const [databaseList, setDatabaseList] = useState();

  const exportIcon = () => {
    return <ExportIcon />;
  };
  const getDataVariations = async () => {
    dispatch(loadingActions.setHasLoading(true));
    dispatch(errorActions.setHasError(false));
    dispatch(errorActions.setError(""));
    await DataVariationsRepository()
      .getAllDataVariations()
      .then((res) => {
        setDatabaseList(res.data.value.dataVariations);
      })
      .catch((error) => {
        dispatch(errorActions.setHasError(true));
        dispatch(
          errorActions.setError({
            message: error.response?.data
              ? error.response.data.Reasons[0].Message
              : error.message,
            statusCode: error.response?.status,
          })
        );
      })
      .finally(() => {
        dispatch(loadingActions.setHasLoading(false));
      });
  };
  const exportDiagramHandler = () => {
    dispatch(loadingActions.setHasLoading(true));
    dispatch(errorActions.setHasError(false));
    dispatch(errorActions.setError(""));
    FormatterRepository()
      .exportDiagram(
        selectedDiagram.id,
        selectedDiagram.diagramCurrentVersion,
        selectedCard.id
      )
      .then((res) => {
        downloadExport(res.data);
        dispatch(diagramActions.setSelectedDatabse(null));
      })
      .catch((error) => {
        dispatch(errorActions.setHasError(true));
        dispatch(
          errorActions.setError({
            message: error.response?.data
              ? error.response.data.Reasons[0].Message
              : error.message,
            statusCode: error.response?.status,
          })
        );
      })
      .finally(() => {
        dispatch(loadingActions.setHasLoading(false));
      });
  };
  const downloadExport = (dataUrl) => {
    if (dataUrl) {
      const a = document.createElement("a");
      a.setAttribute("download", "export");
      a.setAttribute("href", dataUrl);
      a.click();
    }
  };
  useEffect(() => {
    getDataVariations();
  }, []);
  const dataMap = (title) => {
    let card = databaseList?.find((X) => X.name === title);
    return card;
  };
  return (
    <div className={classes.formLayout}>
      <div className={classes.formHeader}>
        <span className={classes.headerTitle}>Export</span>
      </div>
      <div className={classes.formBody}>
        <div className={classes.cardBox}>
          <DatabaseCard title="SQLServer" type={dataMap("SQLServer")} />
          <DatabaseCard title="Mongodb" type={dataMap("Mongodb")} />
          <DatabaseCard title="Postgresql" type={dataMap("Postgresql")} />
        </div>
      </div>
      <div className={classes.formAction}>
        <BaseButton
          title="Cancel"
          size="sm"
          hasLeftIcon={true}
          color="secondary"
          onClickHandler={(e) => {
            return props.exitAction(false);
          }}
        />
        <BaseButton
          title="Export"
          size="sm"
          hasLeftIcon={true}
          icon={exportIcon()}
          color="pimary"
          onClickHandler={(e) => {
            if (selectedCard) {
              exportDiagramHandler();
            }
          }}
        />
      </div>
    </div>
  );
};
export default ExportForm;
