import { configureStore } from "@reduxjs/toolkit";
import layoutSlice from "./layout-slice";
import reactflowSlice from "./reactflow-slice";
import errorSlice from "./error-slice";
import loadingSlice from "./loading-slice";
import authSlice from "./auth-slice";
import folderSlice from "../modules/Folder/store/folder-slice";
import dashboardSlice from "./dashboard-slice";
import diagramSlice from "../modules/Diagram/store/diagram-slice";
import menuSlice from "./menu-slice";
const store = configureStore({
  reducer: {
    layout: layoutSlice,
    reactflow: reactflowSlice,
    error: errorSlice,
    loading: loadingSlice,
    auth: authSlice,
    dashboard: dashboardSlice,
    folder: folderSlice,
    diagram: diagramSlice,
    menu: menuSlice,
  },
});
export default store;
