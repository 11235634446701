import twemoji from "twemoji";
import MarkdownIt from "markdown-it";
import markdownitEmoji from "markdown-it-emoji";

const md = new MarkdownIt({
  html: true,
  linkify: true,
  typographer: true,
});

md.use(markdownitEmoji);
md.renderer.rules.emoji = (token, idx) => {
  return twemoji.parse(token[idx].content, {
    base: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/",
  });
};

export const markdown = (text) => {
  return md.renderInline(text);
};
