import classes from "./style/VersionTile.module.scss";
import { useEffect, useRef, useState } from "react";
import BitbucketCommitsIcon from "@atlaskit/icon/glyph/bitbucket/commits";
import MoreIcon from "@atlaskit/icon/glyph/more";
import EditIcon from "@atlaskit/icon/glyph/edit";
import AddIcon from "@atlaskit/icon/glyph/add";
import Tag from "../../../components/base/Tag";
import Modal from "../../../components/base/Modal";
import VersionOptions from "./VersionOptions";
import VersionModal from "./VersionModal";
import { IconColor, TagColor } from "../../../types/SharedTypes";
import BaseButton from "../../../components/base/BaseButton";
import VersionCreationForm from "./VersionCreationForm";
const VersionTile = (props) => {
  const versionOptionRef = useRef(null);
  const [descriptionFlag, setDescriptionFlag] = useState(false);
  const [optionFlag, setOptionFlag] = useState(false);
  const [modalFlag, setModalFlag] = useState(false);
  const [createModalFlag, setCreateModalFlag] = useState(false);

  const modalExitHandler = (status) => {
    setModalFlag(status);
  };
  const createModalExitHandler = (status, state) => {
    setCreateModalFlag(status);
    if (state === "create") {
      return props.onCreateNewVersion();
    }
  };
  const buttonIcon = () => {
    return <AddIcon label="" primaryColor={IconColor.blue} />;
  };
  const handleClickOutside = (event) => {
    const { target } = event;
    if (
      versionOptionRef.current &&
      !versionOptionRef.current.contains(target)
    ) {
      setOptionFlag(false);
    }
  };
  useEffect(() => {
    document.addEventListener("click", handleClickOutside, true);
    return () => {
      document.removeEventListener("click", handleClickOutside, true);
    };
  }, [versionOptionRef]);
  return (
    <div className={classes.versionBox}>
      <div className={classes.versionNumber}>
        <div className={classes.version}>
          <BitbucketCommitsIcon label="" />
          <span className={classes.versionTitle}> V {props.versionNumber}</span>
          {props.current && (
            <Tag title="current version" color={TagColor.grey} size="sm" />
          )}
        </div>
        <div className={classes.actionBox}>
          {props.current && (
            <BaseButton
              size="xsm"
              title="Create from this version"
              color="outline"
              onClickHandler={() => setCreateModalFlag(true)}
            />
          )}
          {/* {props.current && (
            <BaseButton size="xsm" title="select" color="outline" />
          )} */}

          <div
            className={classes.option}
            onClick={() => {
              setOptionFlag(!optionFlag);
            }}
          >
            <MoreIcon label="" />
            {optionFlag && (
              <div className={classes.optionLayout} ref={versionOptionRef}>
                <VersionOptions
                  onDelete={modalExitHandler}
                  version={props.versionNumber}
                  diagramId={props.diagramId}
                  current={props.current ? true : false}
                />
              </div>
            )}
          </div>
        </div>
      </div>
      <div className={classes.header}>
        <span className={classes.headerTitle}>{props.title}</span>
      </div>
      {props.description && descriptionFlag === false && (
        <div className={classes.description}>
          <span className={classes.descriptionTitle}>{props.description}</span>
          {/* <div
            className={classes.iconBox}
            onClick={() => setDescriptionFlag(true)}
          >
            <EditIcon label="" secondaryColor={IconColor.light_grey} />
          </div> */}
        </div>
      )}
      {/* {!props.description && descriptionFlag === false && (
        <BaseButton
          size="sm"
          title="Add description"
          color="primary-text"
          hasLeftIcon={true}
          icon={buttonIcon()}
          onClickHandler={() => setDescriptionFlag(true)}
        />
      )} */}
      {/* {descriptionFlag && (
        <div className={classes.descriptionForm}>
          <Textarea placeholder="please write youre Description" rows={3} />
          <BaseButton
            size="sm"
            title="save"
            color="secondary"
            onClickHandler={() => setDescriptionFlag(false)}
          />
        </div>
      )} */}
      {modalFlag && (
        <Modal
          body={
            <VersionModal
              version={props.versionNumber}
              diagramId={props.diagramId}
              onClose={modalExitHandler}
            />
          }
          exitAction={modalExitHandler}
        />
      )}
      {createModalFlag && (
        <Modal
          body={
            <VersionCreationForm
              onClose={createModalExitHandler}
              diagramId={props.diagramId}
              versionNumber={props.versionNumber}
            />
          }
        />
      )}
    </div>
  );
};
export default VersionTile;
