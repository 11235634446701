import classes from "./style/DashboardLayout.module.scss";
import Header from "../../components/core/dashboard/Header";
import Menu from "../../components/core/dashboard/Menu";
import { useSelector } from "react-redux";
import ShortcutIcon from "@atlaskit/icon/glyph/shortcut";
import { Colors } from "../../types/SharedTypes";
const DashboardLayout = (props) => {
  const userInfo = useSelector((state) => state.auth.userInfo);
  return (
    <div className={classes.layout}>
      <div className={classes.header}>
        <Header />
      </div>

      <div className={classes.dashboardBody}>
        <div className={classes.sideBar}>
          <Menu />
          <div className={classes.sideBarFooter}>
            <div className={classes.docButton}>
              <ShortcutIcon label="" primaryColor={Colors.Natural_700} />
              <span className={classes.buttonTitle}>Documents</span>
            </div>
            <div className={classes.footerNavigation}>
              <div className={classes.linkBox}>
                <span className={classes.footerTitle}>Terms of use</span>
                <span>.</span>
                <span className={classes.footerTitle}>About us</span>
              </div>
            </div>
          </div>
        </div>
        <div className={classes.content}>{props.children}</div>
      </div>
    </div>
  );
};
export default DashboardLayout;
