import { useReactFlow, getNodesBounds, getTransformForBounds } from "reactflow";
import { toPng } from "html-to-image";
import { useDispatch, useSelector } from "react-redux";
import { RepoFactory } from "../baseRepository/Factory";
import { loadingActions } from "../store/loading-slice";
import { errorActions } from "../store/error-slice";
import { diagramActions } from "../modules/Diagram/store/diagram-slice";
const DiagramRepository = () => RepoFactory.get("diagram");

const imageWidth = 1024;
const imageHeight = 768;

const useDownloadImage = () => {
  const { getNodes } = useReactFlow();
  const selectedDiagram = useSelector((state) => state.diagram.selectedDiagram);
  const dispatch = useDispatch();
  const createSnapshot = async (dataUrl) => {
    let body = {
      tenantId: selectedDiagram.tenantId,
      diagramId: selectedDiagram.id,
      diagramVersionId: selectedDiagram.diagramCurrentVersion,
      snapshot: dataUrl,
    };
    dispatch(loadingActions.setHasLoading(true));
    dispatch(errorActions.setHasError(false));
    dispatch(errorActions.setError(""));
    await DiagramRepository()
      .createSnapshot(body)
      .then((res) => {
        dispatch(errorActions.setHasError(true));
        dispatch(diagramActions.setDiagramImageFlag(true));
      })
      .catch((error) => {
        dispatch(errorActions.setHasError(true));
        dispatch(
          errorActions.setError({
            message: error.response?.data
              ? error.response.data.Reasons[0].Message
              : error.message,
            statusCode: error.response?.status,
          })
        );
        dispatch(diagramActions.setDiagramImageFlag(false));
      })
      .finally(() => {
        dispatch(loadingActions.setHasLoading(false));
      });
  };
  const download = async () => {
    const nodesBounds = getNodesBounds(getNodes());
    const transform = getTransformForBounds(
      nodesBounds,
      imageWidth,
      imageHeight,
      0.3,
      2
    );
    await toPng(document.querySelector(".react-flow__viewport"), {
      backgroundColor: "#ffff",
      width: imageWidth,
      height: imageHeight,
      style: {
        width: imageWidth,
        height: imageHeight,
        transform: `translate(${transform[0]}px, ${transform[1]}px) scale(${transform[2]})`,
      },
    }).then(createSnapshot);
  };

  return {
    download,
  };
};
export default useDownloadImage;
