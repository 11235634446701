/*
        <Tag
          title=""
          color={TagColor.blue}
          hasLeftIcon={false}
          haseRightIcon={false}
          icon={icon()}
        />

*/

import { useState } from "react";
import classes from "./style/Tag.module.scss";
import { TagTitleColor } from "../../types/SharedTypes";
import { TagColor } from "../../types/SharedTypes";

const Tag = (props) => {
  const [clicked, setClicked] = useState(false);

  const clickHandler = () => {
    if (props.onClick) {
      setClicked(true);
      return props.onClick(props.title);
    }
  };
  return (
    <div
      className={props.size === "sm" ? classes.smallLayout : classes.layout}
      style={{
        background: clicked
          ? TagColor.clicked
          : props.color
          ? props.color
          : TagColor.white,
        cursor: props.clickable ? "pointer" : "",
      }}
      onClick={() => {
        clickHandler();
      }}
    >
      {props.hasLeftIcon && <div>{props.icon}</div>}
      <span
        className={classes.title}
        style={{
          color: clicked
            ? TagTitleColor[TagColor.clicked]
            : props.color
            ? TagTitleColor[props.color]
            : TagTitleColor[TagColor.white],
        }}
      >
        {props.title}
      </span>
      {props.hasRightIcon && <div>{props.icon}</div>}
    </div>
  );
};
export default Tag;
