import * as React from "react";
import { ReactFlowProvider } from "reactflow";
import classes from "./style/DBLayout.module.scss";
import Header from "../core/header/Header";
import Sidebar from "../core/sidebar/Sidebar";
import Footer from "../core/footer/Footer";
import { useDispatch, useSelector } from "react-redux";
import EditorCloseIcon from "@atlaskit/icon/glyph/editor/close";
import { reactflowActions } from "../../store/reactflow-slice";
const KEY_NAME_ESC = "Escape";
const KEY_EVENT_TYPE = "keyup";
const DBLayout = (props) => {
  const presentFlag = useSelector((state) => state.reactflow.presentFlag);
  const dbLayoutRef = React.useRef(null);
  const [hintFlag, setHintFlag] = React.useState(true);
  const dispatch = useDispatch();

  const handleEscKey = (event) => {
    if (event.key === KEY_NAME_ESC) {
      dispatch(reactflowActions.setPresentFlag(false));
    }
  };

  React.useEffect(() => {
    document.addEventListener(KEY_EVENT_TYPE, handleEscKey, false);

    return () => {
      document.removeEventListener(KEY_EVENT_TYPE, handleEscKey, false);
    };
  }, []);

  return (
    <ReactFlowProvider>
      <div
        ref={dbLayoutRef}
        className={
          props.status === "createNew" ? classes.footerLayout : classes.layout
        }
      >
        {!presentFlag && (
          <div className={classes.header}>
            {props.status === "embed" ? (
              <Header status="embed" />
            ) : props.status === "share" ? (
              ""
            ) : props.status === "createNew" ? (
              <Footer />
            ) : (
              <Header />
            )}
          </div>
        )}
        <div className={classes.layoutRow}>
          {props.status !== "share" && !presentFlag && (
            <div className={classes.sidebar}>
              <Sidebar></Sidebar>
            </div>
          )}
          <div
            className={
              props.status === "share" || presentFlag
                ? classes.linkLayoutCol
                : classes.layoutCol
            }
          >
            <div
              className={
                props.status === "share" || presentFlag
                  ? classes.linkCharmdb
                  : classes.charmdb
              }
            >
              {presentFlag && hintFlag && (
                <div className={classes.presentHint}>
                  <div onClick={() => setHintFlag(false)}>
                    <EditorCloseIcon />
                  </div>
                  <span className={classes.hintText}>
                    Press ESC to exit presentation mode.
                  </span>
                </div>
              )}
              {props.children}
            </div>
          </div>
        </div>
        {/* {props.status === "createNew" && <Footer />} */}
      </div>
    </ReactFlowProvider>
  );
};

export default DBLayout;
