export const Colors = {
  natural_01: "#ffffff",
  natural_02: "#f4f5f5",
  natural_03: "#d4d5d6",
  natural_04: "#a9abad",
  natural_05: "#54575b",
  natural_06: "#292d32",

  Natural_1100: "#091e4224",
  Natural_1000: " #172b4d",
  Natural_800: "#44546f",
  Natural_700: " #626f86",
  Natural_0: "#ffffff",
  Natural_400A: "#091e424f",

  Blue_700: "#0c66e4",
  Blue_800: "#0055cc",
  // Blue_700: #09326c,
  Blue_600: "#CCE0FF",

  Red_800: "#ae2e24",
  Red_900: "#5d1f1a",

  Orange_600: "#a54800",
  Orange_800: "#a54800",
  Orange_900: "#702e00",

  Yellow_800: "#7f5f01",
  Yellow_900: "#533f04",

  Lime_800: "#4c6b1f",
  Lime_900: "#37471f",

  Green_800: "#216e4e",
  Green_900: "#164b35",
  Green_04: "#dcfff1",

  Purple_700: "#09326c",
  Purple_800: "#5e4db2",
  Purple_900: "#352c63",

  Teal_800: "#206a83",
  Teal_900: "#164555",

  Magenta_800: "#943d73",
  Magenta_900: "#50253f",

  Danger: "#c9372c",
  FolderIconColor: "#758195",
};
export const TagColor = {
  blue: "#CCE0FF",
  grey: "#091E420F",
};

export const TagTitleColor = {
  [TagColor.blue]: Colors.Blue_800,
};
export const MonthName = {
  "01": "Jan",
  "02": "Feb",
  "03": "March",
  "04": "Apr",
  "05": "May",
  "06": "Jun",
  "07": "Jul",
  "08": "Aug",
  "09": "Sep",
  10: "Oct",
  11: "Nov",
  12: "Dec",
};
export const IconColor = {
  blue: "#0C66E4",
  light_grey: "#0000003B",
  grey: "#44546F",
  danger: "#C9372C",
  purple: "#7F4AF0",
  white: "#ffff",
  warning: "#E56910",
  green: "#22A06B",
};

export const FolderSortOptions = [
  { value: "1", title: "Alphabetically" },
  { value: "2", title: "Last created" },
];

export const DiagramSortOptions = [
  { value: "1", title: "Recent" },
  { value: "2", title: "Alphabetically" },
  { value: "3", title: "Last created" },
];

export const SortDiagrams = (sort, list) => {
  if (list) {
    const tempList = [...list];
    switch (sort.title) {
      case "Recent":
        tempList.sort(function (a, b) {
          return b.lastUpdatedDate
            .toLowerCase()
            .localeCompare(a.lastUpdatedDate.toLowerCase());
        });
        break;
      case "Alphabetically":
        tempList.sort(function (a, b) {
          return a.name.toLowerCase().localeCompare(b.name.toLowerCase());
        });
        break;
      case "Last created":
        tempList.sort(function (a, b) {
          return b.addedDate
            .toLowerCase()
            .localeCompare(a.addedDate.toLowerCase());
        });
        break;
      default:
        break;
    }
    return tempList;
  }
};

export const SortFolders = (sort, list) => {
  const tempList = [...list];
  switch (sort.title) {
    case "Recent":
      tempList.sort(function (a, b) {
        return b.lastUpdatedDate
          .toLowerCase()
          .localeCompare(a.lastUpdatedDate.toLowerCase());
      });
      break;
    case "Alphabetically":
      tempList.sort(function (a, b) {
        return a.name.toLowerCase().localeCompare(b.name.toLowerCase());
      });
      break;
    case "Last created":
      tempList.sort(function (a, b) {
        return b.created.toLowerCase().localeCompare(a.created.toLowerCase());
      });
      break;
    default:
      break;
  }
  return tempList;
};
