import { useState, useEffect, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Handle, Position } from "reactflow";
import { Message2 } from "iconsax-react";
import { markdown } from "../helpers/markdown";
import classes from "./table.module.scss";
import "@reactflow/node-resizer/dist/style.css";
import Form from "react-bootstrap/Form";
import { Key, Ranking, TickSquare, Flag2, MessageText } from "iconsax-react";
import { reactflowActions } from "../../../store/reactflow-slice";
import { diagramActions } from "../../Diagram/store/diagram-slice";

const TableNode = ({ data }) => {
  const handleRef = useRef(null);
  const dispatch = useDispatch();
  const database = useSelector((state) => state.reactflow.database);
  const [selectedColumn, setSelectedColumn] = useState("");
  const [showDescription, setShowDescription] = useState(false);
  const [showColumnDescription, setShowColumnDescription] = useState(false);
  const selectedNode = useSelector((state) => state.reactflow.selectedNode);
  const [selectedTable, setSelectedTable] = useState();
  const [handleFlag, setHandleFlag] = useState(false);
  const [descriptionOnHoverActive, setDescriptionOnHoverActive] =
    useState(false);
  const editMode = useSelector((state) => state.reactflow.editFlag);
  useEffect(() => {
    document.addEventListener(
      "keydown",
      (e) => {
        if (e.code === "MetaLeft") {
          setDescriptionOnHoverActive(true);
        }
      },
      false
    );

    document.addEventListener(
      "keyup",
      (e) => {
        if (e.code === "MetaLeft") {
          setDescriptionOnHoverActive(false);
        }
      },
      false
    );
  }, []);
  useEffect(() => {
    const table = database.find((element) => element.data.name === data.name);
    setSelectedTable(table);
  }, []);
  const setNode = () => {
    const table = database.find((element) => element.data.name === data.name);
    dispatch(reactflowActions.setSelectedNode(table));
  };
  useEffect(() => {
    if (selectedNode?.data.name === data?.name) {
      setHandleFlag(true);
    }
  }, [selectedNode]);

  const changeEnteredName = (event) => {
    let tempNode;
    if (selectedNode.data.enum) {
      tempNode = {
        id: selectedNode.id,
        position: selectedNode.position,
        type: selectedNode.type,
        data: {
          name: event.target.value,
          enum: true,
          values: selectedNode.data.values,
          schemaColor: selectedNode.data.schemaColor,
          description: selectedNode.data.description,
        },
      };
      updateDataVariation(event.target.value);
    } else {
      tempNode = {
        id: selectedNode.id,
        position: selectedNode.position,
        type: selectedNode.type,
        data: {
          name: event.target.value,
          enum: false,
          columns: selectedNode.data.columns,
          schemaColor: selectedNode.data.schemaColor,
          description: selectedNode.data.description,
        },
      };
    }
    dispatch(reactflowActions.setSaveChangesFlag(true));
    dispatch(reactflowActions.updateNode(tempNode));
    dispatch(reactflowActions.setSelectedNode(tempNode));
  };
  const updateDataVariation = (newName) => {
    dispatch(
      diagramActions.updateDiagramVariations({
        oldName: selectedNode?.data.name,
        newName: newName,
      })
    );
    dispatch(
      reactflowActions.updateColumnsProperty({
        oldName: selectedNode?.data.name,
        newName: newName,
      })
    );
  };
  const updateTableName = (event) => {
    dispatch(reactflowActions.setEditFlag(""));
  };

  const getBorderColor = () => {
    switch (data.schemaColor) {
      case "#F2EDFD":
        return "#8D71D5";
      case "#F2D8FB":
        return "#9A4DE8";
      case "#FFCFF2":
        return "#E84DE2";
      case "#EBFAE4":
        return "#92BD7F";
      case "#FAF4E4":
        return "#FFE296";
      case "#FFDFC5":
        return "#FFB274";
      case "#FFD7D7":
        return "#FF9C9C";
      case "#FFE7E7":
        return "#FF99CA";
      default:
        return "#292d32";
    }
  };
  const handleClickOutside = (event) => {
    if (handleRef.current && !handleRef.current.contains(event.target)) {
      setHandleFlag(false);
    }
  };
  useEffect(() => {
    document.addEventListener("click", handleClickOutside, true);
    return () => {
      document.removeEventListener("click", handleClickOutside, true);
    };
  }, [handleRef]);
  return (
    <div
      className={classes.table}
      ref={handleRef}
      onClick={() => {
        setHandleFlag(true);
        setNode();
      }}
    >
      <div
        style={{ backgroundColor: data.schemaColor }}
        className={classes.table__name}
      >
        <div className={classes.details}>
          {editMode === "panel" &&
          selectedNode &&
          selectedNode.id === selectedTable.id ? (
            <div className={classes.editBox}>
              <Form.Group>
                <Form.Control
                  type="text"
                  id="name"
                  value={data.name}
                  onChange={(e) => changeEnteredName(e)}
                  onKeyDown={(e) =>
                    e.key === "Enter" ? updateTableName(e) : ""
                  }
                />
              </Form.Group>
              <TickSquare
                className={classes.saveIcon}
                onClick={(e) => {
                  updateTableName(e);
                }}
              />
            </div>
          ) : (
            <div
              onDoubleClick={(e) => {
                setNode();
                dispatch(reactflowActions.setEditFlag("panel"));
              }}
            >
              {data.schema ? `${data.schema}.${data.name}` : data.name}
            </div>
          )}
          <div className={classes.iconBox}>
            {data.description && (
              <Message2
                className={classes.itemIcon}
                onClick={(e) => setShowDescription(!showDescription)}
              />
            )}
            {data.enum && (
              <div className={classes.enumTag}>
                <span className={classes.enumTitle}>ENUM</span>
              </div>
            )}
          </div>
        </div>
        {showDescription && (
          <div className={classes.descriptionBox}>
            <span className={classes.tooltiptext}> {data.description}</span>
            {showDescription && (
              <span className={classes.descriptionText}>
                {data.description}
              </span>
            )}
          </div>
        )}
      </div>
      <div className={classes.table__columns}>
        {data.enum
          ? data.values?.map((value, index) => (
              <div
                key={index}
                className={
                  selectedColumn === value.name
                    ? classes.columnName__selected
                    : classes.columnName
                }
                onMouseEnter={() => {
                  if (descriptionOnHoverActive) {
                    setSelectedColumn(value.name);
                  }
                }}
                onMouseLeave={() => setSelectedColumn("")}
              >
                <div className={classes.columnName__inner}>
                  <div className={classes.columnName__name}>
                    {value.name}
                    {value.description && (
                      <MessageText
                        className={classes.itemIcon}
                        onClick={(e) => {
                          setShowColumnDescription(!showColumnDescription);
                          setSelectedColumn(value);
                        }}
                      />
                    )}
                  </div>
                  <div className={classes.columnName__type}>{value.value}</div>
                  <div
                    className={classes.columnName__description}
                    dangerouslySetInnerHTML={{
                      __html: markdown(value.description || "No description."),
                    }}
                  />
                </div>
                <div className={classes.columnName__options}>
                  {showColumnDescription && selectedColumn.id === value.id && (
                    <div className={classes.descriptionBox}>
                      <span className={classes.descriptionText}>
                        {value.description}
                      </span>
                    </div>
                  )}
                </div>
              </div>
            ))
          : data.columns?.map((column, index) => (
              <div
                key={index}
                className={
                  selectedColumn === column.name
                    ? classes.columnName__selected
                    : classes.columnName
                }
                onMouseEnter={() => {
                  if (descriptionOnHoverActive) {
                    setSelectedColumn(column.name);
                  }
                }}
                onMouseLeave={() => setSelectedColumn("")}
              >
                <Handle
                  style={{
                    width: "12px",
                    height: "12px",
                    left: "-5px",
                    visibility: handleFlag ? "visible" : "hidden",
                  }}
                  type="target"
                  id={column.id.toString()}
                  position={Position.Left}
                />

                <Handle
                  style={{
                    width: "12px",
                    height: "12px",
                    right: "-5px",
                    visibility: handleFlag ? "visible" : "hidden",
                  }}
                  type="source"
                  id={column.id.toString()}
                  position={Position.Right}
                />

                <div className={classes.columnName__inner}>
                  <div className={classes.columnName__name}>
                    {column.key === 1 ? (
                      <Key
                        style={{ color: " #292d32" }}
                        className={classes.itemIcon}
                      />
                    ) : column.key === 2 ? (
                      <Ranking
                        style={{ color: " #292d32" }}
                        className={classes.itemIcon}
                      />
                    ) : column.key === 3 ? (
                      <Flag2
                        style={{ color: " #292d32" }}
                        className={classes.itemIcon}
                      />
                    ) : (
                      ""
                    )}
                    {column.name}
                    {column.description && (
                      <MessageText
                        className={classes.itemIcon}
                        onClick={(e) => {
                          setShowColumnDescription(!showColumnDescription);
                          setSelectedColumn(column);
                        }}
                      />
                    )}
                  </div>
                  <div className={classes.columnName__type}>
                    {column.type}
                    {column.nullable && (
                      <span
                        style={{ color: "#A9ABAD" }}
                        className={classes.nullableIcon}
                      >
                        ?
                      </span>
                    )}
                  </div>

                  <div
                    className={classes.columnName__description}
                    dangerouslySetInnerHTML={{
                      __html: markdown(column.description || "No description."),
                    }}
                  />
                </div>
                <div className={classes.columnName__options}>
                  {showColumnDescription && selectedColumn.id === column.id && (
                    <div className={classes.descriptionBox}>
                      <span className={classes.descriptionText}>
                        {column.description}
                      </span>
                    </div>
                  )}
                </div>
              </div>
            ))}
      </div>
    </div>
  );
};
export default TableNode;
