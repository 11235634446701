import classes from "./Footer.module.scss";
import InfoIcon from "@atlaskit/icon/glyph/info";
import { IconColor } from "../../../types/SharedTypes";
import BaseButton from "../../base/BaseButton";
import { useDispatch, useSelector } from "react-redux";
import { errorActions } from "../../../store/error-slice";
import { loadingActions } from "../../../store/loading-slice";
import { diagramActions } from "../../../modules/Diagram/store/diagram-slice";
import { reactflowActions } from "../../../store/reactflow-slice";
import { RepoFactory } from "../../../baseRepository/Factory";
import useDownloadImage from "../../../hooks/use-downloadImage";
const DiagramRepository = () => RepoFactory.get("diagram");

const Footer = () => {
  const downloader = useDownloadImage();
  const dispatch = useDispatch();
  const database = useSelector((state) => state.reactflow.database);
  const edges = useSelector((state) => state.reactflow.edges);
  const selectedDiagram = useSelector((state) => state.diagram.selectedDiagram);
  const tableDataFiller = async () => {
    let tableArray = [];
    let positionArray = [];
    let enumArray = [];
    let enumPositionArray = [];
    let edgesArray = [];
    await database.forEach((element) => {
      if (element.data.enum) {
        enumArray.push({
          id: element.id,
          name: element.data.name,
          description: element.data.description ? element.data.description : "",
          schemaColor: element.data.schemaColor ? element.data.schemaColor : "",
          values: element.data.values,
        });
        enumPositionArray.push({
          table: element.id,
          positions: {
            x: element.position.x.toString(),
            y: element.position.y.toString(),
          },
        });
      } else {
        tableArray.push({
          id: element.id,
          name: element.data.name,
          description: element.data.description ? element.data.description : "",
          schemaColor: element.data.schemaColor ? element.data.schemaColor : "",
          columns: element.data.columns,
        });
        positionArray.push({
          table: element.id,
          positions: {
            x: element.position.x.toString(),
            y: element.position.y.toString(),
          },
        });
      }
    });
    await edges.forEach((element) => {
      edgesArray.push({
        id: element.id,
        source: element.source,
        sourceKey: element.sourceHandle,
        target: element.target,
        targetKey: element.targetHandle,
        relation:
          element.handleType === "manyToMany"
            ? 2
            : element.handleType === "oneToMany"
            ? 1
            : 0,
      });
    });
    downloader.download();

    insertTableToDiagram(
      tableArray,
      positionArray,
      enumArray,
      enumPositionArray,
      edgesArray
    );
  };

  const insertTableToDiagram = async (
    tableArray,
    positionArray,
    enumArray,
    enumPositionArray,
    edgesArray
  ) => {
    dispatch(loadingActions.setHasLoading(true));
    dispatch(errorActions.setHasError(false));
    dispatch(errorActions.setError(""));

    let body = {
      diagramId: selectedDiagram.id,
      name: selectedDiagram.name,
      description: selectedDiagram.description,
      dataVariationId: selectedDiagram.dataVariationId,
      diagramVersionId: selectedDiagram.diagramCurrentVersion,
      diagram: {
        name: selectedDiagram.name,
        tables: tableArray,
        enums: enumArray,
        edges: edgesArray,
        schemaColors: {
          default: "",
          public: "",
        },
        tablePositions: positionArray,
        enumPositions: enumPositionArray,
      },
    };

    await DiagramRepository()
      .updateDiagram(body)
      .then((res) => {
        dispatch(reactflowActions.setSaveChangesFlag(false));
        dispatch(diagramActions.setSelectedDiagram(res.data.value.diagram));
        dispatch(diagramActions.updateDiagramsList(res.data.value.diagram));
        if (res.data.value.diagram.tables) {
          dispatch(
            reactflowActions.setDataBase(res.data.value.diagram.models.tables)
          );
        }
        dispatch(
          errorActions.setError({
            message: "Diagram Saved Successfuly",
            statusCode: 200,
          })
        );
      })
      .catch((error) => {
        dispatch(errorActions.setHasError(true));
        dispatch(
          errorActions.setError({
            message: error.response?.data
              ? error.response.data.Reasons[0].Message
              : error.message,
            statusCode: error.response?.status,
          })
        );
        dispatch(loadingActions.setHasLoading(false));
      })
      .finally(() => {});
  };

  return (
    <div className={classes.footerLayout}>
      <div className={classes.content}>
        <InfoIcon primaryColor={IconColor.purple} />
        <span className={classes.title}>
          After making changes, save and proceed to the next level for review.
        </span>
      </div>
      <div className={classes.buttonBox}>
        <BaseButton
          size="sm"
          title="Save changes"
          color="primary"
          onClickHandler={(e) => tableDataFiller()}
        />
      </div>
    </div>
  );
};
export default Footer;
