import classes from "./style/DiagramShareLink.module.scss";
import ShareLinkButton from "../../../components/base/ShareLinkButton";
import BaseButton from "../../../components/base/BaseButton";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";
const DiagramShareLink = (props) => {
  const params = useParams();
  const selectedDiagram = useSelector((state) => state.diagram.selectedDiagram);

  const cancelHandler = () => {
    if (props.exitAction) return props.exitAction(false);
  };
  const shareLinkHandler = () => {
    let link =
      process.env.REACT_APP_BASE_DOMAIN +
      `/diagrams/share/${props.diagram.id}/${selectedDiagram.diagramCurrentVersion}`;
    return link;
  };
  return (
    <div className={classes.formContainer}>
      <div className={classes.header}>
        <span className={classes.headerTitle}>Share Diagram Link</span>
      </div>
      <div className={classes.formBody}>
        <span className={classes.text}>
          To make the diagram visible to others, you can simply share the link
          with them.
        </span>
        <ShareLinkButton linkText={shareLinkHandler()} buttonText="Copy Link" />
      </div>
      <div className={classes.formFooter}>
        <BaseButton
          size="sm"
          color="outline"
          title="Cancel"
          hasRightIcon={false}
          hasLeftIcon={false}
          onClickHandler={(e) => cancelHandler()}
        />
      </div>
    </div>
  );
};
export default DiagramShareLink;
