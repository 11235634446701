import classes from "./style/FileOptions.module.scss";
import SettingsIcon from "@atlaskit/icon/glyph/settings";
import EmojiFrequentIcon from "@atlaskit/icon/glyph/emoji/frequent";
import EditorFilePreviewIcon from "@atlaskit/icon/glyph/editor/file-preview";
import DownloadIcon from "@atlaskit/icon/glyph/download";
import UploadIcon from "@atlaskit/icon/glyph/upload";
import IssueRaiseIcon from "@atlaskit/icon/glyph/issue-raise";
import CopyIcon from "@atlaskit/icon/glyph/copy";
const FileOptions = (props) => {
  return (
    <div className={classes.formLayout}>
      <div className={classes.item} onClick={props.properties}>
        <div className={classes.itemBox}>
          <SettingsIcon label="" />
          <span className={classes.itemText}>Properties</span>
        </div>
      </div>
      <div className={classes.item} onClick={props.versionHistory}>
        <div className={classes.itemBox}>
          <EmojiFrequentIcon label="" />
          <span className={classes.itemText}>Version history</span>
        </div>
      </div>
      <div className={classes.item} onClick={props.presentation}>
        <div className={classes.itemBox}>
          <EditorFilePreviewIcon label="" />
          <span className={classes.itemText}>Presentation mode</span>
        </div>
      </div>
      <div className={classes.seperator}></div>
      <div className={classes.item} onClick={props.import}>
        <div className={classes.itemBox}>
          <DownloadIcon label="" />
          <span className={classes.itemText}>Import</span>
        </div>
      </div>
      <div className={classes.item} onClick={props.export}>
        <div className={classes.itemBox}>
          <UploadIcon label="" />
          <span className={classes.itemText}>Export</span>
        </div>
      </div>
      <div className={classes.seperator}></div>
      <div className={classes.item} onClick={props.newDiagram}>
        <div className={classes.itemBox}>
          <IssueRaiseIcon label="" />
          <span className={classes.itemText}>Create a new diagram</span>
        </div>
      </div>
      <div className={classes.item} onClick={props.copyDiagram}>
        <div className={classes.itemBox}>
          <CopyIcon label="" />
          <span className={classes.itemText}>Make a copy</span>
        </div>
      </div>
    </div>
  );
};
export default FileOptions;
