import DashboardLayout from "../../../components/layouts/DashboardLayout";
import { useDispatch } from "react-redux";
import { dashboardAction } from "../../../store/dashboard-slice";
import { useEffect } from "react";
import { reactflowActions } from "../../../store/reactflow-slice";
import FavoriteDiagramsPanel from "../components/FavoriteDiagramsPanel";
import { menuAction } from "../../../store/menu-slice";
const FavoriteDiagrams = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(reactflowActions.changeDataBase([]));
    dispatch(dashboardAction.setSelectedDashboard("Favorite Diagrams"));
    dispatch(menuAction.setSelectedMenu("Favorites"));
  }, [dispatch]);
  return (
    <DashboardLayout>
      <FavoriteDiagramsPanel />
    </DashboardLayout>
  );
};
export default FavoriteDiagrams;
