import { useEffect, useState } from "react";
import classes from "./style/Menu.module.scss";
import Nucleus from "../../../assets/image/Nucleus.png";
import MediaServicesGridIcon from "@atlaskit/icon/glyph/media-services/grid";
import ChildIssuesIcon from "@atlaskit/icon/glyph/child-issues";
import StarIcon from "@atlaskit/icon/glyph/star";
import PremiumIcon from "@atlaskit/icon/glyph/premium";
import BookIcon from "@atlaskit/icon/glyph/book";
import ShortcutIcon from "@atlaskit/icon/glyph/shortcut";
import { useSelector } from "react-redux";
import MenuItem from "./MenuItem";
import FolderList from "../../../modules/Folder/components/FolderList";

const Menu = () => {
  const selectedMenu = useSelector((state) => state.menu.selectedMenu);
  const [selectedLicensedWorkspace, setSelectedLicensedWorkspace] = useState();
  const menuIconHandler = (title) => {
    switch (title) {
      case "My Diagrams":
        return (
          <ChildIssuesIcon
            label=""
            primaryColor={
              selectedMenu === "My Diagrans" ? "#0C66E4" : "#626F86"
            }
          />
        );
      case "Favorites":
        return (
          <StarIcon
            label=""
            primaryColor={selectedMenu === "Favorites" ? "#0C66E4" : "#626F86"}
          />
        );
      default:
        break;
    }
  };

  const [menu, setMenu] = useState([
    {
      title: "My Diagrams",
      link: "/diagrams",
    },
    {
      title: "Favorites",
      link: "/diagrams/Favorites",
    },
  ]);
  useEffect(() => {
    setSelectedLicensedWorkspace(
      JSON.parse(localStorage.getItem("selectedLicensedWorkspace"))?.name
    );
  }, [localStorage]);

  return (
    <>
      <div className={classes.menuHeader}>
        <div className={classes.headerBox}>
          <img
            className={classes.headerIcon}
            src={Nucleus}
            alt="workspace-avatar"
          />
          <span className={classes.headerTitle}>
            {selectedLicensedWorkspace}
          </span>
        </div>
      </div>
      {menu?.map((item, index) => {
        return (
          <MenuItem
            key={index}
            title={item.title}
            leftIcon={menuIconHandler(item.title)}
            rightIcon={item?.rightIcon}
            link={item.link}
          />
        );
      })}
      <FolderList />
    </>
  );
};
export default Menu;
