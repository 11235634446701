import classes from "./style/EmbedDiagram.module.scss";
import ShareLinkButton from "../../../components/base/ShareLinkButton";
import BaseButton from "../../../components/base/BaseButton";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";
const EmbedDiagram = (props) => {
  const params = useParams();
  const selectedDiagram = useSelector((state) => state.diagram.selectedDiagram);

  const cancelHandler = () => {
    if (props.exitAction) return props.exitAction(false);
  };
  const embedLinkHandler = () => {
    let link =
      process.env.REACT_APP_BASE_DOMAIN +
      `/diagrams/embed/${props.diagram.id}/${selectedDiagram.diagramCurrentVersion}`;
    return link;
  };
  return (
    <div className={classes.formContainer}>
      <div className={classes.header}>
        <span className={classes.headerTitle}>Embed Diagram Link</span>
      </div>
      <div className={classes.formBody}>
        <span className={classes.text}>
          Others can use this diagram on their websites by coping the link.
        </span>
        <ShareLinkButton linkText={embedLinkHandler()} buttonText="Copy Link" />
      </div>
      <div className={classes.formFooter}>
        <BaseButton
          size="sm"
          color="outline"
          title="Cancel"
          hasRightIcon={false}
          hasLeftIcon={false}
          onClickHandler={(e) => cancelHandler()}
        />
      </div>
    </div>
  );
};
export default EmbedDiagram;
