import classes from "./style/DiagramCreationForm.module.scss";
import Input from "../../../components/base/Input";
import Textarea from "../../../components/base/Textarea";
import ToggleSwitch from "../../../components/base/ToggleSwitch";
import BaseButton from "../../../components/base/BaseButton";
import Nucleus from "../../../assets/image/Nucleus.png";
import { useNavigate, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { diagramActions } from "../store/diagram-slice";
import { reactflowActions } from "../../../store/reactflow-slice";
import { errorActions } from "../../../store/error-slice";
import { loadingActions } from "../../../store/loading-slice";
import { folderActions } from "../../Folder/store/folder-slice";
import { useDispatch, useSelector } from "react-redux";
import DataBaseForm from "./DataBaseForm";
import { SortDiagrams } from "../../../types/SharedTypes";
import { RepoFactory } from "../../../baseRepository/Factory";
const DiagramSRepository = () => RepoFactory.get("diagram");
const FolderRepository = () => RepoFactory.get("folder");
const DataVariationsRepository = () => RepoFactory.get("dataVariations");

const DiagramCreationForm = (props) => {
  const { folderId } = useParams();
  const [databaseList, setDatabaseList] = useState([]);
  const [name, setName] = useState(props.diagram ? props.diagram.name : "");
  const [description, setDescription] = useState(
    props.diagram ? props.diagram.description : ""
  );
  const databasetype = useSelector((state) => state.diagram.selectedDatabase);
  const sortType = useSelector((state) => state.diagram.sortType);
  const [diagramtype, setDiagramType] = useState(
    props.diagram ? props.diagram.isPrivate : false
  );
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const selectedWorkSpaceId = useSelector(
    (state) => state.auth.selectedWorkSpaceId
  );
  const selectedDiagram = useSelector((state) => state.diagram.selectedDiagram);

  const setNameHandler = (e) => {
    setName(e.target.value);
  };
  const setDescriptionHandler = (e) => {
    setDescription(e.target.value);
  };

  const setDiagramTypeHandler = (selectedType) => {
    setDiagramType(selectedType.target.checked);
  };
  const cancelHandler = () => {
    dispatch(diagramActions.setSelectedDatabse(null));
    if (props.exitAction) return props.exitAction(false);
  };
  const addDiagram = async () => {
    if (name && databasetype) {
      dispatch(loadingActions.setHasLoading(true));
      dispatch(errorActions.setHasError(false));
      dispatch(errorActions.setError(""));
      let body = {
        name: name,
        description: description,
        isPrivate: diagramtype,
        dataVariationId: databasetype.id,
        tenantId: selectedWorkSpaceId,
        businessURl: null,
        tags: null,
        logo: null,
      };

      await DiagramSRepository()
        .createDiagram(body)
        .then((res) => {
          dispatch(diagramActions.setSelectedDiagram(res.data.value.diagram));
          dispatch(diagramActions.updateDiagramsList(res.data.value.diagram));
          dispatch(diagramActions.setSelectedDatabse(null));
          dispatch(reactflowActions.changeDataBase([]));
          if (window.location.pathname.includes("folders") && folderId) {
            moveToFolderHandler(
              res.data.value.diagram.id,
              res.data.value.diagram.diagramCurrentVersion,
              folderId
            );
          } else {
            dispatch(errorActions.setHasError(true));
            dispatch(
              errorActions.setError({
                message: "Diagram Created Successfuly",
                statusCode: 200,
              })
            );
            setTimeout(() => {
              navigate(
                `/diagrams/${res.data.value.diagram.id}/${res.data.value.diagram.diagramCurrentVersion}`
              );
            }, 700);
          }
          getAllDigrams();
          if (props.exitAction) {
            return props.exitAction(false);
          }
          dispatch(errorActions.setHasError(true));
          dispatch(
            errorActions.setError({
              message: "Diagram Created Successfuly",
              statusCode: 200,
            })
          );
        })
        .catch((error) => {
          dispatch(errorActions.setHasError(true));
          dispatch(
            errorActions.setError({
              message: error.response?.data
                ? error.response.data.Reasons[0].Message
                : error.message,
              statusCode: error.response?.status,
            })
          );
        })
        .finally(() => {
          dispatch(loadingActions.setHasLoading(false));
        });
    } else if (name === "") {
      dispatch(errorActions.setHasError(true));
      dispatch(
        errorActions.setError({
          message: "Please Enter Diagram Name !",
          statusCode: 50,
        })
      );
    } else {
      dispatch(errorActions.setHasError(true));
      dispatch(
        errorActions.setError({
          message: "Please Define Database Type !",
          statusCode: 50,
        })
      );
    }
  };
  const moveToFolderHandler = async (diagramId, currentVersion, folderId) => {
    dispatch(loadingActions.setHasLoading(true));
    dispatch(errorActions.setHasError(false));
    dispatch(errorActions.setError(""));
    let body = {
      tenantId: selectedWorkSpaceId,
      folderId: folderId,
      diagramId: diagramId,
    };
    await FolderRepository()
      .assignDiagram(body)
      .then((res) => {
        dispatch(errorActions.setHasError(true));
        dispatch(
          errorActions.setError({
            message: "Diagram Created And Moved To Folder Successfuly",
            statusCode: 200,
          })
        );
        setTimeout(() => {
          navigate(`/diagrams/${diagramId}/${currentVersion}`);
        }, 700);
      })
      .catch((error) => {
        dispatch(errorActions.setHasError(true));
        dispatch(errorActions.setError(error.response?.data));
      })
      .finally(() => {
        dispatch(loadingActions.setHasLoading(false));
      });
  };
  const getDiagramByFolderId = () => {
    dispatch(loadingActions.setHasLoading(true));
    dispatch(errorActions.setHasError(false));
    dispatch(errorActions.setError(""));
    FolderRepository()
      .getFolderById(folderId)
      .then((res) => {
        dispatch(folderActions.setSelectedFolder(res.data.value.folder));
      })
      .catch((error) => {
        dispatch(errorActions.setHasError(true));
        dispatch(errorActions.setError(error.response?.data));
      })
      .finally(() => {
        dispatch(loadingActions.setHasLoading(false));
      });
  };
  const updateDiagram = async () => {
    if (name && databasetype) {
      dispatch(loadingActions.setHasLoading(true));
      dispatch(errorActions.setHasError(false));
      dispatch(errorActions.setError(""));
      let modelsLenght = props?.diagram?.models.length;
      let body = {
        diagramId: props.diagram.id,
        name: name,
        description: description,
        isPrivate: diagramtype,
        dataVariationId: databasetype.id,
        tenantId: selectedWorkSpaceId,
        diagramVersionId: props.diagram.diagramCurrentVersion,
        businessURl: props.diagram.businessURl
          ? props.diagram.businessURl
          : null,
        tags: props.diagram.tags ? props.diagram.tags : null,
        logo: props.diagram.logo ? props.diagram.logo : null,
        diagram: {
          name: name,
          ...props?.diagram?.models[modelsLenght > 0 ? modelsLenght - 1 : 0]
            ?.data,
        },
      };
      await DiagramSRepository()
        .updateDiagram(body)
        .then((res) => {
          if (window.location.pathname.includes("folders") && folderId) {
            getDiagramByFolderId();
          }
          dispatch(diagramActions.setSelectedDiagram(res.data.value.diagram));
          dispatch(diagramActions.updateDiagramsList(res.data.value.diagram));
          dispatch(diagramActions.setSelectedDatabse(null));
          getAllDigrams();
          dispatch(errorActions.setHasError(true));
          dispatch(
            errorActions.setError({
              message: "Diagram Updated Successfuly",
              statusCode: 200,
            })
          );
          if (props.exitAction) {
            return props.exitAction(false);
          }
        })
        .catch((error) => {
          dispatch(errorActions.setHasError(true));
          dispatch(
            errorActions.setError({
              message: error.response?.data
                ? error.response.data.Reasons[0].Message
                : error.message,
              statusCode: error.response?.status,
            })
          );
        })
        .finally(() => {
          dispatch(loadingActions.setHasLoading(false));
        });
    } else if (databasetype === null) {
      dispatch(errorActions.setHasError(true));
      dispatch(
        errorActions.setError({
          message: "Please Define Database Type !",
          statusCode: 50,
        })
      );
    } else {
      dispatch(errorActions.setHasError(true));
      dispatch(
        errorActions.setError({
          message: "Please Enter Diagram Name !",
          statusCode: 50,
        })
      );
    }
  };
  const getAllDigrams = async () => {
    dispatch(loadingActions.setHasLoading(true));
    dispatch(errorActions.setHasError(false));
    dispatch(errorActions.setError(""));
    let array = [];
    await DiagramSRepository()
      .getAllDigrams(selectedWorkSpaceId)
      .then((res) => {
        array = res.data.value.diagrams;
      })
      .catch((error) => {
        dispatch(errorActions.setHasError(true));
        dispatch(
          errorActions.setError({
            message: error.response?.data
              ? error.response.data.Reasons[0].Message
              : error.message,
            statusCode: error.response?.status,
          })
        );
      })
      .finally(() => {
        dispatch(loadingActions.setHasLoading(false));
        dispatch(diagramActions.setDiagramsList(SortDiagrams(sortType, array)));
      });
  };
  const getDataVariations = async () => {
    dispatch(loadingActions.setHasLoading(true));
    dispatch(errorActions.setHasError(false));
    dispatch(errorActions.setError(""));
    await DataVariationsRepository()
      .getAllDataVariations()
      .then((res) => {
        setDatabaseList(res.data.value.dataVariations);
        if (props.diagram) {
          const foundData = res.data.value.dataVariations?.find(
            (element) => element.id === String(props.diagram.dataVariationId)
          );
          dispatch(diagramActions.setSelectedDatabse(foundData));
        }
      })
      .catch((error) => {
        dispatch(errorActions.setHasError(true));
        dispatch(
          errorActions.setError({
            message: error.response?.data
              ? error.response.data.Reasons[0].Message
              : error.message,
            statusCode: error.response?.status,
          })
        );
      })
      .finally(() => {
        dispatch(loadingActions.setHasLoading(false));
      });
  };
  useEffect(() => {
    getDataVariations();
  }, []);
  return (
    <div className={classes.formContainer}>
      <div className={classes.formHeader}>
        <span className={classes.headerTitle}>
          {props.diagram
            ? props.type === "properties"
              ? "Properties"
              : "Edit Diagram"
            : "Create new diagram"}
        </span>
      </div>
      <div className={classes.formContent}>
        <div className={classes.contentHeader}>
          <div className={classes.workspaceBox}>
            <img
              alt="workspace-avatar"
              src={Nucleus}
              className={classes.workspaceAvatar}
            />
            <span className={classes.workspaceTitle}>
              {
                JSON.parse(localStorage.getItem("selectedLicensedWorkspace"))
                  ?.name
              }
            </span>
          </div>
        </div>
        <Input
          name="diagramName"
          placeholder="Enter diagram name"
          value={name}
          handleChange={(e) => {
            setNameHandler(e);
          }}
        />
        <Textarea
          placeholder="Description"
          name="description"
          value={description}
          maxLength={1000}
          handleChange={(e) => {
            setDescriptionHandler(e);
          }}
        />
        <DataBaseForm databaseList={databaseList} />
        <div className={classes.privateBox}>
          <span>{diagramtype ? "Private" : "Public"}</span>
          <ToggleSwitch
            checked={diagramtype}
            checkAction={(e) => {
              setDiagramTypeHandler(e);
            }}
            disabled={false}
          />
        </div>
      </div>
      <div className={classes.formAction}>
        <BaseButton
          size="sm"
          color="outline"
          title="Cancel"
          hasRightIcon={false}
          hasLeftIcon={false}
          onClickHandler={(e) => cancelHandler()}
        />
        <BaseButton
          size="sm"
          title={props.diagram ? "Update" : "Add"}
          color="primary"
          hasRightIcon={false}
          hasLeftIcon={false}
          onClickHandler={(e) => {
            props.diagram ? updateDiagram() : addDiagram();
          }}
        />
      </div>
    </div>
  );
};
export default DiagramCreationForm;
