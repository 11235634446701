import classes from "./style/MoveToFolder.module.scss";
import SelectInput from "../../../components/base/SelectInput";
import Input from "../../../components/base/Input";
import BaseButton from "../../../components/base/BaseButton";
import { Add } from "iconsax-react";
import { useSelector, useDispatch } from "react-redux";
import { diagramActions } from "../store/diagram-slice";
import { folderActions } from "../../Folder/store/folder-slice";
import { useState } from "react";
import { loadingActions } from "../../../store/loading-slice";
import { errorActions } from "../../../store/error-slice";
import { useParams } from "react-router-dom";
import { SortDiagrams } from "../../../types/SharedTypes";
import { RepoFactory } from "../../../baseRepository/Factory";
const FolderRepository = () => RepoFactory.get("folder");
const DiagramRepository = () => RepoFactory.get("diagram");

const MoveToFolder = (props) => {
  const { folderId } = useParams();
  const dispatch = useDispatch();
  const folderList = useSelector((state) => state.folder.folderList);
  const sortType = useSelector((state) => state.diagram.sortType);
  const selectedWorkSpaceId = useSelector(
    (state) => state.auth.selectedWorkSpaceId
  );
  const [selectedFolder, setSelectedFolder] = useState(null);
  const [folderName, setFolderName] = useState("");
  const [creatFolderFlag, setCreateFolderFlag] = useState(false);

  const buttonIcon = () => {
    return <Add />;
  };
  const cancelHandler = () => {
    if (props.exitAction) return props.exitAction(false);
  };
  const getDiagramsListWithPageParams = () => {
    if (window.location.pathname.includes("folders") && folderId) {
      getDiagramByFolderId();
    } else {
      getAllDigrams();
    }
  };
  const getDiagramByFolderId = () => {
    dispatch(loadingActions.setHasLoading(true));
    dispatch(errorActions.setHasError(false));
    dispatch(errorActions.setError(""));
    FolderRepository()
      .getFolderById(folderId)
      .then((res) => {
        dispatch(folderActions.setSelectedFolder(res.data.value.folder));
      })
      .catch((error) => {
        dispatch(errorActions.setHasError(true));
        dispatch(errorActions.setError(error.response?.data));
      })
      .finally(() => {
        dispatch(loadingActions.setHasLoading(false));
      });
  };
  const createAndMoveFolder = async () => {
    if (folderName !== "") {
      dispatch(loadingActions.setHasLoading(true));
      dispatch(errorActions.setHasError(false));
      dispatch(errorActions.setError(""));
      let body = {
        tenantId: selectedWorkSpaceId,
        name: folderName,
      };
      await FolderRepository()
        .createFolder(body)
        .then((res) => {
          moveToFolderHandler(res.data.value.folder.id);
          dispatch(errorActions.setHasError(true));
          dispatch(
            errorActions.setError({
              message: "Diagram Moved To Folder Successfuly",
              statusCode: 200,
            })
          );
        })
        .catch((error) => {
          dispatch(errorActions.setHasError(true));
          dispatch(
            errorActions.setError({
              message: error.response?.data
                ? error.response.data.Reasons[0].Message
                : error.message,
              statusCode: error.response?.status,
            })
          );
        })
        .finally(() => {
          dispatch(loadingActions.setHasLoading(false));
        });
    }
  };
  const getAllFolders = async () => {
    dispatch(loadingActions.setHasLoading(true));
    dispatch(errorActions.setHasError(false));
    dispatch(errorActions.setError(""));
    let array = [];
    await FolderRepository()
      .getAllFolders(selectedWorkSpaceId)
      .then((res) => {
        array = res.data.value.folders;
      })
      .catch((error) => {
        dispatch(errorActions.setHasError(true));
        dispatch(
          errorActions.setError({
            message: error.response?.data
              ? error.response.data.Reasons[0].Message
              : error.message,
            statusCode: error.response?.status,
          })
        );
      })
      .finally(() => {
        dispatch(loadingActions.setHasLoading(false));
        dispatch(folderActions.setFolderList(array));
      });
  };

  const moveToFolderHandler = async (folderId) => {
    dispatch(loadingActions.setHasLoading(true));
    dispatch(errorActions.setHasError(false));
    dispatch(errorActions.setError(""));
    let body = {
      tenantId: selectedWorkSpaceId,
      folderId: folderId,
      diagramId: props.diagram.id,
    };
    await FolderRepository()
      .assignDiagram(body)
      .then((res) => {
        getAllFolders();
        getDiagramsListWithPageParams();
      })
      .catch((error) => {
        dispatch(errorActions.setHasError(true));
        dispatch(errorActions.setError(error.response?.data));
      })
      .finally(() => {
        dispatch(loadingActions.setHasLoading(false));
        return props.exitAction(false);
      });
  };
  const getAllDigrams = async () => {
    dispatch(loadingActions.setHasLoading(true));
    dispatch(errorActions.setHasError(false));
    dispatch(errorActions.setError(""));
    let array = [];
    await DiagramRepository()
      .getAllDigrams(selectedWorkSpaceId)
      .then((res) => {
        array = res.data.value.diagrams;
      })
      .catch((error) => {
        dispatch(errorActions.setHasError(true));
        dispatch(
          errorActions.setError({
            message: error.response?.data
              ? error.response.data.Reasons[0].Message
              : error.message,
            statusCode: error.response?.status,
          })
        );
      })
      .finally(() => {
        dispatch(loadingActions.setHasLoading(false));
        dispatch(diagramActions.setDiagramsList(SortDiagrams(sortType, array)));
      });
  };
  return (
    <div className={classes.formContainer}>
      <div className={classes.header}>
        <span className={classes.headerTitle}>Move to Folder</span>
      </div>
      {creatFolderFlag === false && (
        <div className={classes.inputbox}>
          <SelectInput
            label="Folder"
            placeHolder="Select your Folder"
            options={folderList.map((item) => {
              return { value: item.id, title: item.name };
            })}
            handleselectedOption={(e) => setSelectedFolder(e)}
          />
          <div className={classes.lineBox}>
            <div className={classes.line}></div>
            <span className={classes.lineText}>Or</span>
            <div className={classes.line}></div>
          </div>
          <BaseButton
            title="Add New Folder"
            hasRightIcon={false}
            hasLeftIcon={true}
            icon={buttonIcon()}
            hasBlock={true}
            onClickHandler={(e) => {
              setCreateFolderFlag(true);
            }}
          />
        </div>
      )}
      {creatFolderFlag === true && (
        <div className={classes.inputbox}>
          <Input
            label="Name"
            type="text"
            placeholder="Enter Folders Name"
            name="folderName"
            handleChange={(e) => setFolderName(e.target.value)}
          />
        </div>
      )}

      <div className={classes.formAction}>
        <BaseButton
          size="sm"
          color="outline"
          title="Cancel"
          hasRightIcon={false}
          hasLeftIcon={false}
          onClickHandler={(e) => cancelHandler()}
        />
        <BaseButton
          size="sm"
          title="Submit"
          color="primary"
          hasRightIcon={false}
          hasLeftIcon={false}
          onClickHandler={(e) => {
            creatFolderFlag
              ? createAndMoveFolder()
              : moveToFolderHandler(selectedFolder.value);
          }}
        />
      </div>
    </div>
  );
};
export default MoveToFolder;
