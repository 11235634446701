import classes from "./CreateForm.module.scss";
import Form from "react-bootstrap/Form";
import { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { reactflowActions } from "../../../../store/reactflow-slice";
import { More, Key, Ranking, Flag2, InfoCircle, People } from "iconsax-react";
import EditorAddIcon from "@atlaskit/icon/glyph/editor/add";
import EditorBackgroundColorIcon from "@atlaskit/icon/glyph/editor/background-color";
import BaseButton from "../../../../components/base/BaseButton";
import ColorPicker from "../../components/ColorPicker";
import ColumnKeyAction from "../../../../components/core/sidebar/ColumnKeyAction";
import ColumnOption from "../../components/ColumnOption";
import { Typeahead } from "react-bootstrap-typeahead";
const CreateForm = (props) => {
  const dispatch = useDispatch();
  const [enteredName, setEnteredName] = useState("");
  const database = useSelector((state) => state.reactflow.database);
  const diagramVariations = useSelector(
    (state) => state.diagram.diagramVariations
  );
  const selectedDiagram = useSelector((state) => state.diagram.selectedDiagram);
  const [newDatabaseLength, setNewDatabaseLength] = useState(0);
  const [enteredCol, setEnteredCol] = useState([]);
  const [selectCol, setSelectedCol] = useState(null);
  const [columnOptionFlag, setColumnOptionFlag] = useState(false);
  const [columnKeyFlag, setColumnKeyFlag] = useState(false);
  const [colorOptionFlag, setColorOptionFlag] = useState(false);
  const colorOption = useRef();
  const keyOption = useRef();
  const columnOption = useRef();

  const handleClickOutside = (event) => {
    if (colorOption.current && !colorOption.current.contains(event.target)) {
      setColorOptionFlag(false);
    }
    if (keyOption.current && !keyOption.current.contains(event.target)) {
      setColumnKeyFlag(false);
    }
    if (columnOption.current && !columnOption.current.contains(event.target)) {
      setColumnOptionFlag(false);
    }
  };
  useEffect(() => {
    document.addEventListener("click", handleClickOutside, true);
    return () => {
      document.removeEventListener("click", handleClickOutside, true);
    };
  }, []);

  const changeTableColor = (state) => {
    let tempNode = { ...props.table };
    let tempdata = { ...tempNode.data };
    tempdata.schemaColor = state;
    tempNode.data = tempdata;
    dispatch(reactflowActions.setSaveChangesFlag(true));
    dispatch(reactflowActions.updateNode(tempNode));
    setColorOptionFlag(false);
  };

  useEffect(() => {
    setEnteredName(props.table.data.name);
    if (props.table.data.enum) {
      setEnteredCol(props.table.data.values);
    } else {
      setEnteredCol(props.table.data.columns);
    }
    setNewDatabaseLength(database.length);
  }, [props.table, database]);
  const addCol = (event) => {
    let colNewData = {
      id: Math.random().toString(),
      name:
        selectedDiagram.dataVariationId === "16022509476708353"
          ? "Prop"
          : "Col",
      type: "int",
      description: "",
      key: 0,
      required: true,
      nullable: false,
    };
    let tempCol = [...enteredCol, colNewData];
    setEnteredCol(tempCol);
    let tempNode = { ...props.table };
    let tempdata = { ...tempNode.data };
    tempdata.columns = tempCol;
    tempNode.data = tempdata;
    dispatch(reactflowActions.updateNode(tempNode));
    dispatch(reactflowActions.setSaveChangesFlag(true));
  };
  const addValue = (event) => {
    let valueNewData = {
      id: Math.random().toString(),
      name: "value",
      description: "",
      value: 0,
    };
    let tempCol = [...enteredCol, valueNewData];
    setEnteredCol(tempCol);
    let tempNode = { ...props.table };
    let tempdata = { ...tempNode.data };
    tempdata.values = tempCol;
    tempNode.data = tempdata;
    dispatch(reactflowActions.setSaveChangesFlag(true));
    dispatch(reactflowActions.updateNode(tempNode));
  };
  const adddescription = (event) => {
    let tempNode = { ...props.table };
    let tempdata = { ...tempNode.data };
    tempdata.description = event.target.value;
    tempNode.data = tempdata;
    dispatch(reactflowActions.setSaveChangesFlag(true));
    dispatch(reactflowActions.updateNode(tempNode));
  };
  const changeEnteredColName = (event, id) => {
    let colstemp = [];
    enteredCol.forEach((element) => {
      if (element.id === id) {
        let col = { ...element };
        col.name = event.target.value;
        colstemp.push(col);
      } else {
        colstemp.push(element);
      }
    });
    setEnteredCol(colstemp);
    let tempNode = { ...props.table };
    let tempdata = { ...tempNode.data };
    if (tempdata.enum) {
      tempdata.values = colstemp;
    } else {
      tempdata.columns = colstemp;
    }
    tempNode.data = tempdata;
    dispatch(reactflowActions.updateNode(tempNode));
    dispatch(reactflowActions.setSaveChangesFlag(true));
  };
  const changeEnteredColType = (event, id) => {
    let colstemp = [];
    enteredCol.forEach((element) => {
      if (element.id === id) {
        let col = { ...element };
        col.type = event[0]?.propertyType ? event[0].propertyType : "";
        colstemp.push(col);
      } else {
        colstemp.push(element);
      }
    });
    setEnteredCol(colstemp);
    let tempNode = { ...props.table };
    let tempdata = { ...tempNode.data };
    tempdata.columns = colstemp;
    tempNode.data = tempdata;
    dispatch(reactflowActions.updateNode(tempNode));
    dispatch(reactflowActions.setSaveChangesFlag(true));
  };
  const changeEnteredColValue = (event, id) => {
    let colstemp = [];
    enteredCol.forEach((element) => {
      if (element.id === id) {
        let col = { ...element };
        col.value = event.target.value;
        colstemp.push(col);
      } else {
        colstemp.push(element);
      }
    });
    setEnteredCol(colstemp);
    let tempNode = { ...props.table };
    let tempdata = { ...tempNode.data };
    tempdata.values = colstemp;
    tempNode.data = tempdata;
    dispatch(reactflowActions.updateNode(tempNode));
    dispatch(reactflowActions.setSaveChangesFlag(true));
  };

  const deleteCol = (id) => {
    dispatch(reactflowActions.deleteEdge(id));
    let filteredCol = enteredCol.filter((item) => {
      if (item.id !== id) {
        return item;
      }
    });

    let tempNode = { ...props.table };
    let tempdata = { ...tempNode.data };
    if (tempdata.enum) {
      tempdata.values = filteredCol;
    } else {
      tempdata.columns = filteredCol;
    }
    tempNode.data = tempdata;
    setColumnOptionFlag(false);
    dispatch(reactflowActions.updateNode(tempNode));
    dispatch(reactflowActions.setSaveChangesFlag(true));
  };
  const keyValueHandler = (e, id) => {
    setColumnKeyFlag(false);
    let colstemp = [];
    enteredCol.forEach((element) => {
      if (element.id === id) {
        let col = { ...element };
        col.key = e;
        colstemp.push(col);
      } else {
        colstemp.push(element);
      }
    });
    setEnteredCol(colstemp);
    let tempNode = { ...props.table };
    let tempdata = { ...tempNode.data };
    tempdata.columns = colstemp;
    tempNode.data = tempdata;
    dispatch(reactflowActions.updateNode(tempNode));
    dispatch(reactflowActions.setSaveChangesFlag(true));
  };
  const columnNullableHandler = (id) => {
    let colstemp = [];
    enteredCol.forEach((element) => {
      if (element.id === id) {
        let col = { ...element };
        col.nullable = !element.nullable;
        colstemp.push(col);
      } else {
        colstemp.push(element);
      }
    });
    setEnteredCol(colstemp);
    let tempNode = { ...props.table };
    let tempdata = { ...tempNode.data };
    tempdata.columns = colstemp;
    tempNode.data = tempdata;
    dispatch(reactflowActions.updateNode(tempNode));
    dispatch(reactflowActions.setSaveChangesFlag(true));
  };
  const columnOptionHandler = (item) => {
    if (selectCol === null || selectCol?.id !== item.id) {
      setSelectedCol(item);
      setColumnOptionFlag(true);
    } else if (selectCol?.id === item.id) {
      setSelectedCol(null);
      setColumnOptionFlag(false);
    }
  };
  const keyOptionHandler = (item) => {
    if (selectCol === null || selectCol?.id !== item.id) {
      setSelectedCol(item);
      setColumnKeyFlag(true);
    } else if (selectCol?.id === item.id) {
      setSelectedCol(null);
      setColumnKeyFlag(false);
    }
  };
  const columnDescriptionHandler = (e, id) => {
    let colstemp = [];
    enteredCol.forEach((element) => {
      if (element.id === id) {
        let col = { ...element };
        col.description = e.target.value;
        colstemp.push(col);
      } else {
        colstemp.push(element);
      }
    });
    setEnteredCol(colstemp);
    let tempNode = { ...props.table };
    let tempdata = { ...tempNode.data };
    if (tempdata.enum) {
      tempdata.values = colstemp;
    } else {
      tempdata.columns = colstemp;
    }
    tempNode.data = tempdata;
    dispatch(reactflowActions.updateNode(tempNode));
    dispatch(reactflowActions.setSaveChangesFlag(true));
  };
  const colHeightCalc = (index) => {
    var bodyRect = document.body.getBoundingClientRect();
    var rect = document
      .getElementById("column" + index)
      .getBoundingClientRect();
    var offset = rect.top - bodyRect.top;
    return offset + 40;
  };
  const colorHeightCalc = (index) => {
    var bodyRect = document.body.getBoundingClientRect();
    var rect = document.getElementById("color" + index).getBoundingClientRect();
    var offset = rect.top - bodyRect.top;
    return offset + 25;
  };
  const openColorModalHandler = () => {
    setColorOptionFlag(!colorOptionFlag);
  };
  const addIcon = () => {
    return <EditorAddIcon />;
  };
  return (
    <div className={classes.createForm}>
      <Form>
        {enteredCol &&
          enteredCol.map((item, index) => {
            return (
              <div
                key={index}
                className={classes.colItems}
                id={"column" + index}
              >
                <div>
                  <Form.Group>
                    <Form.Control
                      type="text"
                      id="ColName"
                      value={item.name}
                      onChange={(e) => changeEnteredColName(e, item.id)}
                    />
                  </Form.Group>
                </div>

                <div>
                  {!props.table.data.enum ? (
                    <Form.Group>
                      <Typeahead
                        id={"basic-typeahead-single " + Math.random()}
                        labelKey={(diagramVariations) =>
                          `${diagramVariations.propertyType} `
                        }
                        onChange={(e) => changeEnteredColType(e, item.id)}
                        options={diagramVariations}
                        placeholder="Choose a state..."
                        selected={
                          item.type
                            ? [
                                {
                                  propertyType: item.type,
                                },
                              ]
                            : []
                        }
                      />
                    </Form.Group>
                  ) : (
                    <Form.Group>
                      <Form.Control
                        type="text"
                        id="EnumValue"
                        value={item.value}
                        onChange={(e) => changeEnteredColValue(e, item.id)}
                      />
                    </Form.Group>
                  )}
                </div>
                {!props.table.data.enum && (
                  <div
                    className={classes.iconBox}
                    onClick={(e) => {
                      columnNullableHandler(item.id);
                    }}
                  >
                    <span
                      style={{ color: item.nullable ? "#292d32" : "#A9ABAD" }}
                      className={classes.nullableIcon}
                    >
                      N
                    </span>
                  </div>
                )}
                {!props.table.data.enum && (
                  <div
                    className={classes.iconBox}
                    onClick={(e) => {
                      keyOptionHandler(item);
                    }}
                  >
                    {item.key === 1 ? (
                      <Key
                        style={{ color: " #292d32" }}
                        className={classes.itemIcon}
                      />
                    ) : item.key === 2 ? (
                      <Ranking
                        style={{ color: " #292d32" }}
                        className={classes.itemIcon}
                      />
                    ) : item.key === 3 ? (
                      <Flag2
                        style={{ color: " #292d32" }}
                        className={classes.itemIcon}
                      />
                    ) : (
                      <InfoCircle
                        style={{ color: "#A9ABAD" }}
                        className={classes.itemIcon}
                      />
                    )}
                  </div>
                )}

                <div
                  className={classes.iconBox}
                  onClick={(e) => {
                    columnOptionHandler(item);
                  }}
                >
                  <More
                    style={{ color: "#A9ABAD" }}
                    className={classes.itemIcon}
                  />
                </div>
                {columnKeyFlag && selectCol && selectCol.id === item.id && (
                  <div
                    className={classes.columnKeyOption}
                    style={{ top: colHeightCalc(index) }}
                    ref={keyOption}
                  >
                    <ColumnKeyAction
                      keyValue={(e) => keyValueHandler(e, item.id)}
                    />
                  </div>
                )}
                {columnOptionFlag && selectCol && selectCol.id === item.id && (
                  <div
                    className={classes.columnOption}
                    style={{ top: colHeightCalc(index) }}
                    ref={columnOption}
                  >
                    <ColumnOption
                      type={props.table.data.enum ? "enum" : ""}
                      onDeleteColumn={(e) => deleteCol(item.id)}
                      descriptionHandler={(e) => {
                        columnDescriptionHandler(e, item.id);
                      }}
                      description={item.description}
                    />
                  </div>
                )}
              </div>
            );
          })}
        {props.table.descriptionFlag && (
          <div className={classes.descriptionBox}>
            <span className={classes.descriptionTitle}>Description</span>
            <Form.Group className={classes.descriptionInput}>
              <Form.Control
                type="text"
                id="description"
                value={props.table.data.description ?? ""}
                placeholder="Add a description"
                onChange={(e) => adddescription(e)}
              />
            </Form.Group>
          </div>
        )}
        <div className={classes.actions}>
          <BaseButton
            size="sm"
            title={
              props.table.data.enum
                ? "Value"
                : selectedDiagram.dataVariationId === "16022509476708353"
                ? "Property"
                : "Column"
            }
            onClickHandler={(e) => {
              props.table.data.enum ? addValue() : addCol();
            }}
            hasLeftIcon={true}
            icon={addIcon()}
            color="secondary"
          ></BaseButton>
          <div
            className={classes.colorAction}
            onClick={(e) => openColorModalHandler()}
            id={"color" + props.table.id}
          >
            <EditorBackgroundColorIcon className={classes.colorIcon} />
          </div>
          {colorOptionFlag && (
            <div
              style={{ top: colorHeightCalc(props.table.id) }}
              className={classes.colorOption}
              ref={colorOption}
            >
              <ColorPicker colorAction={changeTableColor} />
            </div>
          )}
        </div>
      </Form>
    </div>
  );
};
export default CreateForm;
