import BaseRepository from "../../../baseRepository/BaseRepository";
const resource = "/api/v1/charmGraphService/DataVariations";
const config = {
  headers: { Authorization: `Bearer ${getCookie("access_token")}` },
};
function getCookie(name) {
  const value = `; ${document.cookie}`;
  const parts = value.split(`; ${name}=`);
  if (parts.length === 2) return parts.pop().split(";").shift();
}
const DataVariationsRepository = {
  getAllDataVariations() {
    return BaseRepository.get(`${resource}/GetAll`);
  },
  getDatabasPropertyDtos(id) {
    return BaseRepository.get(
      `${resource}/GetDatabasePropertyTypesById?id=${id}`
    );
  },
};
export default DataVariationsRepository;
