import { Route, Routes } from "react-router-dom";
import Diagram from "../pages/Diagram";
import FavoriteDiagrams from "../pages/FavotireDiagrams";
import ReactFlow from "../../ReactFlow/pages/ReactFlow";
import ShareReactFlow from "../../ReactFlow/pages/ShareReactFlow";
const DiagramRouter = () => {
  return (
    <Routes>
      <Route path="/" exact element={<Diagram />} />
      <Route path="/Favorites" exact element={<FavoriteDiagrams />} />
      <Route exact path="/:Id/:currentVersion" element={<ReactFlow />} />
      <Route
        path="/share/:Id/:currentVersion"
        exact
        element={<ShareReactFlow link="share" />}
      />
      <Route
        path="/embed/:Id/:currentVersion"
        exact
        element={<ShareReactFlow link="embed" />}
      />
      <Route
        path="/createNew/:Id/:currentVersion"
        exact
        element={<ShareReactFlow link="createNew" />}
      />
    </Routes>
  );
};

export default DiagramRouter;
