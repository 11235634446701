import classes from "./style/PageHeader.module.scss";
import BaseButton from "./BaseButton";
import SelectInput from "./SelectInput";
import { useState } from "react";
import DiagramCreationForm from "../../modules/Diagram/components/DiagramCreationForm";
import AddIcon from "@atlaskit/icon/glyph/add";
import Modal from "./Modal";
import { Colors } from "../../types/SharedTypes";
const PageHeader = (props) => {
  const [diagramModalFlag, setDiagramModalFlag] = useState(false);
  const buttonIcon = () => {
    return <AddIcon primaryColor={Colors.Natural_0} />;
  };
  const exitModal = (status) => {
    setDiagramModalFlag(status);
  };
  const sortHandler = (e) => {
    return props.onSort(e);
  };

  return (
    <div className={classes.headerLayout}>
      <div className={classes.contents}>
        <div className={classes.titleBox}>
          <span className={classes.pageTitle}>{props.type}</span>
        </div>
        <div className={classes.actionBox}>
          <SelectInput
            placeHolder="Sort By ..."
            defaultValue={props.defaultSort}
            options={props.sortOptions}
            handleselectedOption={(e) => {
              sortHandler(e);
            }}
          />
          <BaseButton
            size="sm"
            title="Create diagram"
            color="primary"
            hasLeftIcon={true}
            icon={buttonIcon()}
            onClickHandler={() => {
              setDiagramModalFlag(true);
            }}
          />
        </div>
      </div>
      {diagramModalFlag && (
        <Modal
          size="lg"
          header="Add New Diagram"
          exitAction={exitModal}
          body={<DiagramCreationForm exitAction={exitModal} />}
        />
      )}
    </div>
  );
};
export default PageHeader;
