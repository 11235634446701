import classes from "./Header.module.scss";
import BaseButton from "../../base/BaseButton";
import CheckIcon from "@atlaskit/icon/glyph/check";
import ShareIcon from "@atlaskit/icon/glyph/share";
import Sql_icon from "../../../assets/image/icon/sql-icon.svg";
import Mongodb from "../../../assets/image/icon/mongodb.svg";
import PostgreSql from "../../../assets/image/icon/postgresql.svg";
import Dotnet from "../../../assets/image/icon/dotnet.svg";
import Pyhthon from "../../../assets/image/icon/Python.svg";
import Nodejs from "../../../assets/image/icon/Nodejs.svg";
import Logo from "../../../assets/image/logo/charmDB.svg";
import ChevronDownIcon from "@atlaskit/icon/glyph/chevron-down";
import CheckCircleOutlineIcon from "@atlaskit/icon/glyph/check-circle-outline";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { reactflowActions } from "../../../store/reactflow-slice";
import { errorActions } from "../../../store/error-slice";
import { loadingActions } from "../../../store/loading-slice";
import { diagramActions } from "../../../modules/Diagram/store/diagram-slice";
import { useDispatch, useSelector } from "react-redux";
import { RepoFactory } from "../../../baseRepository/Factory";
import { useEffect, useState, useRef, useMemo } from "react";
import WarningModal from "../../../core/helpers/WarningModal";
import Modal from "../../base/Modal";
import SharesLink from "../../../modules/Diagram/components/SharesLink";
import AppsMenu from "../dashboard/AppsMenu";
import AppSwitcher from "../../../assets/image/app-switcher.svg";
import VersionHistoryPanel from "../../../modules/Diagram/components/VersionHistoryPanel";
import useDownloadImage from "../../../hooks/use-downloadImage";
import DropDownButton from "../../base/DropDownButton";
import { Colors, IconColor } from "../../../types/SharedTypes";
import JiraFailedBuildStatusIcon from "@atlaskit/icon/glyph/jira/failed-build-status";
import ExportIcon from "@atlaskit/icon/glyph/export";
import ExportForm from "../../../modules/formatter/components/ExportForm";
import ChevronRightIcon from "@atlaskit/icon/glyph/chevron-right";
import ArrowDown from "../../../assets/image/Arrow-down.svg";
import FileOptions from "../../../modules/ReactFlow/components/FileOptions";
import DiagramCreationForm from "../../../modules/Diagram/components/DiagramCreationForm";
import DiagramCopyForm from "../../../modules/Diagram/components/DiagramCopyForm";
import DiagramImportForm from "../../../modules/Diagram/components/DiagramImportForm";
const DiagramRepository = () => RepoFactory.get("diagram");

const Header = (props) => {
  const downloader = useDownloadImage();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const appMenuRef = useRef(null);
  const versionHistoryRef = useRef(null);
  const diagramOptionRef = useRef(null);
  const fileMenuRef = useRef(null);
  const saveChangesFlag = useSelector(
    (state) => state.reactflow.saveChangesFlag
  );
  const params = useParams();
  const { search } = useLocation();
  const searchParams = useMemo(() => new URLSearchParams(search), [search]);
  const database = useSelector((state) => state.reactflow.database);
  const edges = useSelector((state) => state.reactflow.edges);
  const selectedDiagram = useSelector((state) => state.diagram.selectedDiagram);
  const selectedFolder = useSelector((state) => state.folder.selectedFolder);
  const [exitModalFlag, setExitModalFlag] = useState(false);
  const [shareModalFlag, setShareModalFlag] = useState(false);
  const [appMenuFlag, setAppMenuFlag] = useState(false);
  const [diagramEditModalFlag, setDiagramEditModalFlag] = useState(false);
  const [diagramCreateModalFlag, setDiagramCreateModalFlag] = useState(false);
  const [diagramOptionFlag, setDiagramOptionFlag] = useState(false);
  const [versionHistoryFlag, setVersionHistoryFlag] = useState(false);
  const databasetype = useSelector((state) => state.diagram.selectedDatabase);
  const [exportModalFlag, setExportModalFlag] = useState(false);
  const [importModalFlag, setImportModalFlag] = useState(false);
  const [copyModalFlag, setCopyModalFlag] = useState(false);
  const [fileFlag, setFileFlag] = useState(false);
  const diagramImageFlag = useSelector(
    (state) => state.diagram.diagramImageFlag
  );

  const exportIcon = () => {
    return <ExportIcon />;
  };
  const shareIcon = () => {
    return <ShareIcon />;
  };
  const fileIcon = () => {
    return <img alt="" className={classes.fileIcon} src={ArrowDown} />;
  };
  const tableDataFiller = async () => {
    let tableArray = [];
    let positionArray = [];
    let enumArray = [];
    let enumPositionArray = [];
    let edgesArray = [];
    await database.forEach((element) => {
      if (element.data.enum) {
        enumArray.push({
          id: element.id,
          name: element.data.name,
          description: element.data.description ? element.data.description : "",
          schemaColor: element.data.schemaColor ? element.data.schemaColor : "",
          values: element.data.values,
        });
        enumPositionArray.push({
          table: element.id,
          positions: {
            x: element.position.x.toString(),
            y: element.position.y.toString(),
          },
        });
      } else {
        tableArray.push({
          id: element.id,
          name: element.data.name,
          description: element.data.description ? element.data.description : "",
          schemaColor: element.data.schemaColor ? element.data.schemaColor : "",
          columns: element.data.columns,
        });
        positionArray.push({
          table: element.id,
          positions: {
            x: element.position.x.toString(),
            y: element.position.y.toString(),
          },
        });
      }
    });
    await edges.forEach((element) => {
      edgesArray.push({
        id: element.id,
        source: element.source,
        sourceKey: element.sourceHandle,
        target: element.target,
        targetKey: element.targetHandle,
        relation:
          element.handleType === "manyToMany"
            ? 2
            : element.handleType === "oneToMany"
            ? 1
            : 0,
      });
    });
    downloader.download();

    insertTableToDiagram(
      tableArray,
      positionArray,
      enumArray,
      enumPositionArray,
      edgesArray
    );
  };

  const insertTableToDiagram = async (
    tableArray,
    positionArray,
    enumArray,
    enumPositionArray,
    edgesArray
  ) => {
    dispatch(loadingActions.setHasLoading(true));
    dispatch(errorActions.setHasError(false));
    dispatch(errorActions.setError(""));

    let body = {
      diagramId: selectedDiagram.id,
      name: selectedDiagram.name,
      description: selectedDiagram.description,
      dataVariationId: selectedDiagram.dataVariationId,
      diagramVersionId: selectedDiagram.diagramCurrentVersion,
      diagram: {
        name: selectedDiagram.name,
        tables: tableArray,
        enums: enumArray,
        edges: edgesArray,
        schemaColors: {
          default: "",
          public: "",
        },
        tablePositions: positionArray,
        enumPositions: enumPositionArray,
      },
    };

    await DiagramRepository()
      .updateDiagram(body)
      .then((res) => {
        dispatch(reactflowActions.setSaveChangesFlag(false));
        dispatch(diagramActions.setSelectedDiagram(res.data.value.diagram));
        dispatch(diagramActions.updateDiagramsList(res.data.value.diagram));
        if (res.data.value.diagram.tables) {
          dispatch(
            reactflowActions.setDataBase(res.data.value.diagram.models.tables)
          );
        }
        dispatch(
          errorActions.setError({
            message: "Diagram Saved Successfuly",
            statusCode: 200,
          })
        );
        dispatch(reactflowActions.setSaveChangesFlag(false));
      })
      .catch((error) => {
        dispatch(errorActions.setHasError(true));
        dispatch(
          errorActions.setError({
            message: error.response?.data
              ? error.response.data.Reasons[0].Message
              : error.message,
            statusCode: error.response?.status,
          })
        );
        dispatch(loadingActions.setHasLoading(false));
      })
      .finally(() => {});
  };

  useEffect(() => {
    if (diagramImageFlag && exitModalFlag && !saveChangesFlag) {
      flowLeaveHandler();
    }
  }, [diagramImageFlag, exitModalFlag, saveChangesFlag]);

  const flowExitHandler = () => {
    if (!saveChangesFlag) {
      backToDashboard();
    } else {
      setExitModalFlag(true);
    }
  };
  const backToDashboard = () => {
    dispatch(reactflowActions.setSaveChangesFlag(false));
    if (searchParams.get("page") === "fav") {
      navigate("/diagrams/Favorites");
    } else if (searchParams.get("page") === "folder" && selectedFolder?.id) {
      navigate(`/folders/${selectedFolder.id}`);
    } else {
      navigate("/diagrams");
    }
  };
  const modalExitHandler = (state) => {
    setExitModalFlag(state);
  };
  const modalShareHandler = (state) => {
    setShareModalFlag(state);
  };
  const modalVersionHistoryHandler = (state) => {
    setVersionHistoryFlag(state);
  };
  const exportModalExit = (status) => {
    setExportModalFlag(status);
  };
  const importModalExit = (status) => {
    setImportModalFlag(status);
  };
  const exitDiagramEditModal = (status) => {
    setDiagramEditModalFlag(status);
  };
  const exitDiagramCreateModal = (status) => {
    setDiagramCreateModalFlag(status);
  };
  const exitDiagramCopyModal = (status) => {
    setCopyModalFlag(status);
  };
  const flowLeaveHandler = () => {
    dispatch(reactflowActions.setSaveChangesFlag(false));

    backToDashboard();
    dispatch(diagramActions.setDiagramImageFlag(false));

    setExitModalFlag(false);
  };

  useEffect(() => {
    dispatch(reactflowActions.setSaveChangesFlag(false));
  }, []);
  const handleClickOutside = (event) => {
    const { target } = event;
    if (appMenuRef.current && !appMenuRef.current.contains(target)) {
      setAppMenuFlag(false);
    }
    if (
      versionHistoryRef.current &&
      !versionHistoryRef.current.contains(target)
    ) {
      setVersionHistoryFlag(false);
    }
    if (
      diagramOptionRef.current &&
      !diagramOptionRef.current.contains(target)
    ) {
      setDiagramOptionFlag(false);
    }
    if (fileMenuRef.current && !fileMenuRef.current.contains(target)) {
      setFileFlag(false);
    }
  };
  useEffect(() => {
    document.addEventListener("click", handleClickOutside, true);
    return () => {
      document.removeEventListener("click", handleClickOutside, true);
    };
  }, [appMenuRef, versionHistoryRef, diagramOptionRef, fileMenuRef]);
  const appMenuModalHandler = () => {
    if (appMenuFlag === false) {
      setAppMenuFlag(true);
    } else {
      setAppMenuFlag(false);
    }
  };
  const diagramIconMap = () => {
    switch (selectedDiagram?.dataVariationId) {
      case "16022509476708352":
        return (
          <img
            alt="icon"
            src={Sql_icon}
            className={classes.diagramDatabaseIcon}
          />
        );
      case "16022509476708353":
        return (
          <img
            alt="icon"
            src={Dotnet}
            className={classes.diagramDatabaseIcon}
          />
        );
      case "16022509476708354":
        return (
          <img
            alt="icon"
            src={PostgreSql}
            className={classes.diagramDatabaseIcon}
          />
        );
      case "16022509476708355":
        return (
          <img
            alt="icon"
            src={Mongodb}
            className={classes.diagramDatabaseIcon}
          />
        );
      default:
        return "";
    }
  };
  return (
    <div className={classes.header}>
      <div className={classes.headerRow}>
        <div className={classes.AppSection}>
          <img
            onClick={(e) => {
              appMenuModalHandler();
            }}
            className={classes.AppIcon}
            alt="app-switcher"
            src={AppSwitcher}
          />
          <div className={classes.logoBox}>
            <img className={classes.logo} src={Logo} alt="logo" />
          </div>
          {props.status !== "embed" && (
            <div style={{ position: "relative" }}>
              <BaseButton
                size="sm"
                hasBlock={true}
                color="secondary"
                title="File"
                hasRightIcon={true}
                icon={fileIcon()}
                onClickHandler={() => {
                  setFileFlag(!fileFlag);
                }}
              />
              {fileFlag && (
                <div className={classes.fileMenu} ref={fileMenuRef}>
                  <FileOptions
                    presentation={() => {
                      setFileFlag(false);
                      dispatch(reactflowActions.setPresentFlag(true));
                    }}
                    properties={() => {
                      setDiagramEditModalFlag(true);
                      setFileFlag(false);
                    }}
                    versionHistory={() => {
                      setVersionHistoryFlag(true);
                      setDiagramOptionFlag(false);
                      setFileFlag(false);
                    }}
                    import={() => {
                      setImportModalFlag(true);
                      setFileFlag(false);
                    }}
                    export={() => {
                      setExportModalFlag(true);
                      setFileFlag(false);
                    }}
                    copyDiagram={() => {
                      setCopyModalFlag(true);
                      setFileFlag(false);
                    }}
                    newDiagram={() => {
                      if (saveChangesFlag) {
                        dispatch(errorActions.setHasError(true));
                        dispatch(
                          errorActions.setError({
                            message:
                              "Please save your diagram before creating new diagram",
                            statusCode: 500,
                          })
                        );
                      } else {
                        setDiagramCreateModalFlag(true);
                        setFileFlag(false);
                      }
                    }}
                  />
                </div>
              )}
            </div>
          )}
        </div>
        <div className={classes.diagramMenu}>
          <div className={classes.diagramDropDown}>
            {props.status !== "embed" && (
              <div className={classes.dashboardBox}>
                <span
                  className={classes.backLink}
                  onClick={(e) => {
                    flowExitHandler();
                  }}
                >
                  Dashboard
                </span>

                <ChevronRightIcon label="" />
              </div>
            )}
            {diagramIconMap()}
            <div
              className={classes.diagramDropDown}
              onClick={() => setDiagramOptionFlag(!diagramOptionFlag)}
            >
              <span className={classes.dbName}>{selectedDiagram?.name}</span>
              <div className={classes.diagramMenuIcon}>
                <ChevronDownIcon alt="" />
              </div>
            </div>
            {diagramOptionFlag && (
              <div ref={diagramOptionRef} className={classes.diagramOption}>
                <div className={classes.optionBox}>
                  <div
                    className={classes.optionItem}
                    onClick={() => {
                      setVersionHistoryFlag(true);
                      setDiagramOptionFlag(false);
                    }}
                  >
                    <div className={classes.itemBox}>
                      <span className={classes.itemTitle}>Version history</span>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
          <span className={classes.versionTitle}>
            V {params.currentVersion}
          </span>
        </div>
        <div className={classes.headerItem}>
          {!saveChangesFlag && props.status !== "embed" && (
            <span className={classes.saveMessage}>
              <CheckCircleOutlineIcon />
            </span>
          )}
          {saveChangesFlag && props.status !== "embed" && (
            <JiraFailedBuildStatusIcon />
          )}

          {props.status !== "embed" && (
            <BaseButton
              size="sm"
              title="Save changes"
              color="primary"
              onClickHandler={(e) => tableDataFiller()}
            />
          )}

          <BaseButton
            title="Share"
            size="sm"
            color="secondary"
            hasLeftIcon={true}
            icon={shareIcon()}
            onClickHandler={(e) => setShareModalFlag(true)}
          />
        </div>
      </div>
      {exitModalFlag && (
        <WarningModal
          type="exit"
          header="Unsaved Changes"
          alert="You have made changes to this file. Are you sure you want to ignore them?"
          onClose={modalExitHandler}
          onLeave={(e) => flowLeaveHandler()}
        />
      )}
      {shareModalFlag && (
        <Modal
          size="lg"
          body={
            <SharesLink
              diagram={selectedDiagram}
              exitAction={modalShareHandler}
            />
          }
        />
      )}
      {appMenuFlag && (
        <div className={classes.menuDropDown} ref={appMenuRef}>
          <AppsMenu />
        </div>
      )}
      {versionHistoryFlag && (
        <div ref={versionHistoryRef} className={classes.versionLayout}>
          <VersionHistoryPanel onClose={modalVersionHistoryHandler} />
        </div>
      )}
      {exportModalFlag && (
        <Modal body={<ExportForm exitAction={exportModalExit} />} />
      )}
      {importModalFlag && (
        <Modal body={<DiagramImportForm exitAction={importModalExit} />} />
      )}
      {diagramEditModalFlag && (
        <Modal
          size="lg"
          header="Properties"
          body={
            <DiagramCreationForm
              diagram={selectedDiagram}
              exitAction={exitDiagramEditModal}
              type="properties"
            />
          }
        />
      )}
      {diagramCreateModalFlag && (
        <Modal
          size="lg"
          header="Create new diagram"
          body={
            <DiagramCreationForm
              exitAction={exitDiagramCreateModal}
              type="create"
            />
          }
        />
      )}
      {copyModalFlag && (
        <Modal
          size="lg"
          header="Make a copy"
          body={
            <DiagramCopyForm
              diagram={selectedDiagram}
              exitAction={exitDiagramCopyModal}
            />
          }
        />
      )}
    </div>
  );
};

export default Header;
