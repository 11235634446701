import classes from "./style/DiagramOptions.module.scss";

const DiagramOptions = (props) => {
  return (
    <div className={classes.optionBox}>
      <div className={classes.optionItems}>
        <div className={classes.item} onClick={props.onEdit}>
          <span className={classes.itemTitle}>Edit</span>
        </div>
        <div className={classes.item} onClick={props.onClone}>
          <span className={classes.itemTitle}>Duplicate</span>
        </div>
        {/* <div className={classes.item}>
          <span className={classes.itemTitle}>Version history</span>
        </div> */}
        <div className={classes.item} onClick={props.onMove}>
          <span className={classes.itemTitle}>Move to folder</span>
        </div>
        <div className={classes.item} onClick={props.onShareLink}>
          <span className={classes.itemTitle}>Share link </span>
        </div>
        <div className={classes.item} onClick={props.onEmbedLink}>
          <span className={classes.itemTitle}>Embed link</span>
        </div>
        <div className={classes.item} onClick={props.onDelete}>
          <span className={classes.deleteTitle}>Delete</span>
        </div>
      </div>
    </div>
  );
};
export default DiagramOptions;
