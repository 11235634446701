import classes from "./style/FolderCreationForm.module.scss";
import Input from "../../../components/base/Input";
import BaseButton from "../../../components/base/BaseButton";
import { useState } from "react";
import { folderActions } from "../store/folder-slice";
import { errorActions } from "../../../store/error-slice";
import { loadingActions } from "../../../store/loading-slice";
import { SortFolders } from "../../../types/SharedTypes";
import { useDispatch, useSelector } from "react-redux";
import { RepoFactory } from "../../../baseRepository/Factory";
const FolderRepository = () => RepoFactory.get("folder");
const FolderCreationForm = (props) => {
  const dispatch = useDispatch();
  const selectedWorkSpaceId = useSelector(
    (state) => state.auth.selectedWorkSpaceId
  );
  const sortType = useSelector((state) => state.folder.sortType);

  const [folderName, setFolderName] = useState(
    props.folder?.name ? props.folder.name : ""
  );

  const folderNameHandler = (e) => {
    setFolderName(e.target.value);
  };
  const cancelHandler = () => {
    if (props.exitAction) return props.exitAction(false);
  };
  const exitHandler = () => {
    return props.exitAction(false);
  };
  const createFolder = async () => {
    if (folderName !== "") {
      dispatch(loadingActions.setHasLoading(true));
      dispatch(errorActions.setHasError(false));
      dispatch(errorActions.setError(""));
      let body = {
        tenantId: selectedWorkSpaceId,
        name: folderName,
      };
      await FolderRepository()
        .createFolder(body)
        .then((res) => {
          dispatch(errorActions.setHasError(true));
          dispatch(
            errorActions.setError({
              message: "Folder Created Successfuly",
              statusCode: 200,
            })
          );
          getAllFolders();
        })
        .catch((error) => {
          dispatch(errorActions.setHasError(true));
          dispatch(
            errorActions.setError({
              message: error.response?.data
                ? error.response.data.Reasons[0].Message
                : error.message,
              statusCode: error.response?.status,
            })
          );
        })
        .finally(() => {
          dispatch(loadingActions.setHasLoading(false));
          exitHandler();
        });
    } else {
      dispatch(errorActions.setHasError(true));
      dispatch(
        errorActions.setError({
          message: "Please enter a name for Folder !",
          statusCode: 50,
        })
      );
    }
  };
  const updateFolder = async () => {
    if (folderName !== "") {
      dispatch(loadingActions.setHasLoading(true));
      dispatch(errorActions.setHasError(false));
      dispatch(errorActions.setError(""));
      let body = {
        tenantId: selectedWorkSpaceId,
        name: folderName,
        folderId: props.folder.id,
        oldName: props.folder.name,
        newName: folderName,
      };
      await FolderRepository()
        .updateFolder(body)
        .then((res) => {
          dispatch(folderActions.updateFolderList(res.data.value.folder));
          dispatch(errorActions.setHasError(true));
          dispatch(
            errorActions.setError({
              message: "Folder Updated Successfuly",
              statusCode: 200,
            })
          );
          getAllFolders();
        })
        .catch((error) => {
          dispatch(errorActions.setHasError(true));
          dispatch(
            errorActions.setError({
              message: error.response?.data
                ? error.response.data.Reasons[0].Message
                : error.message,
              statusCode: error.response?.status,
            })
          );
        })
        .finally(() => {
          dispatch(loadingActions.setHasLoading(false));
          exitHandler();
        });
    } else {
      dispatch(errorActions.setHasError(true));
      dispatch(
        errorActions.setError({
          message: "Please enter a name for Folder !",
          statusCode: 50,
        })
      );
    }
  };
  const getAllFolders = async () => {
    dispatch(loadingActions.setHasLoading(true));
    dispatch(errorActions.setHasError(false));
    dispatch(errorActions.setError(""));
    let array = [];
    await FolderRepository()
      .getAllFolders(selectedWorkSpaceId)
      .then((res) => {
        array = res.data.value.folders;
      })
      .catch((error) => {
        dispatch(errorActions.setHasError(true));
        dispatch(
          errorActions.setError({
            message: error.response?.data
              ? error.response.data.Reasons[0].Message
              : error.message,
            statusCode: error.response?.status,
          })
        );
      })
      .finally(() => {
        dispatch(loadingActions.setHasLoading(false));
        dispatch(folderActions.setFolderList(SortFolders(sortType, array)));
      });
  };
  return (
    <div className={classes.formContainer}>
      <div className={classes.headerBox}>
        <span className={classes.headerTitle}>Add Folder</span>
      </div>
      <div className={classes.formBody}>
        <Input
          name="folderName"
          size="lg"
          value={folderName}
          placeholder="Folder name"
          handleChange={(e) => folderNameHandler(e)}
        />
      </div>
      <div className={classes.formAction}>
        <BaseButton
          size="sm"
          color="outline"
          title="Cancel"
          hasRightIcon={false}
          hasLeftIcon={false}
          onClickHandler={(e) => cancelHandler()}
        />
        <BaseButton
          size="sm"
          title={props.folder ? "Update" : "Add"}
          color="primary"
          hasRightIcon={false}
          hasLeftIcon={false}
          onClickHandler={(e) => {
            props.folder ? updateFolder() : createFolder();
          }}
        />
      </div>
    </div>
  );
};
export default FolderCreationForm;
