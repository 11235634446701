import classes from "./style/FolderList.module.scss";
import FolderIcon from "@atlaskit/icon/glyph/folder";
import EditorAddIcon from "@atlaskit/icon/glyph/editor/add";
import { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import Modal from "../../../components/base/Modal";
import FolderCreationForm from "./FolderCreationFrom";
import { folderActions } from "../store/folder-slice";
import { errorActions } from "../../../store/error-slice";
import { loadingActions } from "../../../store/loading-slice";
import { menuAction } from "../../../store/menu-slice";
import { useDispatch, useSelector } from "react-redux";
import { RepoFactory } from "../../../baseRepository/Factory";
import { Colors } from "../../../types/SharedTypes";
const FolderRepository = () => RepoFactory.get("folder");

const FolderList = () => {
  const navigate = useNavigate();
  const folderOption = useRef();
  const dispatch = useDispatch();
  const [expand, setExpand] = useState(true);
  const [modalFlag, setModalFlag] = useState(false);

  const selectedFolderMenu = useSelector(
    (state) => state.menu.selectedFolderMenu
  );
  const selectedWorkSpaceId = useSelector(
    (state) => state.auth.selectedWorkSpaceId
  );

  const folderList = useSelector((state) => state.folder.folderList);

  const createButtonHandler = () => {
    dispatch(folderActions.setSelectedFolder(null));
    setModalFlag(true);
  };
  const folderSelectionHandler = (folder) => {
    navigate(`/folders/${folder.id}`);
  };
  const modalExitHandler = (status) => {
    setModalFlag(status);
  };

  const getAllFolders = async () => {
    dispatch(loadingActions.setHasLoading(true));
    dispatch(errorActions.setHasError(false));
    dispatch(errorActions.setError(""));
    let array = [];
    await FolderRepository()
      .getAllFolders(selectedWorkSpaceId)
      .then((res) => {
        array = res.data.value.folders;
      })
      .catch((error) => {
        dispatch(errorActions.setHasError(true));
        dispatch(
          errorActions.setError({
            message: error.response?.data
              ? error.response.data.Reasons[0].Message
              : error.message,
            statusCode: error.response?.status,
          })
        );
      })
      .finally(() => {
        dispatch(loadingActions.setHasLoading(false));
        dispatch(folderActions.setFolderList(array));
      });
  };
  useEffect(() => {
    if (selectedWorkSpaceId) {
      getAllFolders();
    }
  }, [selectedWorkSpaceId]);

  const fetchSelectedFolder = async (folder) => {
    dispatch(loadingActions.setHasLoading(true));
    dispatch(errorActions.setHasError(false));
    dispatch(errorActions.setError(""));
    await FolderRepository()
      .getDiagramByFolderId(folder.id)
      .then((res) => {
        dispatch(folderActions.setSelectedFolder(res.data.value.folder));
        setModalFlag(true);
      })
      .catch((error) => {
        dispatch(errorActions.setHasError(true));
        dispatch(
          errorActions.setError({
            message: error.response?.data
              ? error.response.data.Reasons[0].Message
              : error.message,
            statusCode: error.response?.status,
          })
        );
      })
      .finally(() => {
        dispatch(loadingActions.setHasLoading(false));
      });
  };

  return (
    <div className={classes.folderListContainer}>
      <div className={classes.listHeader}>
        <span className={classes.headerTitle}>folders</span>
      </div>
      {folderList.map((folder, index) => {
        if (index < 3) {
          return (
            <div
              className={
                folder.name === selectedFolderMenu
                  ? classes.selectedFolder
                  : classes.listItem
              }
              onClick={() => {
                folderSelectionHandler(folder);
              }}
              key={index}
            >
              {folder.name === selectedFolderMenu && (
                <div className={classes.indicator}></div>
              )}
              <FolderIcon label="" size="small" />
              <span className={classes.itemTitle}>{folder.name}</span>
            </div>
          );
        } else return null;
      })}

      <div
        className={classes.listItem}
        onClick={(e) => {
          dispatch(menuAction.setSelectedFolderMenu(""));
          navigate("/folders");
        }}
      >
        <span className={classes.buttonTitle}>All Folders</span>
      </div>
      <div className={classes.listItem} onClick={() => setModalFlag(true)}>
        <EditorAddIcon
          label=""
          size="small"
          primaryColor={Colors.Natural_700}
        />
        <span className={classes.buttonTitle}>Add folder</span>
      </div>
      {modalFlag && (
        <Modal
          body={<FolderCreationForm exitAction={modalExitHandler} />}
          exitAction={modalExitHandler}
        />
      )}
    </div>
  );
};
export default FolderList;
