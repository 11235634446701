import { createSlice } from "@reduxjs/toolkit";
const menuSlice = createSlice({
  name: "menu",
  initialState: {
    selectedMenu: "My Applications",
    selectedFolderMenu: "",
  },
  reducers: {
    setSelectedMenu(state, action) {
      state.selectedFolderMenu = "";
      state.selectedMenu = action.payload;
    },
    setSelectedFolderMenu(state, action) {
      state.selectedMenu = "";
      state.selectedFolderMenu = action.payload;
    },
  },
});
export const menuAction = menuSlice.actions;
export default menuSlice.reducer;
