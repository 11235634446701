import BaseRepository from "../../../baseRepository/BaseRepository";
const resource = "/api/v1/charmGraphService/Templates";
const config = {
  headers: { Authorization: `Bearer ${getCookie("access_token")}` },
};
function getCookie(name) {
  const value = `; ${document.cookie}`;
  const parts = value.split(`; ${name}=`);
  if (parts.length === 2) return parts.pop().split(";").shift();
}
const TemplateRepository = {
  getAllTemplates() {
    return BaseRepository.get(`${resource}/GetAll`, config);
  },

  cloneTemplate(body) {
    return BaseRepository.post(`${resource}/Clone`, body, config);
  },
};
export default TemplateRepository;
