import classes from "./style/VersionOptions.module.scss";
import { RepoFactory } from "../../../baseRepository/Factory";
import { useDispatch, useSelector } from "react-redux";
import { errorActions } from "../../../store/error-slice";
import { loadingActions } from "../../../store/loading-slice";
import { diagramActions } from "../store/diagram-slice";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useMemo } from "react";
const DiagramRepository = RepoFactory.get("diagram");

const VersionOptions = (props) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const params = useParams();
  const { search } = useLocation();
  const searchParams = useMemo(() => new URLSearchParams(search), [search]);
  const selectedFolder = useSelector((state) => state.folder.selectedFolder);

  const deleteHandler = () => {
    if (props.onDelete) return props.onDelete(true);
  };
  const getPageUrl = () => {
    if (searchParams.get("page") === "fav") {
      return "fav";
    } else if (searchParams.get("page") === "folder") {
      return `folder`;
    } else {
      return "diagrams";
    }
  };
  const setCurrentVersion = async () => {
    dispatch(loadingActions.setHasLoading(true));
    dispatch(errorActions.setHasError(false));
    dispatch(errorActions.setError(""));
    let body = {
      version: props.version,
      diagramId: props.diagramId,
    };
    await DiagramRepository.setCurrentVersion(body)
      .then((res) => {
        dispatch(errorActions.setHasError(true));
        dispatch(
          errorActions.setError({
            message: "Set Current version Successfuly",
            statusCode: 200,
          })
        );
        getDigramsVersionHistory();
        navigate(
          `/diagrams/${props.diagramId}/${props.version}?page=${getPageUrl()}`
        );
      })
      .catch((error) => {
        dispatch(errorActions.setHasError(true));
        dispatch(
          errorActions.setError({
            message: error.response?.data
              ? error.response.data.Reasons[0].Message
              : error.message,
            statusCode: error.response?.status,
          })
        );
      })
      .finally(() => {
        dispatch(loadingActions.setHasLoading(false));
      });
  };
  const getDigramsVersionHistory = async () => {
    dispatch(loadingActions.setHasLoading(true));
    dispatch(errorActions.setHasError(false));
    dispatch(errorActions.setError(""));
    await DiagramRepository.getDigramsVersionHistory(props.diagramId)
      .then((res) => {
        dispatch(diagramActions.setSelectedDiagram(res.data.value.diagram));
      })
      .catch((error) => {
        dispatch(errorActions.setHasError(true));
        dispatch(
          errorActions.setError({
            message: error.response?.data
              ? error.response.data.Reasons[0].Message
              : error.message,
            statusCode: error.response?.status,
          })
        );
      })
      .finally(() => {
        dispatch(loadingActions.setHasLoading(false));
      });
  };

  return (
    <div className={classes.optionBox}>
      <div className={classes.optionItems}>
        {!props.current && (
          <div className={classes.item} onClick={() => setCurrentVersion()}>
            <span className={classes.itemTitle}>Switch this version</span>
          </div>
        )}
        <div className={classes.item} onClick={() => deleteHandler()}>
          <span className={classes.deleteTitle}>Delete</span>
        </div>
      </div>
    </div>
  );
};
export default VersionOptions;
