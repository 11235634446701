import DBLayout from "../../../components/layouts/DBLayout";
import ReactFlowComponent from "../ReactFlow";
const Dashboard = () => {
  return (
    <DBLayout>
      <ReactFlowComponent />
    </DBLayout>
  );
};
export default Dashboard;
