import { useEffect, useRef, useState } from "react";
import { IconColor } from "../../types/SharedTypes";
import classes from "./style/DropDownButton.module.scss";
import ChevronDownIcon from "@atlaskit/icon/glyph/chevron-down";
const DropDownButton = (props) => {
  const [dropdownFlag, setDropdownFlag] = useState(false);
  const dropDownButtonRef = useRef();
  const handleClickOutside = (event) => {
    const { target } = event;
    if (
      dropDownButtonRef.current &&
      !dropDownButtonRef.current.contains(target)
    ) {
      setDropdownFlag(false);
    }
  };
  useEffect(() => {
    document.addEventListener("click", handleClickOutside, true);
    return () => {
      document.removeEventListener("click", handleClickOutside, true);
    };
  }, [dropDownButtonRef]);
  return (
    <div className={classes.buttonLayout} style={{ background: props.color }}>
      <div className={classes.buttonBox}>
        <div className={classes.firstButton} onClick={props.onButtonClick}>
          <span className={classes.buttonTitle}>{props.title}</span>
        </div>
        <div
          className={classes.dropdownBox}
          onClick={() => setDropdownFlag(!dropdownFlag)}
        >
          <ChevronDownIcon primaryColor={IconColor.white} />
        </div>
      </div>
      {dropdownFlag && (
        <div className={classes.dropdownMenu} ref={dropDownButtonRef}>
          {props?.items.map((item, index) => {
            return (
              <div
                key={index}
                className={classes.dropdownItem}
                onClick={() => setDropdownFlag(false)}
              >
                {item}
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
};
export default DropDownButton;
