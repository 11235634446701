/**
 *  <Textarea
        placeholder="Write Your Message"
        name="message"
        maxLength={1000}
        icon={MessageIcon()}
      />
 */
import classes from "./style/Textarea.module.scss";
const Textarea = (props) => {
  return (
    <div className={classes.textareaContainer}>
      {props.label && (
        <div className={classes.label}>
          <label className={classes.labelText}>{props.label}</label>
        </div>
      )}
      <div className={classes.textarea}>
        {props.icon && <div className={classes.icon}>{props.icon}</div>}
        <textarea
          className={classes.textareaSection}
          placeholder={props.placeholder}
          id={props.id ? props.id : Math.random()}
          name={props.name}
          maxLength={props.maxLength}
          onChange={props.handleChange}
          value={props.value}
          required
        >
          {props.value}
        </textarea>
      </div>
    </div>
  );
};
export default Textarea;
