import React, { useCallback, useEffect, useRef } from "react";
import classes from "./ReactFlow.module.css";
import ReactFlow, {
  MiniMap,
  Controls,
  Background,
  useNodesState,
  useEdgesState,
  addEdge,
  applyNodeChanges,
  updateEdge,
} from "reactflow";
import { useParams } from "react-router-dom";
import "reactflow/dist/style.css";
import { errorActions } from "../../store/error-slice";
import { loadingActions } from "../../store/loading-slice";
import { useSelector, useDispatch } from "react-redux";
import { reactflowActions } from "../../store/reactflow-slice";
import { diagramActions } from "../../modules/Diagram/store/diagram-slice";
import TableNode from "./node/TableNode";
import CustomEdge from "./edge/CustomEdge";
import { RepoFactory } from "../../baseRepository/Factory";
const DiagramRepository = () => RepoFactory.get("diagram");
const DataVariationsRepository = () => RepoFactory.get("dataVariations");
const edgeTypes = {
  custom: CustomEdge,
};
const nodeTypes = {
  table: TableNode,
};
const ReactFlowComponent = (props) => {
  const dispatch = useDispatch();
  const database = useSelector((state) => state.reactflow.database);
  const selectedNode = useSelector((state) => state.reactflow.selectedNode);

  if (!database) {
    database = [];
  }
  const changeColFlag = useSelector((state) => state.reactflow.changeColFlag);
  const getEdges = useSelector((state) => state.reactflow.edges);
  const [nodes, setNodes] = useNodesState(database);
  const [edges, setEdges, onEdgesChange] = useEdgesState(getEdges);
  const [changedColFlag, setChangedColFlag] = useNodesState(-1);
  const params = useParams();
  const getDiagramByVersion = async () => {
    dispatch(loadingActions.setHasLoading(true));
    dispatch(errorActions.setHasError(false));
    dispatch(errorActions.setError(""));
    await DiagramRepository()
      .getDiagramByVersion(params.Id, params.currentVersion)
      .then((res) => {
        dispatch(diagramActions.setSelectedDiagram(res.data.value.diagram));
        diagramDatadispatcher(res.data.value.diagram);
        edgeDataDispatcher(res.data.value.diagram);
        getDiagramVariations(res.data.value.diagram);
      })
      .catch((error) => {
        dispatch(errorActions.setHasError(true));
        dispatch(
          errorActions.setError({
            message: error.response?.data
              ? error.response.data.Reasons[0].Message
              : error.message,
            statusCode: error.response?.status,
          })
        );
      })
      .finally(() => {});
  };
  const getDiagramVariations = async (diagram) => {
    dispatch(errorActions.setHasError(false));
    dispatch(errorActions.setError(""));
    let tempArray = [];

    await DataVariationsRepository()
      .getDatabasPropertyDtos(diagram.dataVariationId)
      .then((res) => {
        res.data.value?.types.forEach((element) => {
          tempArray.push(element);
        });
        let currentModel = [];
        let modelsLenght = diagram?.models.length;
        currentModel = diagram?.models[modelsLenght - 1];
        currentModel?.data?.enums?.forEach((element) => {
          tempArray.push({ propertyType: element.name });
        });
        dispatch(diagramActions.setDiagramVariations(tempArray));
      })
      .catch((error) => {
        dispatch(errorActions.setHasError(true));
        dispatch(
          errorActions.setError({
            message: error.response?.data
              ? error.response.data.Reasons[0].Message
              : error.message,
            statusCode: error.response?.status,
          })
        );
      })
      .finally(() => {
        dispatch(loadingActions.setHasLoading(false));
      });
  };

  const diagramDatadispatcher = async (diagram) => {
    let dataArray = [];
    let currentModel = [];
    let modelsLenght = diagram?.models.length;
    currentModel = diagram?.models[modelsLenght - 1];
    if (diagram?.models)
      await currentModel?.data?.tables?.forEach((element) => {
        let data = {
          id: element.id,
          position: {
            x: parseInt(
              currentModel.data.tablePositions.find(
                (position) =>
                  position.table === element.id ||
                  position.table === element.name
              )?.positions.x
            ),
            y: parseInt(
              currentModel.data.tablePositions.find(
                (position) =>
                  position.table === element.id ||
                  position.table === element.name
              )?.positions.y
            ),
          },
          type: "table",
          descriptionFlag: false,
          data: {
            enum: false,
            schemaColor: element.schemaColor,
            name: element.name,
            description: element.description,
            columns: element.columns,
          },
        };
        dataArray.push(data);
      });
    currentModel?.data?.enums.forEach((element) => {
      let data = {
        id: element.id,
        position: {
          x: parseInt(
            currentModel.data.enumPositions.find(
              (position) => position.table === element.id
            )?.positions.x
          ),
          y: parseInt(
            currentModel.data.enumPositions.find(
              (position) => position.table === element.id
            )?.positions.y
          ),
        },
        type: "table",
        descriptionFlag: false,
        data: {
          enum: true,
          schemaColor: element.schemaColor,
          name: element.name,
          description: element.description,
          values: element.values,
        },
      };
      dataArray.push(data);
    });
    dispatch(reactflowActions.changeDataBase(dataArray));
  };
  const edgeDataDispatcher = async (diagram) => {
    let edgeArray = [];
    let currentModel = [];
    let modelsLenght = diagram?.models.length;
    currentModel = diagram?.models[modelsLenght - 1];

    await currentModel?.data?.edges?.forEach((element) => {
      let edge = {
        data: {
          label:
            element.relation === 2
              ? "N:N"
              : element.relation === 1
              ? "1:N"
              : "1:1",
          startLabel: element.relation === 2,
          endLabel: (element.relation === 1) | (element.relation === 2),
        },
        handleType:
          element.relation === 2
            ? "manyToMany"
            : element.relation === 1
            ? "oneToMany"
            : "oneToOne",
        id: element.id,
        label:
          element.relation === 2
            ? "N:N"
            : element.relation === 1
            ? "1:N"
            : "1:1",
        source: element.source,
        sourceHandle: element.sourceKey,
        target: element.target,
        targetHandle: element.targetKey,

        type: "custom",
      };
      edgeArray.push(edge);
    });
    setEdges(edgeArray);
  };

  const edgeUpdateSuccessful = useRef(true);
  useEffect(() => {
    dispatch(reactflowActions.changeDataBase([]));
    if (params.Id || params.currentVersion) {
      getDiagramByVersion();
    }
  }, [params.Id, params.currentVersion]);
  useEffect(() => {
    if (database) {
      if (database.length !== nodes.length) {
        setNodes(database);
      }
      if (changedColFlag !== changeColFlag) {
        setChangedColFlag(changeColFlag);
        setNodes(database);
        setEdges(getEdges);
      }
    }
  }, [nodes, [...database], changeColFlag, edges]);
  const onConnect = useCallback(
    (params) => {
      let setTypeToParam = {
        ...params,
        type: "custom",
        data: {
          label: "",
        },
      };
      setEdges((eds) => addEdge(setTypeToParam, eds));
    },
    [setEdges]
  );
  const onEdgeUpdate = useCallback((oldEdge, newConnection) => {
    setEdges((els) => updateEdge(oldEdge, newConnection, els));
  }, []);
  useEffect(() => {
    if (edges.length !== getEdges.length && changedColFlag === changeColFlag) {
      dispatch(reactflowActions.setEdges(edges));
    }
  }, [[edges]]);
  const onNodesChange = useCallback(
    (changes) => {
      setNodes((ns) => applyNodeChanges(changes, ns));
      dispatch(reactflowActions.changeDataBase(nodes));
    },
    [nodes]
  );
  const onEdgeUpdateStart = useCallback(() => {
    edgeUpdateSuccessful.current = false;
  }, []);

  const onEdgeUpdateEnd = useCallback((_, edge) => {
    if (!edgeUpdateSuccessful.current) {
      setEdges((eds) => eds.filter((e) => e.id !== edge.id));
    }
    edgeUpdateSuccessful.current = true;
  }, []);
  return (
    <div className={classes.reactflow}>
      {props.status !== "share" && (
        <ReactFlow
          nodes={nodes}
          edges={edges}
          onNodesChange={onNodesChange}
          onEdgesChange={onEdgesChange}
          onConnect={onConnect}
          nodeTypes={nodeTypes}
          onEdgeUpdate={onEdgeUpdate}
          onEdgeUpdateStart={onEdgeUpdateStart}
          onEdgeUpdateEnd={onEdgeUpdateEnd}
          onNodeDrag={() => dispatch(reactflowActions.setSaveChangesFlag(true))}
          edgeTypes={edgeTypes}
          fitView
          fitViewOptions={{ maxZoom: 1 }}
          attributionPosition="top-right"
        >
          <Controls />
          <MiniMap />
          <Background variant="dots" gap={12} size={1} />
        </ReactFlow>
      )}
      {props.status === "share" && (
        <ReactFlow
          nodes={nodes}
          edges={edges}
          nodeTypes={nodeTypes}
          edgeTypes={edgeTypes}
          attributionPosition="top-right"
          fitView
          multiSelectionKeyCode="Shift"
          edgesUpdatable={false}
          edgesFocusable={false}
          nodesDraggable={false}
          nodesConnectable={false}
          nodesFocusable={false}
          elementsSelectable={false}
        >
          <Controls />
          <MiniMap />
          <Background variant="dots" gap={12} size={1} />
        </ReactFlow>
      )}
    </div>
  );
};

export default ReactFlowComponent;
