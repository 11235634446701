import { createSlice } from "@reduxjs/toolkit";
const reactflowSlice = createSlice({
  name: "reactflow",
  initialState: {
    database: [],
    edges: [],
    selectedNode: null,
    changeColFlag: -1,
    saveChangesFlag: false,
    editFlag: "",
    presentFlag: false,
  },
  reducers: {
    setSaveChangesFlag(state, action) {
      state.saveChangesFlag = action.payload;
    },
    setSelectedNode(state, action) {
      // state.saveChangesFlag = true;
      state.selectedNode = action.payload;
    },
    setDataBase(state, action) {
      // state.saveChangesFlag = true;
      state.database = [...state.database, action.payload];
    },
    setEdges(state, action) {
      // state.saveChangesFlag = true;
      state.edges = action.payload;
      state.changeColFlag = Math.random();
    },
    setEditFlag(state, action) {
      state.editFlag = action.payload;
    },
    updateEdges(state, action) {
      // state.saveChangesFlag = true;
      let parsEdges = JSON.parse(JSON.stringify(state.edges));
      let edgesTemp = [];
      parsEdges.map((edge) => {
        if (edge.id === action.payload.id) {
          edgesTemp.push({
            ...edge,
            data: {
              label: action.payload.type.label,
              startLabel: action.payload.type.type === "manyToMany",
              endLabel: action.payload.type.type !== "oneToOne",
            },
            handleType: action.payload.type.type,
            label: action.payload.type.label,
            type: "custom",
          });
        } else {
          edgesTemp.push(edge);
        }
      });
      state.edges = edgesTemp;
      state.changeColFlag = Math.random();
    },
    deleteEdge(state, action) {
      // state.saveChangesFlag = true;
      let parsEdges = JSON.parse(JSON.stringify(state.edges));
      let sourceFilterEdges = parsEdges.filter((edge) => {
        if (edge.sourceHandle !== action.payload) {
          return edge;
        }
      });
      let targetFilterEdges = sourceFilterEdges.filter((edge) => {
        if (edge.targetHandle !== action.payload) {
          return edge;
        }
      });

      state.edges = targetFilterEdges;
      state.changeColFlag = Math.random();
    },
    deleteEdgeById(state, action) {
      // state.saveChangesFlag = true;
      let parsEdges = JSON.parse(JSON.stringify(state.edges));
      let filterEdges = parsEdges.filter((edge) => {
        if (edge.id !== action.payload) {
          return edge;
        }
      });

      state.edges = filterEdges;
      state.changeColFlag = Math.random();
    },
    changeDataBase(state, action) {
      state.database = action.payload;
    },
    updateNode(state, action) {
      // state.saveChangesFlag = true;
      let parsDatabases = JSON.parse(JSON.stringify(state.database));
      let temp = [];
      parsDatabases.forEach((item) => {
        if (item.id === action.payload?.id) {
          temp.push(action.payload);
        } else {
          temp.push(item);
        }
      });
      state.database = temp;
      state.changeColFlag = Math.random();
    },
    deleteNode(state, action) {
      // state.saveChangesFlag = true;
      let parsDatabases = JSON.parse(JSON.stringify(state.database));
      let temp = parsDatabases.filter((item) => {
        if (item.id !== action.payload.id) {
          return item;
        }
      });
      state.database = temp;
      reactflowSlice.caseReducers.deleteEdge(state, action);
    },
    updateColumnsProperty(state, action) {
      // state.saveChangesFlag = true;
      let tempList = [];
      state.database.forEach((table) => {
        if (!table.data.enum) {
          table.data.columns.forEach((col) => {
            if (col.type === action.payload.oldName) {
              col.type = action.payload.newName;
            }
          });
        }
        tempList.push(table);
      });
      state.database = tempList;
    },
    deleteColsByDataVariationRemove(state, action) {
      // state.saveChangesFlag = true;
      let tempList = [];
      let parsDatabases = JSON.parse(JSON.stringify(state.database));
      parsDatabases.forEach((table) => {
        if (!table.data.enum) {
          table.data.columns = table.data.columns.filter((col) => {
            if (col.type !== action.payload) {
              return col;
            }
          });
        }
        tempList.push(table);
      });
      state.database = tempList;
    },
    setPresentFlag(state, action) {
      state.presentFlag = action.payload;
    },
  },
});
export const reactflowActions = reactflowSlice.actions;
export default reactflowSlice.reducer;
