import Router from "./router/index.js";
import { useSelector } from "react-redux";
import LoadHandling from "./core/helpers/LoadHandling.js";
import ErrorHandling from "./core/helpers/ErrorHandling.js";
function App() {
  const hasLoading = useSelector((state) => state.loading.hasLoading);
  const hasError = useSelector((state) => state.error.hasError);

  return (
    <>
      {hasError && <ErrorHandling />}
      {hasLoading && <LoadHandling />}
      <Router />
    </>
  );
}

export default App;
