import classes from "./style/Modal.module.scss";

const Modal = (props) => {
  return (
    <div className={classes.backgroundModal}>
      <div className={classes.modalContent}>{props.body}</div>
    </div>
  );
};
export default Modal;
