import BaseButton from "../../../components/base/BaseButton";
import Input from "../../../components/base/Input";
import classes from "./style/VersionCreationForm.module.scss";
import { RepoFactory } from "../../../baseRepository/Factory";
import { loadingActions } from "../../../store/loading-slice";
import { errorActions } from "../../../store/error-slice";
import { useDispatch } from "react-redux";
import { useState } from "react";
const DiagramRepository = () => RepoFactory.get("diagram");

const VersionCreationForm = (props) => {
  const [description, setDescription] = useState("");
  const dispatch = useDispatch();

  const createNewVersionHistory = async () => {
    dispatch(loadingActions.setHasLoading(true));
    dispatch(errorActions.setHasError(false));
    dispatch(errorActions.setError(""));
    let body = {
      fromVersion: props?.versionNumber,
      diagramId: props?.diagramId,
      description: description,
    };
    await DiagramRepository()
      .createNewVersion(body)
      .then((res) => {
        dispatch(errorActions.setHasError(true));
        dispatch(
          errorActions.setError({
            message: "New Version Created",
            statusCode: 200,
          })
        );
        closeHandler("create");
      })
      .catch((error) => {
        dispatch(errorActions.setHasError(true));
        dispatch(
          errorActions.setError({
            message: error.response?.data
              ? error.response.data.Reasons[0].Message
              : error.message,
            statusCode: error.response?.status,
          })
        );
      })
      .finally(() => {
        dispatch(loadingActions.setHasLoading(false));
      });
  };
  const closeHandler = (state) => {
    if (props.onClose) return props.onClose(false, state);
  };
  return (
    <div className={classes.formLayout}>
      <div className={classes.header}>
        <span className={classes.headerTitle}>Create new version</span>
      </div>
      <div className={classes.formBody}>
        <span className={classes.bodyTitle}>
          Please input a new version number to create. (The number should not be
          smaller than the current version)
        </span>
        <Input
          type="text"
          name="version description"
          placeholder="Enter version description"
          handleChange={(e) => setDescription(e.target.value)}
        />
      </div>
      <div className={classes.formFooter}>
        <BaseButton
          size="sm"
          title="Cancel"
          color="secondary"
          onClickHandler={() => closeHandler("cancel")}
        />
        <BaseButton
          size="sm"
          title="Create"
          color="primary"
          onClickHandler={(e) => createNewVersionHistory()}
        />
      </div>
    </div>
  );
};
export default VersionCreationForm;
