/**
 * <ShareLinkButton
        linkText="Https//wingsteck.com/fgvsdcdkmckb"
        buttonText="Copy Link"
      />
 */
import classes from "./style/ShareLinkButton.module.scss";
import BaseButton from "./BaseButton";
import CopyIcon from "@atlaskit/icon/glyph/copy";
import { IconColor } from "../../types/SharedTypes";
const ShareLinkButton = (props) => {
  const copyIcon = () => {
    return <CopyIcon label="" primaryColor={IconColor.light_grey} />;
  };
  const copyLinkHandler = () => {
    var copyText = document.getElementById("link");
    navigator.clipboard.writeText(copyText.innerText);
  };
  return (
    <div className={classes.buttonContainer}>
      <div className={classes.linkBox}>
        <span className={classes.linkText} id="link">
          {props.linkText}
        </span>
      </div>

      <BaseButton
        size="sm"
        color="secondary"
        title="Copy"
        hasRightIcon={false}
        hasLeftIcon={true}
        onClickHandler={(e) => copyLinkHandler()}
        icon={copyIcon()}
      />
    </div>
  );
};
export default ShareLinkButton;
