// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../../font/SF-Pro.ttf", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@font-face {
  font-family: "SF-Pro";
  src: url(${___CSS_LOADER_URL_REPLACEMENT_0___}) format("truetype");
}
body {
  margin: 0;
  font-family: "SF-Pro";
}
input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
a {
  text-decoration: none !important;
}

::-webkit-scrollbar {
  width: 10px;
}

::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(200, 200, 200, 1);
  border-radius: 10px;
}

::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background-color: #fff;
  -webkit-box-shadow: inset 0 0 6px rgba(90, 90, 90, 0.5);
}
`, "",{"version":3,"sources":["webpack://./src/assets/style/css/main.css"],"names":[],"mappings":"AAAA;EACE,qBAAqB;EACrB,+DAAoD;AACtD;AACA;EACE,SAAS;EACT,qBAAqB;AACvB;AACA;;EAEE,wBAAwB;EACxB,SAAS;AACX;AACA;EACE,gCAAgC;AAClC;;AAEA;EACE,WAAW;AACb;;AAEA;EACE,wDAAwD;EACxD,mBAAmB;AACrB;;AAEA;EACE,mBAAmB;EACnB,sBAAsB;EACtB,uDAAuD;AACzD","sourcesContent":["@font-face {\n  font-family: \"SF-Pro\";\n  src: url(\"../../font/SF-Pro.ttf\") format(\"truetype\");\n}\nbody {\n  margin: 0;\n  font-family: \"SF-Pro\";\n}\ninput[type=\"number\"]::-webkit-outer-spin-button,\ninput[type=\"number\"]::-webkit-inner-spin-button {\n  -webkit-appearance: none;\n  margin: 0;\n}\na {\n  text-decoration: none !important;\n}\n\n::-webkit-scrollbar {\n  width: 10px;\n}\n\n::-webkit-scrollbar-track {\n  -webkit-box-shadow: inset 0 0 6px rgba(200, 200, 200, 1);\n  border-radius: 10px;\n}\n\n::-webkit-scrollbar-thumb {\n  border-radius: 10px;\n  background-color: #fff;\n  -webkit-box-shadow: inset 0 0 6px rgba(90, 90, 90, 0.5);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
