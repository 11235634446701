import BaseRepository from "../../../baseRepository/BaseRepository";
const resource = "/api/v1/charmGraphService/Diagrams";
const config = {
  headers: { Authorization: `Bearer ${getCookie("access_token")}` },
};
function getCookie(name) {
  const value = `; ${document.cookie}`;
  const parts = value.split(`; ${name}=`);
  if (parts.length === 2) return parts.pop().split(";").shift();
}
const DiagramRepository = {
  getAllDigrams(id) {
    return BaseRepository.get(`${resource}/GetAllDiagrams?tenantId=${id}`);
  },
  getDiagram(id) {
    return BaseRepository.get(`${resource}?id=${id}`);
  },
  createDiagram(body) {
    return BaseRepository.post(`${resource}`, body, config);
  },
  deleteDiagram(id) {
    return BaseRepository.delete(`${resource}?id=${id}`, config);
  },
  updateDiagram(body) {
    return BaseRepository.put(`${resource}`, body, config);
  },
  addToFavorite(body) {
    return BaseRepository.post(`${resource}/SetFavoriteDiagrams`, body, config);
  },
  unFavoriteDiagram(body) {
    return BaseRepository.post(`${resource}/UnFavoriteDiagrams`, body, config);
  },
  getFavoriteDiagrams(id) {
    return BaseRepository.get(
      `${resource}/GetFavoritedDiagramByTenantId?tenantId=${id}`,
      config
    );
  },
  getDigramsVersionHistory(diagramId) {
    return BaseRepository.get(
      `${resource}/GetVersionHistory?diagramId=${diagramId}`
    );
  },
  getDiagramByVersion(diagramId, versionId) {
    return BaseRepository.get(
      `${resource}/GetVersion?diagramId=${diagramId}&versionId=${versionId}`
    );
  },
  setCurrentVersion(body) {
    return BaseRepository.post(`${resource}/SetCurrentVersion`, body, config);
  },
  RemoveVersion(body) {
    return BaseRepository.post(`${resource}/RemoveVersion`, body, config);
  },
  createNewVersion(body) {
    return BaseRepository.post(`${resource}/CreateNewVersion`, body, config);
  },
  createSnapshot(body) {
    return BaseRepository.put(`${resource}/CreateSnapshot`, body, config);
  },
};
export default DiagramRepository;
