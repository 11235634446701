import axios from "axios";

const resource =
  process.env.REACT_APP_API_CHARMFORMATTER_DOMAIN +
  "api/v1/charmFormatterService";
const DiagramRepository = {
  exportDiagram(diagramId, diagramVersion, dataVariationId) {
    switch (dataVariationId) {
      case "16022509476708353":
        return axios.get(
          `${resource}/Export/DotNet?diagramId=${diagramId}&diagramVersion=${diagramVersion}`
        );
      case "16022509476708354":
        return axios.get(
          `${resource}/Export/Postgres?diagramId=${diagramId}&diagramVersion=${diagramVersion}`
        );
      case "16022509476708355":
        return axios.get(
          `${resource}/Export/Mongo?diagramId=${diagramId}&diagramVersion=${diagramVersion}`
        );
      case "16022509476708356":
        return axios.get(
          `${resource}/Export/Oracle?diagramId=${diagramId}&diagramVersion=${diagramVersion}`
        );
      case "16022509476708352":
        return axios.get(
          `${resource}/Export/SQLServer?diagramId=${diagramId}&diagramVersion=${diagramVersion}`
        );
      default:
        return axios.get(
          `${resource}/Export/SQLServer?diagramId=${diagramId}&diagramVersion=${diagramVersion}`
        );
    }
  },
  importDiagram(file) {
    return axios.post(`${resource}/Import/ImportSqlServer`, file, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
  },
};
export default DiagramRepository;
