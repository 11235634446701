import classes from "./style/FolderTile.module.scss";
import FolderOptions from "./FolderOptions";
import FolderCreationForm from "./FolderCreationFrom";
import FolderFilledIcon from "@atlaskit/icon/glyph/folder-filled";
import MoreIcon from "@atlaskit/icon/glyph/more";
import { useState, useEffect, useRef } from "react";
import { folderActions } from "../store/folder-slice";
import { errorActions } from "../../../store/error-slice";
import { loadingActions } from "../../../store/loading-slice";
import { useDispatch, useSelector } from "react-redux";
import Modal from "../../../components/base/Modal";
import WarningModal from "../../../core/helpers/WarningModal";
import { Colors } from "../../../types/SharedTypes";
import { SortFolders } from "../../../types/SharedTypes";
import { useNavigate } from "react-router-dom";
import { RepoFactory } from "../../../baseRepository/Factory";
const FolderRepository = () => RepoFactory.get("folder");

const FolderTile = (props) => {
  const navigate = useNavigate();
  const folderOption = useRef();
  const dispatch = useDispatch();
  const [optionFlag, setOptionFlag] = useState(false);
  const [mouseHoverFlag, setMouseHoverFlag] = useState(false);
  const [optionHoverFlag, setOptionHoverFlag] = useState(false);
  const [folderModalFlag, setFolderModalFlag] = useState(false);
  const [deleteModalFlag, setDeleteModalFlag] = useState(false);
  const [folderName, setFolderName] = useState("");
  const selectedWorkSpaceId = useSelector(
    (state) => state.auth.selectedWorkSpaceId
  );
  const sortType = useSelector((state) => state.folder.sortType);

  const folderList = useSelector((state) => state.folder.folderList);

  const exitModal = (status) => {
    setMouseHoverFlag(false);
    setFolderModalFlag(status);
  };
  const warningModalHandler = (state) => {
    setDeleteModalFlag(state);
  };

  const deleteFolder = async () => {
    if (props.folder.diagrams.length > 0) {
      setDeleteModalFlag(false);
      dispatch(errorActions.setHasError(true));
      dispatch(
        errorActions.setError({
          message: "Selected Folder Contains Diagrams you can't Delete it !",
          statusCode: 0,
        })
      );
      setMouseHoverFlag(false);
      return;
    }
    dispatch(loadingActions.setHasLoading(true));
    dispatch(errorActions.setHasError(false));
    dispatch(errorActions.setError(""));
    await FolderRepository()
      .deleteFolder(props.folder.id)
      .then((res) => {
        const result = folderList.filter(
          (folder) => folder.id !== props.folder.id
        );
        dispatch(folderActions.setFolderList(result));
        dispatch(errorActions.setHasError(true));
        dispatch(
          errorActions.setError({
            message: "Folder Deleted Successfuly",
            statusCode: 200,
          })
        );
        getAllFolders();
      })
      .catch((error) => {
        dispatch(errorActions.setHasError(true));
        dispatch(
          errorActions.setError({
            message: error.response?.data
              ? error.response.data.Reasons[0].Message
              : error.message,
            statusCode: error.response?.status,
          })
        );
      })
      .finally(() => {
        setDeleteModalFlag(false);
        setMouseHoverFlag(false);
        dispatch(loadingActions.setHasLoading(false));
      });
  };
  const getAllFolders = async () => {
    dispatch(loadingActions.setHasLoading(true));
    dispatch(errorActions.setHasError(false));
    dispatch(errorActions.setError(""));
    let array = [];
    await FolderRepository()
      .getAllFolders(selectedWorkSpaceId)
      .then((res) => {
        array = res.data.value.folders;
      })
      .catch((error) => {
        dispatch(errorActions.setHasError(true));
        dispatch(
          errorActions.setError({
            message: error.response?.data
              ? error.response.data.Reasons[0].Message
              : error.message,
            statusCode: error.response?.status,
          })
        );
      })
      .finally(() => {
        dispatch(loadingActions.setHasLoading(false));
        dispatch(folderActions.setFolderList(SortFolders(sortType, array)));
      });
  };

  const handleClickOutside = (event) => {
    if (folderOption.current && !folderOption.current.contains(event.target)) {
      setOptionFlag(false);
      setOptionHoverFlag(false);
    }
  };
  useEffect(() => {
    document.addEventListener("click", handleClickOutside, true);
    return () => {
      document.removeEventListener("click", handleClickOutside, true);
    };
  }, [folderOption]);
  useEffect(() => {
    if (!optionHoverFlag) {
      setOptionHoverFlag(false);
    }
  }, [optionHoverFlag]);
  return (
    <div className={classes.tileLayout} ref={folderOption}>
      <div
        className={classes.tileBody}
        onMouseLeave={() => setMouseHoverFlag(false)}
        onMouseEnter={() => {
          setMouseHoverFlag(true);
        }}
        style={{
          background: mouseHoverFlag ? Colors.Natural_1100 : Colors.Natural_0,
        }}
      >
        {mouseHoverFlag && (
          <div
            className={classes.optionIcon}
            onClick={() => {
              setOptionFlag(!optionFlag);
            }}
          >
            <MoreIcon label="" />
          </div>
        )}
        {optionFlag && (
          <div className={classes.folderOption}>
            <FolderOptions
              onDelete={() => {
                setDeleteModalFlag(true);
                setOptionFlag(false);
              }}
              onEdit={() => {
                setFolderModalFlag(true);
                setOptionFlag(false);
              }}
            />
          </div>
        )}
        <div
          className={classes.folderIcon}
          onClick={() => navigate(`/folders/${props.folder.id}`)}
        >
          <FolderFilledIcon
            label=""
            primaryColor={Colors.FolderIconColor}
            size="large"
          />
        </div>
      </div>
      <div className={classes.tileFooter}>
        <div className={classes.folderName}>
          <span className={classes.nameTitle}>{props.folder.name}</span>
        </div>
      </div>
      {folderModalFlag && (
        <Modal
          body={
            <FolderCreationForm exitAction={exitModal} folder={props.folder} />
          }
          exitAction={exitModal}
        />
      )}
      {deleteModalFlag && (
        <WarningModal
          type="Leave"
          header="Warning"
          alert="Are You Sure Delete This Folder?"
          onClose={warningModalHandler}
          onDelete={() => deleteFolder()}
        />
      )}
    </div>
  );
};
export default FolderTile;
