import classes from "./style/DiagramCopyForm.module.scss";
import Nucleus from "../../../assets/image/Nucleus.png";
import Input from "../../../components/base/Input";
import Textarea from "../../../components/base/Textarea";
import ToggleSwitch from "../../../components/base/ToggleSwitch";
import BaseButton from "../../../components/base/BaseButton";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { loadingActions } from "../../../store/loading-slice";
import { errorActions } from "../../../store/error-slice";
import { RepoFactory } from "../../../baseRepository/Factory";
import InternalLoadHandling from "../../../core/helpers/InternalLoadHandling";
import EditorSuccessIcon from "@atlaskit/icon/glyph/editor/success";
import { IconColor } from "../../../types/SharedTypes";
const TemplateRepository = () => RepoFactory.get("template");

const DiagramCopyForm = (props) => {
  const dispatch = useDispatch();
  const selectedWorkSpaceId = useSelector(
    (state) => state.auth.selectedWorkSpaceId
  );
  const internalHasLoading = useSelector(
    (state) => state.loading.internalHasLoading
  );
  const [name, setName] = useState(
    props.diagram ? "Copy of " + props.diagram.name : ""
  );
  const [description, setDescription] = useState(
    props.diagram ? props.diagram.description : ""
  );
  const [diagramtype, setDiagramType] = useState(
    props.diagram ? props.diagram.isPrivate : false
  );
  const [successfulyFlag, setSuccessfulyFlag] = useState(false);
  const [newDiagram, setNewDiagram] = useState();
  const setNameHandler = (e) => {
    setName(e.target.value);
  };
  const setDescriptionHandler = (e) => {
    setDescription(e.target.value);
  };

  const setDiagramTypeHandler = (selectedType) => {
    setDiagramType(selectedType.target.checked);
  };
  const cancelHandler = () => {
    if (props.exitAction) return props.exitAction(false);
  };

  const diagramClone = async () => {
    if (!name) {
      dispatch(errorActions.setHasError(true));
      dispatch(
        errorActions.setError({
          message: "please enter name",
          statusCode: 500,
        })
      );
      return;
    }
    dispatch(loadingActions.setInternalHasLoading(true));
    dispatch(errorActions.setHasError(false));
    dispatch(errorActions.setError(""));
    let body = {
      templateId: props.diagram.id,
      tenantId: selectedWorkSpaceId,
      newTemplateName: name,
    };
    await TemplateRepository()
      .cloneTemplate(body)
      .then((res) => {
        dispatch(errorActions.setHasError(true));
        dispatch(
          errorActions.setError({
            message: "Diagram Cloned Successfuly",
            statusCode: 200,
          })
        );
        setNewDiagram(res.data.value.tempalte);
        setSuccessfulyFlag(true);
      })
      .catch((error) => {
        dispatch(errorActions.setHasError(true));
        dispatch(
          errorActions.setError({
            message: error.response?.data
              ? error.response.data.Reasons[0].Message
              : error.message,
            statusCode: error.response?.status,
          })
        );
      })
      .finally(() => {
        dispatch(loadingActions.setInternalHasLoading(false));
      });
  };
  return (
    <>
      {!internalHasLoading && !successfulyFlag && (
        <div className={classes.formContainer}>
          <div className={classes.formHeader}>
            <span className={classes.headerTitle}>{"Make a copy"}</span>
          </div>
          <div className={classes.formContent}>
            <div className={classes.contentHeader}>
              <div className={classes.workspaceBox}>
                <img
                  alt="workspace-avatar"
                  src={Nucleus}
                  className={classes.workspaceAvatar}
                />
                <span className={classes.workspaceTitle}>
                  {
                    JSON.parse(
                      localStorage.getItem("selectedLicensedWorkspace")
                    )?.name
                  }
                </span>
              </div>
            </div>
            <Input
              name="diagramName"
              placeholder="Enter diagram name"
              value={name}
              handleChange={(e) => {
                setNameHandler(e);
              }}
            />
            <Textarea
              placeholder="Description"
              name="description"
              value={description}
              maxLength={1000}
              handleChange={(e) => {
                setDescriptionHandler(e);
              }}
            />

            <div className={classes.privateBox}>
              <span>{diagramtype ? "Private" : "Public"}</span>
              <ToggleSwitch
                checked={diagramtype}
                checkAction={(e) => {
                  setDiagramTypeHandler(e);
                }}
                disabled={false}
              />
            </div>
          </div>
          <div className={classes.formAction}>
            <BaseButton
              size="sm"
              color="outline"
              title="Cancel"
              hasRightIcon={false}
              hasLeftIcon={false}
              onClickHandler={(e) => cancelHandler()}
            />
            <BaseButton
              size="sm"
              title={"create"}
              color="primary"
              hasRightIcon={false}
              hasLeftIcon={false}
              onClickHandler={(e) => {
                diagramClone();
              }}
            />
          </div>
        </div>
      )}
      {internalHasLoading && !successfulyFlag && (
        <div className={classes.formContainer}>
          <div className={classes.formHeader}>
            <span className={classes.headerTitle}>{"Make a copy"}</span>
          </div>
          <div className={classes.formContent}>
            <InternalLoadHandling />
          </div>
        </div>
      )}
      {!internalHasLoading && successfulyFlag && (
        <div className={classes.formContainer}>
          <div className={classes.formHeader}>
            <span className={classes.headerTitle}>{"Make a copy"}</span>
          </div>
          <div className={classes.formContent}>
            <div className={classes.successBox}>
              <EditorSuccessIcon size="large" primaryColor={IconColor.green} />
              <span className={classes.successText}>Clone complete!</span>
            </div>
          </div>
          <div className={classes.formAction}>
            <BaseButton
              size="sm"
              color="outline"
              title="Cancel"
              hasRightIcon={false}
              hasLeftIcon={false}
              onClickHandler={(e) => cancelHandler()}
            />
            <a
              href={
                process.env.REACT_APP_BASE_DOMAIN +
                `/diagrams/${newDiagram?.id}/${props?.diagram?.diagramCurrentVersion}?page=diagrams`
              }
              target="_blank"
              rel="noopener noreferrer"
              className={classes.menuItem}
            >
              <BaseButton
                size="sm"
                title="View diagram"
                color="primary"
                hasRightIcon={false}
                hasLeftIcon={false}
                onClickHandler={(e) => {
                  cancelHandler();
                }}
              />
            </a>
          </div>
        </div>
      )}
    </>
  );
};
export default DiagramCopyForm;
