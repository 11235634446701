import axios from "axios";
// export const authHeader = axios.interceptors.request.use((req) => {
//   req.headers.Authorization = getCookie("access_token")
//     ? "Bearer " + getCookie("access_token")
//     : null;
//   return req;
// });
// function getCookie(name) {
//   const value = `; ${document.cookie}`;
//   const parts = value.split(`; ${name}=`);
//   if (parts.length === 2) return parts.pop().split(";").shift();
// }
export const baseURL = process.env.REACT_APP_API_BASE_DOMAIN;
axios.defaults.baseURL = baseURL;
axios.defaults.headers = {
  "Content-Type": "application/json",
  accept: "application/json",
  "X-Requested-With": "XMLHttpRequest",
  "Access-Control-Allow-Origin": "*",
};
// if (authHeader) {
//   axios.defaults.headers.common["Authorization"] = `Bearer ${getCookie(
//     "access_token"
//   )}`;
// }

export default axios;
