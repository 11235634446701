import BaseRepository from "../../../baseRepository/BaseRepository";
const resource = "/api/v1/charmGraphService/folders";
const config = {
  headers: { Authorization: `Bearer ${getCookie("access_token")}` },
};
function getCookie(name) {
  const value = `; ${document.cookie}`;
  const parts = value.split(`; ${name}=`);
  if (parts.length === 2) return parts.pop().split(";").shift();
}
const FolderRepository = {
  getAllFolders(id) {
    return BaseRepository.get(`${resource}/GetByTenantId?id=${id}`, config);
  },
  getFolderById(id) {
    return BaseRepository.get(`${resource}?id=${id}`);
  },
  createFolder(body) {
    return BaseRepository.post(`${resource}/CreateFolder`, body, config);
  },
  deleteFolder(id) {
    return BaseRepository.delete(`${resource}/DeleteFolder?id=${id}`, config);
  },
  updateFolder(body) {
    return BaseRepository.put(`${resource}/RenameFolder`, body, config);
  },
  assignDiagram(body) {
    return BaseRepository.put(`${resource}/AssignDiagramFolder`, body, config);
  },
};
export default FolderRepository;
