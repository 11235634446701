import classes from "./style/ColumnOption.module.scss";
import { Bookmark, Message } from "iconsax-react";
import Input from "../../../components/base/Input";
import Textarea from "../../../components/base/Textarea";
import BaseButton from "../../../components/base/BaseButton";
const ColumnOption = (props) => {
  return (
    <div className={classes.tabcontainer}>
      {props.type !== "enum" && (
        <div className={classes.itemBox}>
          <Input type="text" placeholder="Default Value" />
        </div>
      )}
      <div className={classes.itemBox}>
        <Textarea
          name="description"
          placeholder={
            props.type === "enum"
              ? "Description For This Value"
              : "Description For This Column"
          }
          maxLength={1000}
          handleChange={props.descriptionHandler}
          value={props.description}
        >
          {props.description}
        </Textarea>
      </div>
      {props.type !== "enum" && (
        <div className={classes.itemBox}>
          <div className={classes.checkBox}>
            <input
              className={classes.check}
              type="checkbox"
              id="increment"
              name="increment"
              value="increment"
            />
            <label htmlFor="vehicle1" className={classes.checkBoxTitle}>
              Auto Increment
            </label>
          </div>
        </div>
      )}
      <div className={classes.itemBox}>
        <div className={classes.deleteAction} onClick={props.onDeleteColumn}>
          <span>
            {props.type === "enum" ? "Delete Value" : "Delete Column"}
          </span>
        </div>
      </div>
    </div>
  );
};
export default ColumnOption;
