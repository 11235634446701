import { createSlice } from "@reduxjs/toolkit";
import { FolderSortOptions } from "../../../types/SharedTypes";
const folderSLice = createSlice({
  name: "folder",
  initialState: {
    folderList: [],
    selectedFolder: null,
    sortType: FolderSortOptions[1],
    diagramsListByFolderId: [],
  },
  reducers: {
    setSelectedFolder(state, action) {
      state.selectedFolder = action.payload;
    },
    setFolderList(state, action) {
      state.folderList = action.payload;
    },
    updateFolderList(state, action) {
      let tempList = [];
      state.folderList?.map((item) => {
        if (item.id === action.payload.id) {
          tempList.push(action.payload);
        } else {
          tempList.push(item);
        }
      });
      state.folderList = tempList;
    },
    setSortType(state, action) {
      state.sortType = action.payload;
    },
    setDiagramsListByFolderId(state, action) {
      state.diagramsListByFolderId = action.payload;
    },
  },
});
export const folderActions = folderSLice.actions;
export default folderSLice.reducer;
