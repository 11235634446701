import classes from "./style/FolderDetails.module.scss";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import DiagramTile from "../../Diagram/components/DiagramTile";
import { dashboardAction } from "../../../store/dashboard-slice";
import { useParams } from "react-router-dom";
import { errorActions } from "../../../store/error-slice";
import { loadingActions } from "../../../store/loading-slice";
import { RepoFactory } from "../../../baseRepository/Factory";
import { folderActions } from "../store/folder-slice";
import { diagramActions } from "../../Diagram/store/diagram-slice";
import { menuAction } from "../../../store/menu-slice";
import PageHeader from "../../../components/base/PageHeader";
import { DiagramSortOptions } from "../../../types/SharedTypes";
import { SortDiagrams } from "../../../types/SharedTypes";
const FolderRepository = () => RepoFactory.get("folder");

const FolderDetails = () => {
  const { folderId } = useParams();
  const dispatch = useDispatch();
  const selectedFolder = useSelector((state) => state.folder.selectedFolder);
  const [folderDiagrams, setFolderDiagrams] = useState([]);
  const sortType = useSelector((state) => state.diagram.sortType);
  const sortHandler = (status) => {
    dispatch(diagramActions.setSortType(status));
  };
  const getFolder = async () => {
    dispatch(loadingActions.setHasLoading(true));
    dispatch(errorActions.setHasError(false));
    dispatch(errorActions.setError(""));
    await FolderRepository()
      .getFolderById(folderId)
      .then((res) => {
        dispatch(folderActions.setSelectedFolder(res.data.value.folder));
        dispatch(
          menuAction.setSelectedFolderMenu(res.data.value?.folder?.name)
        );
        dispatch(
          dashboardAction.setSelectedDashboard(res.data.value.folder.name)
        );
        setFolderDiagrams(
          SortDiagrams(sortType, res.data.value.folder.diagrams)
        );
      })
      .catch((error) => {
        dispatch(errorActions.setHasError(true));
        dispatch(errorActions.setError(error.response?.data));
      })
      .finally(() => {
        dispatch(loadingActions.setHasLoading(false));
      });
  };
  useEffect(() => {
    setFolderDiagrams(SortDiagrams(sortType, selectedFolder?.diagrams));
  }, [selectedFolder?.diagrams]);
  useEffect(() => {
    if (folderId) {
      getFolder();
    }
  }, [folderId]);
  useEffect(() => {
    setFolderDiagrams(SortDiagrams(sortType, selectedFolder?.diagrams));
  }, [sortType]);

  return (
    <>
      <PageHeader
        type={selectedFolder?.name}
        defaultSort={sortType}
        sortOptions={DiagramSortOptions}
        onSort={sortHandler}
      />
      <div className={classes.panelLayout}>
        <div className={classes.tileBox}>
          {selectedFolder ? (
            <>
              {folderDiagrams?.map((item) => {
                return (
                  <div key={item.id} className={classes.tileItem}>
                    <DiagramTile diagram={item} />
                  </div>
                );
              })}
            </>
          ) : (
            <div className={classes.emptyFolder}>
              <span> Folder is empty !</span>
            </div>
          )}
        </div>
      </div>
    </>
  );
};
export default FolderDetails;
