import classes from "./style/ProfileMenu.module.scss";
import Avatar from "../../../assets/image/avatar-image.svg";
import ShortcutIcon from "@atlaskit/icon/glyph/shortcut";
import { useSelector, useDispatch } from "react-redux";
import { authActions } from "../../../store/auth-slice";
const ProfileMenu = () => {
  const userInfo = useSelector((state) => state.auth.userInfo);
  const dispatch = useDispatch();
  const Logout = () => {
    dispatch(authActions.logOut());
  };
  return (
    <div className={classes.profileBox}>
      <div className={classes.menuBody}>
        <div className={classes.header}>
          <span className={classes.headerTitle}>Account</span>
        </div>
        <div className={classes.profileDetials}>
          <img alt="avatar" src={Avatar} className={classes.avatar} />
          <div className={classes.profileInfo}>
            <span className={classes.profileName}>{userInfo?.nickname}</span>
            <span className={classes.profileEmail}>{userInfo?.email}</span>
          </div>
        </div>
        <a
          className={classes.manageBox}
          href={process.env.REACT_APP_ADMIN_BASE_DOMAIN + "/profile"}
          target="_blank"
        >
          <div className={classes.manageBox}>
            <div className={classes.manageLayout}>
              <span className={classes.manageTitle}>Manage Account</span>
              <ShortcutIcon label="" primaryColor="#626F86" />
            </div>
          </div>
        </a>
      </div>
      <div className={classes.menuFooter}>
        <div className={classes.footerBox} onClick={(e) => Logout()}>
          <span className={classes.footerTitle}>Log out</span>
        </div>
      </div>
    </div>
  );
};
export default ProfileMenu;
