import classes from "./style/WarningModal.module.scss";
import ErrorIcon from "@atlaskit/icon/glyph/error";
import { IconColor } from "../../types/SharedTypes";
import BaseButton from "../../components/base/BaseButton";

const WarningModal = (props) => {
  const closeHandler = () => {
    if (props.onClose) return props.onClose(false);
  };
  const saveAndLeave = () => {
    props.saveAction();
  };
  return (
    <div className={classes.backgroundModal}>
      <div className={classes.modalLayout}>
        <div className={classes.header}>
          <ErrorIcon
            label=""
            primaryColor={
              props.type === "exit" ? IconColor.warning : IconColor.danger
            }
          />
          <span className={classes.headerTitle}>{props.header}</span>
        </div>
        <div className={classes.content}>
          <span className={classes.contentText}>{props.alert}</span>
        </div>
        <div className={classes.modalFooter}>
          <BaseButton
            title="Cancel"
            size="sm"
            color="secondary"
            onClickHandler={(e) => closeHandler()}
          />

          <BaseButton
            title={
              props.type === "exit"
                ? "Yes, exit without saving"
                : "Yes,delete it"
            }
            hasRightIcon={false}
            hasLeftIcon={false}
            color={props.type === "exit" ? "warning" : "danger"}
            size="sm"
            onClickHandler={
              props.type === "exit" ? props.onLeave : props.onDelete
            }
          />
        </div>
      </div>
    </div>
  );
};
export default WarningModal;
