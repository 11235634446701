import classes from "./style/SharesLink.module.scss";
import ShareLinkButton from "../../../components/base/ShareLinkButton";
import BaseButton from "../../../components/base/BaseButton";
import { useState } from "react";
import { useParams } from "react-router-dom";
const SharesLink = (props) => {
  const params = useParams();
  const cancelHandler = () => {
    if (props.exitAction) return props.exitAction(false);
  };
  const [shareType, setShareType] = useState("Share");
  const embedLinkHandler = () => {
    let link =
      process.env.REACT_APP_BASE_DOMAIN +
      (shareType === "Share"
        ? `/diagrams/share/${props.diagram.id}/${params.currentVersion}`
        : `/diagrams/embed/${props.diagram.id}/${params.currentVersion}`);
    return link;
  };
  return (
    <div className={classes.formContainer}>
      <div className={classes.header}>
        <span className={classes.headerTitle}>Share diagram</span>
      </div>
      <div className={classes.tabSection}>
        <div
          className={
            shareType === "Share" ? classes.selectedLinkText : classes.linkText
          }
          onClick={(e) => setShareType("Share")}
        >
          Share link
        </div>
        <div
          className={
            shareType === "Embed" ? classes.selectedLinkText : classes.linkText
          }
          onClick={(e) => setShareType("Embed")}
        >
          Embed link
        </div>
      </div>
      <div className={classes.formBody}>
        <span className={classes.text}>
          {shareType === "Share"
            ? "To make the diagram visible to others, you can simply share the link with them."
            : "Others can use this diagram on their websites by coping the link."}
        </span>
        <ShareLinkButton linkText={embedLinkHandler()} buttonText="Copy Link" />
      </div>
      <div className={classes.formFooter}>
        <BaseButton
          size="sm"
          color="outline"
          title="Cancel"
          hasRightIcon={false}
          hasLeftIcon={false}
          onClickHandler={(e) => cancelHandler()}
        />
      </div>
    </div>
  );
};
export default SharesLink;
