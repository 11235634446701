import classes from "./style/MenuItem.module.scss";
import { useNavigate } from "react-router-dom";
import { menuAction } from "../../../store/menu-slice";
import { useDispatch, useSelector } from "react-redux";

const MenuItem = (props) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const selectedMenu = useSelector((state) => state.menu.selectedMenu);
  const menuItemHandler = () => {
    dispatch(menuAction.setSelectedMenu(props.title));
    navigate(props.link);
  };

  return (
    <div
      className={
        selectedMenu === props.title
          ? classes.selectedMenuItem
          : classes.menuItem
      }
      onClick={(e) => menuItemHandler()}
    >
      {props.title === selectedMenu && (
        <div className={classes.indicator}></div>
      )}
      <div className={classes.itemBox}>
        <div className={classes.itemContainer}>
          {props.leftIcon}
          <span
            style={{
              color: selectedMenu === props.title ? "#0C66E4" : "#172B4D",
            }}
            className={classes.menuItemTitle}
          >
            {props.title}
          </span>
          {props.rightIcon}
        </div>
      </div>
    </div>
  );
};
export default MenuItem;
