import FolderRepository from "../modules/Folder/repository/FolderRepository";
import DiagramRepository from "../modules/Diagram/repository/DiagramRepository";
import DataVariationsRepository from "../modules/DataVariations/repository/DataVariationsRepository";
import TemplateRepository from "../modules/Template/repository/TemplateRepository";
import FormatterRepository from "../modules/formatter/repository/FormatterRepository";
const repositories = {
  folder: FolderRepository,
  diagram: DiagramRepository,
  dataVariations: DataVariationsRepository,
  template: TemplateRepository,
  formatter: FormatterRepository,
};

export const RepoFactory = {
  get: (name) => repositories[name],
};
