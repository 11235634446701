// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.MenuItem_menuItem__hGQqk {
  display: flex;
  flex-direction: row;
  align-items: center;
  align-self: stretch;
  border-radius: 3px;
  position: relative;
  cursor: pointer;
  background-color: white;
}

.MenuItem_menuItem__hGQqk:hover {
  background-color: #e9f2ff;
}

.MenuItem_selectedMenuItem__ttqDU {
  display: flex;
  flex-direction: row;
  align-items: center;
  align-self: stretch;
  border-radius: 3px;
  position: relative;
  cursor: pointer;
  background-color: #e9f2ff;
}

.MenuItem_indicator__-Xv3n {
  width: 4px;
  height: 16px;
  position: absolute;
  top: 12px;
  border-radius: 0px 4px 4px 0px;
  background: #0c66e4;
  z-index: 99;
}

.MenuItem_itemBox__pkZuU {
  display: flex;
  min-height: 32px;
  align-items: center;
  flex: 1 0;
}

.MenuItem_itemContainer__0axh0 {
  display: flex;
  padding: 8px 16px;
  align-items: center;
  justify-content: space-between;
  gap: 12px;
  flex: 1 0;
}

.MenuItem_menuItemTitle__8fpF2 {
  /* _Internal/Menu/Menu item/Title */
  width: 80%;
  font-family: "SF-Pro";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px; /* 128.571% */
}`, "",{"version":3,"sources":["webpack://./src/components/core/dashboard/style/MenuItem.module.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,mBAAA;EACA,mBAAA;EACA,mBAAA;EACA,kBAAA;EACA,kBAAA;EACA,eAAA;EACA,uBAAA;AACF;;AACA;EACE,yBAAA;AAEF;;AAAA;EACE,aAAA;EACA,mBAAA;EACA,mBAAA;EACA,mBAAA;EACA,kBAAA;EACA,kBAAA;EACA,eAAA;EACA,yBAAA;AAGF;;AADA;EACE,UAAA;EACA,YAAA;EACA,kBAAA;EACA,SAAA;EACA,8BAAA;EACA,mBAAA;EACA,WAAA;AAIF;;AAFA;EACE,aAAA;EACA,gBAAA;EACA,mBAAA;EACA,SAAA;AAKF;;AAHA;EACE,aAAA;EACA,iBAAA;EACA,mBAAA;EACA,8BAAA;EACA,SAAA;EACA,SAAA;AAMF;;AAJA;EACE,mCAAA;EACA,UAAA;EACA,qBAAA;EACA,eAAA;EACA,kBAAA;EACA,gBAAA;EACA,iBAAA,EAAA,aAAA;AAOF","sourcesContent":[".menuItem {\n  display: flex;\n  flex-direction: row;\n  align-items: center;\n  align-self: stretch;\n  border-radius: 3px;\n  position: relative;\n  cursor: pointer;\n  background-color: #ffff;\n}\n.menuItem:hover {\n  background-color: #e9f2ff;\n}\n.selectedMenuItem {\n  display: flex;\n  flex-direction: row;\n  align-items: center;\n  align-self: stretch;\n  border-radius: 3px;\n  position: relative;\n  cursor: pointer;\n  background-color: #e9f2ff;\n}\n.indicator {\n  width: 4px;\n  height: 16px;\n  position: absolute;\n  top: 12px;\n  border-radius: 0px 4px 4px 0px;\n  background: #0c66e4;\n  z-index: 99;\n}\n.itemBox {\n  display: flex;\n  min-height: 32px;\n  align-items: center;\n  flex: 1 0 0;\n}\n.itemContainer {\n  display: flex;\n  padding: 8px 16px;\n  align-items: center;\n  justify-content: space-between;\n  gap: 12px;\n  flex: 1 0 0;\n}\n.menuItemTitle {\n  /* _Internal/Menu/Menu item/Title */\n  width: 80%;\n  font-family: \"SF-Pro\";\n  font-size: 14px;\n  font-style: normal;\n  font-weight: 400;\n  line-height: 18px; /* 128.571% */\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"menuItem": `MenuItem_menuItem__hGQqk`,
	"selectedMenuItem": `MenuItem_selectedMenuItem__ttqDU`,
	"indicator": `MenuItem_indicator__-Xv3n`,
	"itemBox": `MenuItem_itemBox__pkZuU`,
	"itemContainer": `MenuItem_itemContainer__0axh0`,
	"menuItemTitle": `MenuItem_menuItemTitle__8fpF2`
};
export default ___CSS_LOADER_EXPORT___;
