import {
  getBezierPath,
  EdgeLabelRenderer,
  BaseEdge,
  getSmoothStepPath,
} from "reactflow";
import classes from "./CustomEdge.module.scss";
import { useDispatch, useSelector } from "react-redux";
import { reactflowActions } from "../../../store/reactflow-slice";
import { useEffect, useRef, useState } from "react";
import TrashIcon from "@atlaskit/icon/glyph/trash";
import CrossIcon from "@atlaskit/icon/glyph/cross";
import RightRealtionArrow from "../../../assets/image/RightRelationArrow.svg";

const CustomEdge = ({
  id,
  sourceX,
  sourceY,
  targetX,
  targetY,
  sourcePosition,
  targetPosition,
  data,
}) => {
  const dispatch = useDispatch();
  const [editFlag, setEditFlag] = useState(false);

  const [edgePath, labelX, labelY] = getSmoothStepPath({
    sourceX,
    sourceY,
    sourcePosition,
    targetX,
    targetY,
    targetPosition,
  });
  let types = [
    {
      type: "oneToOne",
      label: "1:1",
    },
    {
      type: "oneToMany",
      label: "1:N",
    },
    {
      type: "manyToMany",
      label: "N:N",
    },
  ];

  const setTypeForEdge = (event, type) => {
    event.stopPropagation();
    setEditFlag(false);
    dispatch(reactflowActions.setSaveChangesFlag(true));
    dispatch(
      reactflowActions.updateEdges({
        id,
        type,
      })
    );
  };
  const deleteEdge = (event) => {
    event.stopPropagation();
    setEditFlag(false);
    dispatch(reactflowActions.deleteEdgeById(id));
    dispatch(reactflowActions.setSaveChangesFlag(true));
  };

  return (
    <>
      <BaseEdge id={id} path={edgePath} />
      <EdgeLabelRenderer style={{ pointerEvents: "All" }}>
        <div
          style={{
            position: "absolute",
            transform: `translate(-50%, -50%) translate(${labelX}px,${labelY}px)`,
            padding: "8px",
            pointerEvents: "all",
            alignItems: "center",
            alignContent: "center",
            justifyContent: "space-between",
            zIndex: 9999999,
          }}
        >
          {data.label === "" || editFlag ? (
            <div className={classes.typeBox}>
              <div className={classes.relation}>
                {types.map((type, index) => {
                  return (
                    <div
                      key={index}
                      className={
                        data.label === type.label
                          ? classes.selectedType
                          : classes.type
                      }
                      onClick={(e) => setTypeForEdge(e, type)}
                    >
                      <span className={classes.typeText}>{type.label}</span>
                    </div>
                  );
                })}
              </div>
              <div className={classes.action}>
                <div
                  className={classes.trash}
                  onClick={(e) => {
                    deleteEdge(e);
                  }}
                >
                  <TrashIcon />
                </div>
                {editFlag && (
                  <div
                    className={classes.type}
                    onClick={(e) => {
                      setEditFlag(false);
                    }}
                  >
                    <CrossIcon />
                  </div>
                )}
              </div>
            </div>
          ) : (
            <div className={classes.edgeLabel}>
              <span
                className={classes.typeText}
                style={{ cursor: "pointer" }}
                onClick={() => {
                  setEditFlag(true);
                }}
              >
                {data.label}
              </span>
            </div>
          )}
        </div>

        {data.startLabel && (
          <div
            style={{
              position: "absolute",
              top: -12,
              left: -2,
              transform: `translate(-50%, 0%) translate(${sourceX}px,${sourceY}px)`,
            }}
            className="nodrag nopan"
          >
            <img
              alt=""
              src={RightRealtionArrow}
              className={classes.LeftRelationIcon}
            />
          </div>
        )}
        {data.endLabel && (
          <div
            style={{
              position: "absolute",
              top: "12px",
              left: 2,
              transform: `translate(-50%, -100%) translate(${targetX}px,${targetY}px)`,
            }}
            className="nodrag nopan"
          >
            <img
              alt=""
              src={RightRealtionArrow}
              className={classes.RightRelationIcon}
            />
          </div>
        )}
      </EdgeLabelRenderer>
    </>
  );
};
export default CustomEdge;
