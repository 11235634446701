import classes from "./style/ColumnKeyAction.module.scss";
import { Key, Ranking, InfoCircle, Flag2 } from "iconsax-react";
const ColumnKeyAction = (props) => {
  const KeyAction = (type) => {
    return props.keyValue(type);
  };
  return (
    <div className={classes.tabcontainer}>
      <div className={classes.actionItem} onClick={(e) => KeyAction(1)}>
        <Key className={classes.itemIcon} />
        <span className={classes.actionText}>Primary Key</span>
      </div>
      <div className={classes.actionItem} onClick={(e) => KeyAction(2)}>
        <Ranking className={classes.itemIcon} />
        <span className={classes.actionText}>Unique Key</span>
      </div>
      <div className={classes.actionItem} onClick={(e) => KeyAction(3)}>
        <Flag2 className={classes.itemIcon} />
        <span className={classes.actionText}>index </span>
      </div>
      <div className={classes.actionItem} onClick={(e) => KeyAction(0)}>
        <InfoCircle className={classes.itemIcon} />
        <span className={classes.actionText}>none</span>
      </div>
    </div>
  );
};
export default ColumnKeyAction;
