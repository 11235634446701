import classes from "./style/DiagramImportForm.module.scss";
import Sql from "../../../assets/image/icon/sql.svg";
import Sql_icon from "../../../assets/image/icon/sql-icon.svg";
import JsonIcon from "../../../assets/image/Json.svg";
import { Colors, IconColor } from "../../../types/SharedTypes";
import { useRef, useState } from "react";
import BaseButton from "../../../components/base/BaseButton";
import UploadIcon from "@atlaskit/icon/glyph/upload";
import CrossIcon from "@atlaskit/icon/glyph/cross";
import EditorFileIcon from "@atlaskit/icon/glyph/editor/file";
import { RepoFactory } from "../../../baseRepository/Factory";
import { loadingActions } from "../../../store/loading-slice";
import { errorActions } from "../../../store/error-slice";
import { useDispatch, useSelector } from "react-redux";
import { encode, decode } from "js-base64";
import { Base64 } from "js-base64";
import { reactflowActions } from "../../../store/reactflow-slice";
import { useEdgesState } from "reactflow";
const FormatterRepository = () => RepoFactory.get("formatter");

const DiagramImportForm = (props) => {
  const refInputEl = useRef();
  const dispatch = useDispatch();
  const getEdges = useSelector((state) => state.reactflow.edges);
  const [edges, setEdges, onEdgesChange] = useEdgesState(getEdges);
  const [selectedType, setSelectedType] = useState("SQL Server");
  const [uploadFile, setUploadFile] = useState();
  const [uploadFileName, setUploadFileName] = useState("");
  const importTypes = [
    {
      title: "SQL Server",
      icon: (
        <div className={classes.iconBox}>
          <img alt="sql_icon" src={Sql_icon} />
          <img alt="sql" src={Sql} />
        </div>
      ),
      enable: true,
    },
    {
      title: "JSON",
      icon: (
        <div className={classes.iconBox}>
          <img alt="json" src={JsonIcon} />
        </div>
      ),
      enable: false,
    },
  ];
  const uploadIcon = () => {
    return <UploadIcon label="" />;
  };

  const uploadHandler = async (e) => {
    let fileToRead = document.querySelector("input").files[0];
    var bodyFormData = new FormData();
    bodyFormData.append("file", fileToRead);
    setUploadFile(bodyFormData);
    setUploadFileName(fileToRead.name);
  };

  const cancelHandler = () => {
    if (props.exitAction) return props.exitAction(false);
  };
  const importDiagramHandler = () => {
    dispatch(loadingActions.setHasLoading(true));
    dispatch(errorActions.setHasError(false));
    dispatch(errorActions.setError(""));
    FormatterRepository()
      .importDiagram(uploadFile)
      .then((res) => {
        // diagramDatadispatcher(res.data);
        edgeDataDispatcher(res.data);
        cancelHandler();
      })
      .catch((error) => {
        dispatch(errorActions.setHasError(true));
        dispatch(
          errorActions.setError({
            message: error.response?.data
              ? error.response.data.Reasons[0].Message
              : error.message,
            statusCode: error.response?.status,
          })
        );
      })
      .finally(() => {
        dispatch(loadingActions.setHasLoading(false));
      });
  };
  const diagramDatadispatcher = async (diagram) => {
    let dataArray = [];
    await diagram?.tables?.forEach((element) => {
      let data = {
        id: element.id,
        position: {
          x: parseInt(
            diagram?.tablePositions.find(
              (position) =>
                position.table === element.id || position.table === element.name
            )?.positions.x
          ),
          y: parseInt(
            diagram?.tablePositions.find(
              (position) =>
                position.table === element.id || position.table === element.name
            )?.positions.y
          ),
        },
        type: "table",
        descriptionFlag: false,
        data: {
          enum: false,
          schemaColor: element.schemaColor,
          name: element.name,
          description: element.description,
          columns: element.columns,
        },
      };
      dataArray.push(data);
    });
    diagram?.enums.forEach((element) => {
      let data = {
        id: element.id,
        position: {
          x: parseInt(
            diagram?.enumPositions.find(
              (position) => position.table === element.id
            )?.positions.x
          ),
          y: parseInt(
            diagram?.enumPositions.find(
              (position) => position.table === element.id
            )?.positions.y
          ),
        },
        type: "table",
        descriptionFlag: false,
        data: {
          enum: true,
          schemaColor: element.schemaColor,
          name: element.name,
          description: element.description,
          values: element.values,
        },
      };

      dataArray.push(data);
    });
    dispatch(reactflowActions.changeDataBase(dataArray));
  };

  const edgeDataDispatcher = async (diagram) => {
    let edgeArray = [];
    await diagram?.edges?.forEach((element) => {
      let edge = {
        data: {
          label:
            element.relation === 2
              ? "N:N"
              : element.relation === 1
              ? "1:N"
              : "1:1",
          startLabel: element.relation === 2,
          endLabel: (element.relation === 1) | (element.relation === 2),
        },
        handleType:
          element.relation === 2
            ? "manyToMany"
            : element.relation === 1
            ? "oneToMany"
            : "oneToOne",
        id: element.id,
        label:
          element.relation === 2
            ? "N:N"
            : element.relation === 1
            ? "1:N"
            : "1:1",
        source: element.source,
        sourceHandle: element.sourceKey,
        target: element.target,
        targetHandle: element.targetKey,

        type: "custom",
      };
      edgeArray.push(edge);
    });
    dispatch(reactflowActions.setEdges(edgeArray));
    diagramDatadispatcher(diagram);
  };

  return (
    <div className={classes.formLayout}>
      <div className={classes.header}>
        <span className={classes.headerTitle}>Import</span>
      </div>
      <div className={classes.formBody}>
        <span className={classes.bodyHeader}>Select Source data format</span>
        <div className={classes.ImportTypeBox}>
          {importTypes.map((type, index) => {
            return (
              <div
                key={index}
                className={classes.type}
                style={{
                  borderColor: !type.enable
                    ? Colors.Natural_400A
                    : selectedType === type.title
                    ? Colors.Blue_700
                    : Colors.Natural_1100,
                }}
                onClick={() => {}}
              >
                {type.icon}
                <span className={classes.typeTitle}>{type.title}</span>
                {!type.enable && (
                  <div className={classes.disableTag}>
                    <span className={classes.tagLabel}>Soon</span>
                  </div>
                )}
              </div>
            );
          })}
        </div>
        <span className={classes.bodyHeader}>Select file to upload</span>
        {!uploadFile && (
          <BaseButton
            size="sm"
            color="primary"
            title="Upload"
            hasLeftIcon={true}
            icon={uploadIcon()}
            onClickHandler={() => refInputEl.current?.click()}
          />
        )}
        <input
          ref={refInputEl}
          type="file"
          name="file"
          accept=".sql"
          hidden
          onChange={(e) => uploadHandler(e)}
        />
        {uploadFile && (
          <div className={classes.banner}>
            <div className={classes.fileIcon}>
              <EditorFileIcon label="" primaryColor={IconColor.green} />
            </div>
            <div className={classes.fileNameBox}>
              <span className={classes.fileName}>{uploadFileName}</span>
            </div>
            <div
              className={classes.closeIcon}
              onClick={() => {
                setUploadFile(null);
                setUploadFileName("");
              }}
            >
              <CrossIcon label="" secondaryColor={Colors.Green_04} />
            </div>
          </div>
        )}
      </div>
      <div className={classes.formAction}>
        <BaseButton
          size="sm"
          color="outline"
          title="Cancel"
          hasRightIcon={false}
          hasLeftIcon={false}
          onClickHandler={(e) => cancelHandler()}
        />
        <BaseButton
          size="sm"
          title="Begin Import"
          color="primary"
          hasRightIcon={false}
          hasLeftIcon={false}
          onClickHandler={(e) => {
            importDiagramHandler();
          }}
        />
      </div>
    </div>
  );
};
export default DiagramImportForm;
