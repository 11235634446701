import { Route, Routes } from "react-router-dom";
import ShareReactFlow from "../../ReactFlow/pages/ShareReactFlow";
const DiagramPublicRouter = () => {
  return (
    <Routes>
      <Route
        path="/share/:Id/:currentVersion"
        exact
        element={<ShareReactFlow link="share" />}
      />
      <Route
        path="/embed/:Id/:currentVersion"
        exact
        element={<ShareReactFlow link="embed" />}
      />
    </Routes>
  );
};

export default DiagramPublicRouter;
