import classes from "./style/DatabaseCard.module.scss";
import Sql from "../../assets/image/icon/sql.svg";
import Sql_icon from "../../assets/image/icon/sql-icon.svg";
import Mongodb from "../../assets/image/icon/mongodb.svg";
import PostgreSql from "../../assets/image/icon/postgresql.svg";
import Dotnet from "../../assets/image/icon/dotnet.svg";
import Pyhthon from "../../assets/image/icon/Python.svg";
import Nodejs from "../../assets/image/icon/Nodejs.svg";
import { Colors } from "../../types/SharedTypes";
import { useDispatch, useSelector } from "react-redux";
import { diagramActions } from "../../modules/Diagram/store/diagram-slice";
const DatabaseCard = (props) => {
  const dispatch = useDispatch();
  const selectedCard = useSelector((state) => state.diagram.selectedDatabase);
  const cardIcon = {
    SQLServer: (
      <div className={classes.iconBox}>
        <img alt="sql_icon" src={Sql_icon} />
        <img alt="sql" src={Sql} />
      </div>
    ),
    Mongodb: (
      <div className={classes.iconBox}>
        <img alt="mongo_icon" src={Mongodb} />
      </div>
    ),
    Postgresql: (
      <div className={classes.iconBox}>
        <img alt="postgre_icon" src={PostgreSql} />
      </div>
    ),
    CodeInOne: (
      <div className={classes.iconBox}>
        <img alt="Code_icon" src={Dotnet} />
      </div>
    ),
    Python: (
      <div className={classes.iconBox}>
        <img alt="python_icon" src={Pyhthon} />
      </div>
    ),
    Nodejs: (
      <div className={classes.iconBox}>
        <img alt="Node_icon" src={Nodejs} />
      </div>
    ),
  };

  const cardTitle = {
    SQLServer: "SQLServer",
    Mongodb: "MongoDb",
    Postgresql: "PostGreSql",
    CodeInOne: ".Net",
    Python: "Python",
    Nodejs: "NodeJs",
  };
  const selectHandler = () => {
    dispatch(diagramActions.setSelectedDatabse(props.type));
  };

  return (
    <div
      className={classes.cardLayout}
      style={{
        background:
          props?.type?.id === selectedCard?.id
            ? Colors.Blue_600
            : props.disable
            ? Colors.Natural_1100
            : Colors.Natural_0,
        cursor: !props.disable && "pointer",
      }}
      onClick={() => {
        !props.disable && selectHandler();
      }}
    >
      {cardIcon[props.title]}
      <span className={classes.cardName}>{cardTitle[props.title]}</span>
    </div>
  );
};
export default DatabaseCard;
