import classes from "./style/DiagramPanel.module.scss";
import DiagramTile from "./DiagramTile";
import PageHeader from "../../../components/base/PageHeader";
import { errorActions } from "../../../store/error-slice";
import { loadingActions } from "../../../store/loading-slice";
import { diagramActions } from "../store/diagram-slice";
import { useDispatch, useSelector } from "react-redux";
import { RepoFactory } from "../../../baseRepository/Factory";
import { useEffect, useState } from "react";
import { DiagramSortOptions } from "../../../types/SharedTypes";
import { SortDiagrams } from "../../../types/SharedTypes";
const DiagramRepository = () => RepoFactory.get("diagram");

const FavoriteDiagramsPanel = () => {
  const dispatch = useDispatch();
  const diagramsList = useSelector((state) => state.diagram.diagramsList);
  const sortType = useSelector((state) => state.diagram.sortType);
  const favoriteDiagramList = useSelector(
    (state) => state.diagram.favoriteDiagramList
  );
  const selectedWorkSpaceId = useSelector(
    (state) => state.auth.selectedWorkSpaceId
  );
  const getFavoriteDigrams = async () => {
    dispatch(loadingActions.setHasLoading(true));
    dispatch(errorActions.setHasError(false));
    dispatch(errorActions.setError(""));
    let array = [];
    await DiagramRepository()
      .getFavoriteDiagrams(selectedWorkSpaceId)
      .then((res) => {
        array = res.data.value.diagrams;
      })
      .catch((error) => {
        dispatch(errorActions.setHasError(true));
        dispatch(
          errorActions.setError({
            message: error.response?.data
              ? error.response.data.Reasons[0].Message
              : error.message,
            statusCode: error.response?.status,
          })
        );
      })
      .finally(() => {
        dispatch(loadingActions.setHasLoading(false));
        dispatch(
          diagramActions.setFavoriteDiagramList(SortDiagrams(sortType, array))
        );
      });
  };
  useEffect(() => {
    if (selectedWorkSpaceId) {
      getFavoriteDigrams();
    }
  }, [selectedWorkSpaceId, favoriteDiagramList.lenght, diagramsList]);

  useEffect(() => {
    dispatch(
      diagramActions.setFavoriteDiagramList(
        SortDiagrams(sortType, favoriteDiagramList)
      )
    );
  }, [sortType]);
  const sortHandler = (status) => {
    dispatch(diagramActions.setSortType(status));
  };
  return (
    <div className={classes.panelLayout}>
      <PageHeader
        type="Favorites"
        defaultSort={DiagramSortOptions[2]}
        sortOptions={DiagramSortOptions}
        onSort={sortHandler}
      />
      <div className={classes.tileBox}>
        {favoriteDiagramList?.map((item) => {
          return (
            <div key={item.id} className={classes.tileItem}>
              <DiagramTile diagram={item} />
            </div>
          );
        })}
      </div>
    </div>
  );
};
export default FavoriteDiagramsPanel;
