import classes from "./style/AppsMenu.module.scss";
import AdminAvatar from "../../../assets/image/Monkey.png";
import Tool from "../../../assets/image/tool.png";
import { useState } from "react";
import Modal from "../../base/Modal";
import DiagramCreationForm from "../../../modules/Diagram/components/DiagramCreationForm";
const AppsMenu = () => {
  const [diagramModalFlag, setDiagramModalFlag] = useState(false);
  const exitModal = (status) => {
    setDiagramModalFlag(status);
  };
  return (
    <div className={classes.menuBox}>
      <div className={classes.menuBody}>
        <div className={classes.header}>
          <span className={classes.headerTitle}>Your Services</span>
        </div>
        <a
          href={process.env.REACT_APP_ADMIN_BASE_DOMAIN}
          target="_blank"
          rel="noopener noreferrer"
          className={classes.menuItem}
        >
          <img
            src={AdminAvatar}
            alt="adminPanel Avatar"
            className={classes.avatar}
          />
          <span className={classes.menuItemTitle}>Admin Panel</span>
        </a>

        <div className={classes.menuItem}>
          <img src={Tool} alt="adminPanel Avatar" className={classes.avatar} />
          <span className={classes.menuItemTitle}>Code in one</span>
        </div>
      </div>
      <div className={classes.menuFooter}>
        <div
          className={classes.footerContainer}
          onClick={() => {
            setDiagramModalFlag(true);
          }}
        >
          <span className={classes.footerTitle}>Create diagram</span>
        </div>
      </div>
      {diagramModalFlag && (
        <Modal
          size="lg"
          header="Add New Diagram"
          exitAction={exitModal}
          body={<DiagramCreationForm exitAction={exitModal} />}
        />
      )}
    </div>
  );
};
export default AppsMenu;
