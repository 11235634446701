import classes from "./style/InternalLoadHandling.module.scss";
import { useSelector } from "react-redux";
const InternalLoadHandling = () => {
  const internalHasLoading = useSelector(
    (state) => state.loading.internalHasLoading
  );
  return (
    <>
      {internalHasLoading && (
        <div className={classes.loaderFrame}>
          <div className={classes.loaderBody}>
            <div className={classes.loader}></div>
            <span className={classes.loadingText}>please wait...</span>
          </div>
        </div>
      )}
    </>
  );
};
export default InternalLoadHandling;
