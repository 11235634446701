import DashboardLayout from "../../../components/layouts/DashboardLayout";
import FolderPanel from "../components/FolderPanel";
import { useDispatch } from "react-redux";
import { dashboardAction } from "../../../store/dashboard-slice";
import { useEffect } from "react";

const Folders = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(dashboardAction.setSelectedDashboard("All Folders"));
  }, [dispatch]);
  return (
    <DashboardLayout>
      <FolderPanel></FolderPanel>
    </DashboardLayout>
  );
};
export default Folders;
