import DBLayout from "../../../components/layouts/DBLayout";
import ReactFlowComponent from "../ReactFlow";
const ShareReactFlow = (props) => {
  return (
    <DBLayout status={props.link}>
      <ReactFlowComponent status={props.link} />
    </DBLayout>
  );
};
export default ShareReactFlow;
