import classes from "./style/Header.module.scss";
import AppSwitcher from "../../../assets/image/app-switcher.svg";
import Logo from "../../../assets/image/logo/charmDB.svg";
import BaseButton from "../../base/BaseButton";
import PremiumIcon from "@atlaskit/icon/glyph/premium";
import NotificationIcon from "@atlaskit/icon/glyph/notification";
import UserAvatarCircleIcon from "@atlaskit/icon/glyph/user-avatar-circle";
import AppsMenu from "./AppsMenu";
import ProfileMenu from "./ProfileMenu";
import { useState, useEffect, useRef } from "react";
const Header = () => {
  const appMenuRef = useRef(null);
  const profileMenuRef = useRef(null);
  const [appMenuFlag, setAppMenuFlag] = useState(false);
  const [profileMenuFlag, setProfileMenuFlag] = useState(false);
  const premiumIcon = () => {
    return <PremiumIcon label="" primaryColor="#F86F03" />;
  };
  const profileMenuHandler = () => {
    setProfileMenuFlag(!profileMenuFlag);
  };
  const handleClickOutside = (event) => {
    const { target } = event;
    if (appMenuRef.current && !appMenuRef.current.contains(target)) {
      setAppMenuFlag(false);
    }
    if (profileMenuRef.current && !profileMenuRef.current.contains(target)) {
      setProfileMenuFlag(false);
    }
  };
  useEffect(() => {
    document.addEventListener("click", handleClickOutside, true);
    return () => {
      document.removeEventListener("click", handleClickOutside, true);
    };
  }, [appMenuRef, profileMenuRef]);
  const appMenuModalHandler = () => {
    if (appMenuFlag === false) {
      setAppMenuFlag(true);
    } else {
      setAppMenuFlag(false);
    }
  };
  return (
    <div className={classes.headerContainer}>
      <div className={classes.leftPanel}>
        <div
          onClick={(e) => {
            appMenuModalHandler();
          }}
        >
          <img
            className={classes.AppIcon}
            alt="app-switcher"
            src={AppSwitcher}
          />
        </div>

        <img className={classes.logo} alt="logo" src={Logo} />
      </div>
      <div className={classes.rightPanel}>
        <BaseButton
          title="Premium"
          color="outline"
          hasRightIcon={false}
          hasLeftIcon={true}
          hasBlock={false}
          icon={premiumIcon()}
        ></BaseButton>
        <NotificationIcon label="" size="large" primaryColor="#44546F" />
        <div className={classes.profile} onClick={() => profileMenuHandler()}>
          <UserAvatarCircleIcon label="" size="large" primaryColor="#44546F" />
          {profileMenuFlag && (
            <div className={classes.profileDropDown} ref={profileMenuRef}>
              <ProfileMenu />
            </div>
          )}
        </div>
      </div>
      {appMenuFlag && (
        <div className={classes.menuDropDown} ref={appMenuRef}>
          <AppsMenu />
        </div>
      )}
    </div>
  );
};
export default Header;
