import classes from "./style/Input.module.scss";
import { Edit, Save2 } from "iconsax-react";
import { useState, useEffect } from "react";
const Input = (props) => {
  const [readOnly, setReadOnly] = useState(false);
  const [editFlag, setEditFlag] = useState(true);
  useEffect(() => {
    if (props.readOnly === true || props.status === "edit") {
      setReadOnly(true);
    } else {
      setReadOnly(false);
    }
  }, [props.status]);
  useEffect(() => {
    if (props.success) {
      setEditFlag(true);
    }
  }, [props.success]);
  const editButtonHandler = () => {
    setReadOnly(false);
    setEditFlag(false);
  };
  const EditIcon = () => {
    return <Edit />;
  };
  const SaveIcon = () => {
    return <Save2 />;
  };
  return (
    <div className={classes.inputContainer}>
      {props.label && (
        <div className={classes.label}>
          <label className={classes.labelText}>{props.label}</label>
        </div>
      )}
      <div
        className={
          props.circleShape
            ? classes.digitItem
            : props.roundBorder
            ? `${classes.input} ${classes.roundBorder}`
            : classes.input
        }
      >
        {!props.circleShape && props.icon && (
          <div className={classes.icon}>{props.icon}</div>
        )}
        <input
          className={classes.inputSection}
          type={props.type}
          placeholder={props.placeholder}
          id={props.id ? props.id : Math.random()}
          name={props.name}
          maxLength={props.maxLength}
          onChange={props.handleChange}
          onKeyDown={props.keyHandler}
          required
          pattern={props.pattern}
          defaultValue={props.value}
          readOnly={readOnly}
        />
      </div>
    </div>
  );
};
export default Input;
