import classes from "./style/ColorPicker.module.scss";
const ColorPicker = (props) => {
  const colorItems = [
    "#F2EDFD",
    "#F2D8FB",
    "#FFCFF2",
    "#EBFAE4",
    "#FAF4E4",
    "#FFDFC5",
    "#FFD7D7",
    "#FFE7E7",
  ];
  const colorHandler = (item) => {
    return props.colorAction(item);
  };
  return (
    <div className={classes.actionContainer}>
      <div className={classes.header}>
        <span className={classes.title}>Select Colors</span>
      </div>
      <div className={classes.colorBox}>
        {colorItems.map((item, index) => {
          return (
            <div
              key={index}
              style={{ background: item }}
              className={classes.colorItem}
              onClick={(e) => colorHandler(item)}
            ></div>
          );
        })}
      </div>
    </div>
  );
};
export default ColorPicker;
